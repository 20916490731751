import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import Timeline from './views/Timeline.vue'
import Entry from './views/Entry.vue'
import EntryStandard from "./views/EntryStandard";

import UserList from './views/UserList.vue'
import Login from './views/Login.vue'

import FollowList from "./views/FollowList";
import Profile from "./views/Profile";

import ReportSearch from "./views/ReportSearch";
import Report from "./views/Report";

import WorkingCalendar from "./views/WorkingCalendar";
import UserCalendar from "./views/UserCalendar";

import DailyView from "./views/DailyView";
import EntryCheckView from "./views/EntryCheckView";

import GroupMaintenance from "./views/GroupMaintenance";
import GroupViewabilityMaintenance from "./views/GroupViewabilityMaintenance";

import AttendanceList from "./views/AttendanceList";

import ThanksCard from "@/views/ThanksCard";

Vue.use(Router);




const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta:{
        title: 'Home'
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props:(route) => ({
        propSSO:route.query.sso,
        propBackuri:route.query.backuri,
      }),
      meta: {
        isPublic: true ,
        title: 'Login'
      }
    },

    {
      path: '/timeline',
      name: 'timeline',
      component: Timeline,
      meta:{
        title: 'Timeline',
        useReload:true,
      },
    },

    {
      path: '/daily',
      component: DailyView,
      meta:{
        title: 'DailyView'
      },
    },


    {
      path: '/entrycheck',
      component: () => import(/* webpackChunkName: "entryCheckView" */ './views/EntryCheckView'),
      meta:{
        title: '入力確認'
      },
    },

    {
      path: '/attendance',
      component: () => import(/* webpackChunkName: "entryCheckView" */ './views/AttendanceList'),
      meta:{
        title: '退勤入力リスト'
      },
    },


    {
      path: '/search',
      component:ReportSearch,
      meta:{
        title: '日報検索'
      },
    },

    {
      path:'/report/:reportId',
      component:Report,
      props:(route) => ({
        reportId:route.params.reportId,
      }),
      meta:{
        title: '日報'
      },
    },

    {
      path: '/entry',
      name: 'entry',
      component: Entry,
      props:(route) => ({
        propReportDate:route.query.dt,
      }),
      meta:{
        title: '日報入力',
        scrollToTop:false,
      },
    },

    {
      path: '/entry_standard',
      component: EntryStandard,
      props:(route) => ({
        propReportId:Number(route.query.id),
        propReportDate:route.query.dt,
      }),
      meta:{
        title: '日報入力',
        scrollToTop:false,
      },
    },

    {
      path:'/follows',
      component:FollowList,
      meta:{
        title: 'フォロー設定'
      },
    },

    {
      path:'/thanks/_self',
      component:ThanksCard,
      meta:{
        title: 'Thanks!カード'
      },
    },

    {
      path:'/profile',
      component:Profile,
      meta:{
        title: 'プロフィール',
        scrollToTop:false,
      },
    },

    {
      path: '/users',
      component: UserList,
      meta:{
        title: 'ユーザ'
      },
    },

    {
      path: '/calendar',
      component: WorkingCalendar,
      meta:{
        title: '稼働カレンダー設定',
        scrollToTop:false,
      },
    },
    {
      path: '/user_calendar',
      component: UserCalendar,
      meta:{
        title: '個別カレンダー設定',
        scrollToTop:false,
      },
    },

    {
      path: '/system_maintenance',
      component: () => import(/* webpackChunkName: "systemMaintenance" */ './views/SystemMaintenance.vue'),
      meta:{
        title: 'システムメンテナンス',
        scrollToTop:false,
      },
    },

    {
      path:'/admin/follows',
      component:FollowList,
      meta:{
        title: 'フォロー設定'
      },
      props:(route) => ({
        adminMode:true
      }),
    },

    {
      path:'/admin/groups',
      component:GroupMaintenance,
      meta:{
        title: 'グループ設定'
      },
    },

    {
      path:'/admin/groups/:groupCd/viewability',
      component:GroupViewabilityMaintenance,
      meta:{
        title: 'グループ間参照設定'
      },
      props:(route) => ({
        groupCd:route.params.groupCd,
      }),
    },

    {
      path: '/admin/user_roles',
      component: () => import(/* webpackChunkName: "userRoleList" */ './views/UserRoleList.vue'),
      meta:{
        title: 'ユーザ権限一覧',
        scrollToTop:false,
      },
    },

    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ]
});

export default router;