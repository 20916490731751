import axios from 'axios';
import Util from '../Util'

class ReportAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    getEntry(entryId){

        let path = "/api/entry/" + entryId;

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

}

let _api = new ReportAPI();

export default _api;