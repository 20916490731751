<template>
    <v-dialog v-model="dialog" persistent max-width="400px" @keydown.esc="onCancelClick">
        <v-card>
            <v-card-title>
                <span class="headline">担当者へ転送</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="formForward" v-model="isValid" >
                        <v-row>
                            <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="12" md="12" v-for="(staff,index) in staff_cd_list">
                                <UserSelectField :required="index === 0" label="お知らせしたい人" v-model="staff_cd_list[index]" ></UserSelectField>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="message"
                                        :rules="forwardCommentRules"
                                        :counter="50"
                                        label="コメント"
                                        required
                                        maxlength="50"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" @click.native="onSaveClick">保存</v-btn>
                <v-btn color="grey lighten-4" @click.native="onCancelClick">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import UserSelectField from "@/components/controls/UserSelectField";
export default {
    components : {
        UserSelectField
    },
    props: [
    ],
    watch: {
        'staff_cd_list':function(to, from) {

            // 途中の空欄を削除する
            while(true){
                let findBlank = false;
                for (let i = 0; i < this.staff_cd_list.length -1; i++) {
                    if (!this.staff_cd_list[i]){
                        this.staff_cd_list.splice(i,1);
                        findBlank = true;
                        break;
                    }
                }
                if (!findBlank) break;
            }

            // 常に空の要素を最後に配置する
            if (this.staff_cd_list[this.staff_cd_list.length - 1]){
                this.staff_cd_list.push("");
            }

        },
    },
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,

            isValid:true,
            message: "",
            staff_cd:"",

            staff_cd_list:[""],

            forwardCommentRules: [
                v => !!v || 'コメントは必須項目です',
            ],

            options: {
                color: 'primary',
                width: 290
            }
        };
    },
    methods: {
        open(options) {

            if (this.$refs.formForward){
                this.$refs.formForward.resetValidation();
            }

            this.message = "";
            this.staff_cd = "";
            this.staff_cd_list.splice(0);
            this.staff_cd_list.push("");
            this.options = Object.assign(this.options, options); // 使っていないけど一応

            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        onSaveClick() {
            if (!this.$refs.formForward.validate()){
                console.log("入力内容に不備があります。");
                return;
            }

            let staff_cd_list = this.staff_cd_list.filter(a => {
                return !!a;
            });

            let result = {
                message:this.message,
                staff_cd:this.staff_cd,
                staff_cd_list:staff_cd_list
            };

            this.resolve([true,result]);
            this.dialog = false;
        },
        onCancelClick() {
            this.resolve([false,{}]);
            this.dialog = false;
        }
    },

}

</script>
