<template>
    <div class="d-flex align-center">
        <div>
            <v-text-field
                    v-model="userName"
                    :label="label"
                    clearable
                    :rules="required ? userNameRules : []"
                    name="userName"
                    v-on:click="showDialog"
                    readonly
                    @click:clear="onClickClear"
            />

        </div>

        <UserSelectDialog ref="userSelectDialog"></UserSelectDialog>

    </div>
</template>

<script>
import BasicInfoAPI from '@/apis/BasicInfoAPI';
import UserSelectDialog from "../dialogs/UserSelectDialog";
export default {
    name: "UserSelectField",
    components : {
        UserSelectDialog
    },
    mounted: function() {
        this.refId = this.makeRefId();
        this.staffCd = this.value;
    },
    watch: {
        value() {
            let a = this.value;
            //console.log("user select field value changed. " + a);

            if (this.staffCd !== this.value){
                //console.log("user select field apply new value");
                if (a){
                    this.staffCd = a;

                    let user = this.userListAll.find(function(element) {
                        return element.staff_cd === a;
                    });
                    if (user){
                        this.userName = user.name;
                    } else {
                        this.userName = "";
                    }

                } else {
                    this.userName = "";
                    this.staffCd = "";
                    this.userId = "";
                }
            }

        },


    },
    props: {
        label:"",
        value: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            redId:"",
            menuFlg:false,

            userId:"",
            staffCd:"",
            userName:"",
            userFilter:"",

            userListAll:[],
            userList:[],
            userNameRules: [
                v => !!v || 'ユーザは必須項目です'
            ],
            isOmitted:false,
        }
    },
    computed: {
    },
    methods: {

        onClickClear(){
            this.userName = "";
            this.userId = "";
            this.staffCd = "";
            // updatedが発生しないので明示的に呼び出す
            this.$emit('input', this.staffCd);
        },
        makeRefId(){
            // 生成する文字列の長さ
            let l = 8;
            // 生成する文字列に含める文字セット
            let c = "abcdefghijklmnopqrstuvwxyz0123456789";
            let cl = c.length;
            let r = "";
            for(let i=0; i<l; i++){
                r += c[Math.floor(Math.random()*cl)];
            }
            return "timef_" + r;
        },


        async showDialog(){
            let user = await this.$refs.userSelectDialog.open("",{});
            if (user){
                this.userId = user.userId;
                this.staffCd = user.staffCd;
                this.userName = user.userName;

                console.log("title_cd=" + user.titleCd)
                //this.$emit('select' , this.userId);
            }
        },
    },
    updated: function() {
        //console.log("update user field." + this.staffCd + " req:" + this.required);
        this.$emit('input', this.staffCd);
    },

}
</script>

<style scoped>

</style>