<template>
  <!-- 日報の個別表示画面 -->
  <v-container>
    <v-card>
      <loading-view :loading="loading" />

      <template v-if="!loading && isFetched">
        <v-list-item>
          <ProfileImageView :staff-cd="userEntry.staff_cd"></ProfileImageView>
          <v-list-item-content class="ml-3">
            <v-list-item-title class="headline">{{
              userEntry.user_name
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              userEntry.group_name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- 標準日報 -->
        <ReportDetail :report-id="reportId" />

        <!-- A,B共通のツールボックスとコメント-->
        <ReportComments :report-id="reportId" />

        <!-- 転送の履歴 転送可能な人だけ表示 -->
        <ReportForwards :report-id="reportId" />
      </template>
    </v-card>
  </v-container>
</template>

<script>
import Common from "@/components/Common";
import ReportAPI from "@/apis/ReportAPI";
import UserAPI from "@/apis/UserAPI";
import ReportViewSupport from "../components/ReportViewSupport";
import ProfileImageView from "../components/controls/ProfileImageView";
import ReportComments from "./sub/ReportComments";
import ReportDetail from "./sub/ReportDetail";
import ReportForwards from "./sub/ReportForwards";

export default {
  mixins: [Common, ReportViewSupport],
  components: {
    ReportDetail,
    ReportForwards,
    ReportComments,
    ProfileImageView,
  },
  mounted: function () {
    this.setupConditions();
  },
  props: ["reportId"],
  watch: {
    $route: function (to, from) {
      this.setupConditions();
    },
  },
  data() {
    return {
      loading: false,

      fetched: {
        reportId: "",
      },

      // 以下表示データ
      userEntry: {
        staff_cd: "",
        user_name: "",
        group_name: "",
      },
    };
  },
  computed: {
    isFetched: function () {
      return this.reportId == this.fetched.reportId;
    },
    entry() {
      return this.$store.state.reports[this.reportId];
    },
  },
  methods: {
    setupConditions() {
      this.fetchData(true);
    },

    fetchData(needDataFetch) {
      let self = this;

      if (!this.isInitialized) {
        this.fetchBasicData(function () {
          self.fetchData(needDataFetch);
        });
        return;
      }

      if (needDataFetch) {
        this.loading = true;

        Promise.resolve()
          .then((a) => {
            return this.checkAuthToken();
          })
          .then((a) => {
            return ReportAPI.getEntry(this.reportId);
          })
          .then((a) => {
            return UserAPI.profileByStaffCd(a.data.report.staff_cd).then(
              (b) => {
                return {
                  reportEntity: a.data,
                  profile: b.data,
                };
              }
            );
          })
          .then((data) => {
            self.makeDisplayData(data);
            self.loading = false;
            self.fetched.reportId = self.reportId;

            // 自動の場合にはこのタイミングで既読にする
            if (self.useAutoRead) {
              self.setAlreadyRead(self.reportId);
            }
          })
          .catch(function (err) {
            console.log(err);
            self.showErrorToast("データの取得に失敗しました。");
          });
      }
    },

    fetchBasicData(finishProc) {
      let self = this;
      self.isInitialized = true;
      if (finishProc) {
        finishProc();
      }
    },

    makeDisplayData(data) {
      let self = this;

      //console.log(data);

      let entrySet = data.reportEntity;
      let profile = data.profile;

      let reportEntry = self.makeReportDisplayData(entrySet);

      // vuex.stateに日報データを保存
      this.$store.commit("setReport", reportEntry);

      let report = entrySet.report;
      let staffCd = report.staff_cd;

      //this.entry = reportEntry;

      this.userEntry.staff_cd = staffCd;
      this.userEntry.user_name = profile.user.name;
      this.userEntry.group_name = profile.groupName;
    },
  },
};
</script>
