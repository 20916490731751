<template>
    <v-card outlined>

        <v-row class="pl-4" v-if="mode===1">
            <v-col v-if="isImage" class="shrink clickable" v-on:click="fireClickEvent">
                <!--<ImageView v-if="isImage" :src="downloadUrl" :height="70" :width="70"></ImageView>-->
                <ThumbnailView v-if="isImage" :height="70" :width="70" :imageId="fileId"></ThumbnailView>
            </v-col>
            <v-col v-if="!isImage" class="shrink clickable">
                <a v-if="downloadType === 's3'" :href="downloadUrl" target="_blank" rel="noopener">
                    <span v-if="iconType === 'pdf'"><i class="far fa-4x fa-file-pdf"></i></span>
                    <span v-if="iconType === 'file'"><i class="far fa-4x fa-file"></i></span>
                </a>
                <a v-if="downloadType === 'api'" v-on:click.prevent="onDownloadFile(buildApiServerUrl(downloadUrl),fileName)" rel="noopener">
                    <span v-if="iconType === 'pdf'"><i class="far fa-4x fa-file-pdf"></i></span>
                    <span v-if="iconType === 'file'"><i class="far fa-4x fa-file"></i></span>
                </a>
            </v-col>
            <v-col>
                <v-container>
                    <v-row>
                        <span class="caption">{{fileName}}</span>
                    </v-row>
                    <v-row justify="end">
                        <v-btn v-if="canDelete" text color="orange" v-on:click="fireDeleteEvent">DELETE</v-btn>
                    </v-row>
                </v-container>

            </v-col>
        </v-row>

    </v-card>
</template>
<script>
import FileUploadAPI from '@/apis/FileUploadAPI';
import ImageView from "@/components/controls/ImageView";
import ThumbnailView from "./ThumbnailView";
import Common from "../Common";
import Util from '../../Util'

export default {
    mixin:[Common],
    components : {
        ImageView,ThumbnailView
    },
    mounted() {
        this.startImageLoading();
    },
    props: [
        'fileId','canDelete'
    ],

    watch:{
        'fileId':function(val){
            this.startImageLoading();
        },
    },

    data() {
        return {
            mode:1,
            loading:false,
            contentType:"",
            fileName:"",
            downloadUrl:"",
            downloadType:"",
        }
    },

    computed:{

        iconType(){
            if (this.downloadUrl){
                let contentType = this.contentType;
                if (contentType === "image/png" || contentType === "image/jpeg"){
                    return "image";
                }

                if (contentType === "application/pdf"){
                    return "pdf";
                }

                return "file";
            }
            return "";
        },

        isImage(){
            if (this.downloadUrl){
                if (this.contentType === "image/png" || this.contentType === "image/jpeg"){
                    return true;
                }
            }

            return false;
        },
    },

    methods: {

        buildApiServerUrl(path){
            return Util.combineUrl(process.env.VUE_APP_API_HOST,path)
        },

        startImageLoading(){

            this.loading = true;

            let self = this;

            let urlProc = this.getFileInfo();
            if (urlProc == null){
                self.loading = false;
                return;
            }

            urlProc.then(fileInfo => {
                self.loading = false;

                console.log("FilePreview urlProcResult↓");
                console.log(fileInfo);

                if (!fileInfo){
                    self.contentType = "";
                    self.fileName = "";
                    self.downloadUrl = "";
                    self.downloadType = "";
                } else {
                    self.contentType = fileInfo.contentType;
                    self.fileName = fileInfo.fileName;
                    self.downloadUrl = fileInfo.downloadUrl;
                    self.downloadType = fileInfo.downloadType;
                }
            });
        },

        getFileInfo(){
            let self = this;
            return FileUploadAPI.fileInfo(this.fileId)
                .then(a => a);
        },

        fireDeleteEvent(){
            this.$emit('delete',this.fileId);
        },

        fireClickEvent(){

            let data = {
                fileId:this.fileId,
                contentType:this.contentType,
                fileName:this.fileName,
                downloadUrl:this.downloadUrl,
                isImage:(this.iconType === 'image'),
            };

            this.$emit('click',data);
        },


        onDownloadFile(url,fileName){
            let authToken = this.$store.state.account.idToken;
            fetch(url, { method: 'GET', headers: new Headers([["Authorization", authToken]])}).then(response => response.blob()).then(blob => {
                let anchor = document.createElement("a");
                anchor.href = window.URL.createObjectURL(blob);
                anchor.download = fileName;
                anchor.click();
            })
        },

    },

}
</script>