import axios from 'axios';
import Util from '../Util'

class FollowAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    list(followerCd){

        let path;
        if (!followerCd){
            path = "/api/follows/_self";
        } else {
            path = "/api/follows/" + followerCd;
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }


    putStaff(followerCd,staffCd){

        let path;
        if (!followerCd){
            path = "/api/follows/_self/user/" + staffCd;
        } else {
            path = "/api/follows/" + followerCd + "/user/" + staffCd;
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {
        }).then(response =>  response.data)
    }


    deleteStaff(followerCd,staffCd){

        let path;
        if (!followerCd){
            path = "/api/follows/_self/user/" + staffCd;
        } else {
            path = "/api/follows/" + followerCd + "/user/" + staffCd;
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.delete(endpoint ).then(response =>  response.data)
    }

    postGroups(followerCd,groups){

        let path;
        if (!followerCd){
            path = "/api/follows/_self/groups";
        } else {
            path = "/api/follows/" + followerCd + "/groups";
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint, {
            groups:groups
        }).then(response =>  response.data)
    }

}

let _api = new FollowAPI();

export default _api;