import axios from 'axios';
import Util from '../Util'
import moment from "moment";

class ThanksCardAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    listMyThanks(){

        let path = "/api/thanks/_self";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)

    }

    listEveryonesThanks(dateBefore){

        let path = "/api/thanks/_everyone";

        if (dateBefore){
            let qt = moment(dateBefore).format('YYYYMMDDHHmmss');
            path += "?d=" + qt;
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)

    }


    saveThanksCard(data){

        let id = data.id;

        let path = "/api/thanks";

        if (id){

            path += "/" + id;

            let endpoint =  Util.combineUrl(this.apiHost,path);

            return axios.put(endpoint, data).then(response =>  response.data)
        }
        else {
            let endpoint =  Util.combineUrl(this.apiHost,path);

            return axios.post(endpoint, data).then(response =>  response.data)
        }

    }

    deleteThanksCard(cardId){

        let path = "/api/thanks/" + cardId;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.delete(endpoint).then(response =>  response.data)

    }

    /**
     * 未読件数を取得
     * @returns {Promise<AxiosResponse<any>>}
     */
    countUnread(){

        let path = "/api/thanks/_unread_count";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    /**
     * サンクスカードを既読に設定
     * @param cardId
     * @returns {Promise<AxiosResponse<any>>}
     */
    readCard(cardId){
        let path = "/api/thanks/" + cardId + "/read";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.put(endpoint, {})
    }

}

let _api = new ThanksCardAPI();

export default _api;