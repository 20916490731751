import axios from 'axios';
import Util from '../Util'

class GroupMaintenanceAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    listGroups(){
        let path = "/api/group";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    listUnviewableGroups(groupCd){
        let path = "/api/group/" + groupCd + "/unviewable";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }


    updateGroupViewability(groupCd, data){

        let path = "/api/group/" + groupCd + "/viewability";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, data).then(response =>  response.data)
    }


}

let _api = new GroupMaintenanceAPI();

export default _api;