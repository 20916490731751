<template>
  <v-container class="entry" wrap>
    <div>
      <span class="title">{{ formatDateToHeaderDisplay(propReportDate) }}</span>
    </div>

    <v-form ref="formEdit" v-model="valid" lazy-validation>
      <v-row dense>
        <v-col class="pl-10" cols="4">
          <v-switch
            v-model="editData.all_day"
            :label="'終日'"
            :value="1"
          ></v-switch>
        </v-col>

        <v-col cols="3" v-show="!editData.all_day">
          <v-text-field
            label="開始時間"
            value=""
            type="time"
            v-model="editData.start_time"
          ></v-text-field>
        </v-col>
        <v-col cols="1" v-show="!editData.all_day">
          <v-container bg fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
              <v-flex> 〜 </v-flex>
            </v-layout>
          </v-container>
        </v-col>
        <v-col cols="3" v-show="!editData.all_day">
          <v-text-field
            label="終了時間"
            value=""
            type="time"
            v-model="editData.end_time"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="pl-10 pr-10">
          <v-text-field
            :label="titleTitle"
            placeholder=""
            v-model="editData.title"
            :counter="50"
            maxlength="50"
            :rules="titleRule"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row
        dense
        v-for="(exCont, ecIdx) in editData.extraContents"
        :key="ecIdx"
      >
        <v-col class="pl-10 pr-10">
          <v-textarea
            :label="exCont.caption"
            value=""
            hint=""
            v-model="exCont.content"
            auto-grow
            :counter="1000"
            maxlength="1000"
            required
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="pl-10 pr-10">
          <v-textarea
            name="input-7-1"
            :label="defaultContentTitle"
            value=""
            hint=""
            v-model="editData.content"
            auto-grow
            :counter="2000"
            maxlength="2000"
            :rules="defaultContentRule"
          ></v-textarea>
        </v-col>
      </v-row>

      <!--
      <v-row dense>
        <v-col class="pl-10 pr-10">
          <v-select
                  :items="customers"
                  label="得意先/仕入先"
          ></v-select>
        </v-col>
      </v-row>
-->
      <v-row dense class="pl-10">
        <v-col class="" cols="12" dense>
          <v-radio-group class="pa-0 ma-0" v-model="editData.goodOrBad" row>
            <v-radio label="通常" value="N" />
            <v-radio label="GoodNews" color="blue" value="G" />
            <v-radio label="BadNews" color="red" value="B" />
          </v-radio-group>
        </v-col>

        <v-col class="" cols="12" dense>
          <v-radio-group class="pa-0 ma-0" v-model="editData.importance" row>
            <v-radio label="通常" value="0" />
            <v-radio label="重要" color="blue" value="1" />
            <v-radio label="最重要" color="orange" value="2" />
            <v-radio label="緊急" color="red" value="3" />
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="pl-10 pr-10" cols="12" sm="6">
          <v-text-field label="タグ" placeholder="" v-model="editData.inputTag">
            <template v-slot:append-outer>
              <v-btn v-on:click="onAddTagBtnClick">追加</v-btn>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <!--
          <v-chip @click="onImportantBtnClick" class="mb-2 mr-2">
            <v-icon left>add_circle_outline</v-icon>
            重要
          </v-chip>
          -->
          <v-chip
            class="mr-2 mb-2"
            v-for="tag in tag_ac.autocompleteList"
            @click="onTagChipClick(tag)"
          >
            <v-icon left>add_circle_outline</v-icon>
            {{ tag }}
          </v-chip>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="pl-10 pr-10">
          <div class="report_tags">
            <span
              v-for="(tag, tagIdx) in editData.tags"
              class="chip_container"
              :key="tagIdx"
            >
              <v-chip close @click:close="onDeleteTag(tagIdx)">{{
                tag
              }}</v-chip>
            </span>
          </div>
        </v-col>
      </v-row>

      <!--
      <v-row dense>
        <v-col class="pl-10">
          <UserSelectField label="お知らせしたい人" :value="editData.notify_user_id"></UserSelectField>
        </v-col>
      </v-row>
      -->

      <!--
      <v-row dense>
        <v-col class="pl-10 pr-10">
          <v-file-input v-model="selected_file" label="添付ファイル" @change="onFileInputChanged"></v-file-input>
        </v-col>
      </v-row>
      -->

      <v-row dense>
        <v-col class="pl-10 pr-10 mt-5">
          <ReportFileInputBox
            :fileIds="editData.files"
            @delete="onFileDeleteClick"
            @drop="onFileDrop"
          ></ReportFileInputBox>
        </v-col>
      </v-row>

      <v-divider class="mb-3 mt-3"></v-divider>
      <v-row dense
        ><!--  justify="space-between" -->

        <!--<v-col class="pl-10">
            <v-btn v-on:click.prevent="onDeleteBtnClick" color="error">削除</v-btn>
        </v-col>-->

        <v-col class="pl-10">
          <v-btn
            v-on:click.prevent="onSaveBtnClick(1)"
            color="primary"
            class="mr-3 mb-3"
            >保存</v-btn
          >
          <v-btn
            v-if="canDraftSave"
            v-on:click.prevent="onSaveBtnClick(2)"
            class="mr-3 mb-3"
            >下書保存</v-btn
          >
          <v-btn v-on:click.prevent="onCancelBtnClick" class="mr-3 mb-3"
            >キャンセル</v-btn
          >
        </v-col>
        <v-col align="right">
          <v-btn v-if="isEditMode" v-on:click.prevent="onDeleteBtnClick"
            >削除</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDialog ref="deleteConfirmDialog"></ConfirmDialog>
  </v-container>
</template>

<script>
import Common from "@/components/Common";
import ReportEntryAPI from "@/apis/ReportEntryAPI";
import FileUploadAPI from "@/apis/FileUploadAPI";
import TagAPI from "@/apis/TagAPI";
import DateInputField from "../components/controls/DateInputField";
import TimeInputField from "../components/controls/TimeInputField";
import UserSelectField from "../components/controls/UserSelectField";
import ReportFileInputBox from "./sub/ReportFileInputBox";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";

export default {
  mixins: [Common],
  components: {
    DateInputField,
    TimeInputField,
    UserSelectField,
    ReportFileInputBox,
    ConfirmDialog,
  },
  mounted: function () {
    if (this.propReportId !== null && this.propReportId > "0") {
      this.fetchData(true);
      this.tagSearch("");
    } else {
      this.fetchData(false);
      this.tagSearch("");
      if (window.app.util.checkEnvFlag(process.env.VUE_APP_USE_REP_TEMPLATE)) {
        //console.log(
        //  "process.env.VUE_APP_USE_REP_TEMPLATE = True",
        //  process.env.VUE_APP_USE_REP_TEMPLATE
        //);
        this.editData.content = "";
      } else {
        console.log("process.env.VUE_APP_USE_REP_TEMPLATE = False");
      }

      if (window.app.util.checkEnvFlag(process.env.VUE_APP_USE_REP_EXCONTENT)) {
        this.editData.extraContents = [];
        this.extraContentCodes.forEach((excnt) => {
          this.editData.extraContents.push({
            content_no: this.editData.length,
            content_type: excnt.cd,
            content: "",
            caption: excnt.caption,
          });
        });
      } else {
        this.extraContentCodes.splice(0);
      }
    }
  },
  watch: {
    "editData.inputTag"(val) {
      // val には入力中の値が入ってくる
      this.tagSearch(val);

      //if (val !== this.tag_ac.search){
      //  this.tagSearch(val);
      //  //this.editData.inputTag = val;
      //}
    },
  },
  props: {
    propReportId: Number,
    propReportDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,

      reportDate: "",

      customers: ["小松ss粟津工場", "室戸鉄工所", "ダイワ"],

      staffs: ["丹羽常務", "田中課長", "番匠課長"],

      editData: {
        sourceIdx: 0,
        report_type: "A",
        dt: null,
        start_time: null,
        end_time: null,
        title: "",
        content: "",
        staff_cd: "",
        tags: [],
        all_day: 1,

        notify_user_id: "",
        inputTag: "",
        files: [],
        state: 1,

        goodOrBad: "N",
        importance: "0",
        extraContents: [],
      },

      extraContentCodes: [
        {
          cd: "A1",
          caption: "気付き／課題／提案／バッドニュース",
        },
        {
          cd: "A2",
          caption: "ポジティブストーリー（グッドニュース／感謝など）",
        },
        {
          cd: "A3",
          caption: "今日のトピックス（業務内容、出張報告、取引先情報など）",
        },
      ],

      userFilter: "",

      selected_file: null,

      tag_ac: {
        entries: [],
        isLoading: false,
        model: null,
        search: null,

        autocompleteList: [],

        needReload: false,
      },

      isInitialized: true,
      isFetched: false,

      updating: false,

      valid: true,
      validateRules: {
        titleRules: [
          //(v) => !!v || "タイトルは必須項目です",
          (v) =>
            !v || v.length <= 50 || "タイトルは50文字以内で入力してください",
        ],
        contentRules: [
          //(v) => !!v || "内容は必須項目です",
          (v) =>
            !v || v.length <= 2000 || "内容は2000文字以内で入力してください",
        ],
      },
    };
  },
  computed: {
    fields() {
      return [{}, {}, {}];
    },

    isEditMode() {
      if (this.propReportId !== null && this.propReportId > "0") {
        return true;
      } else {
        return false;
      }
    },

    canDraftSave() {
      if (!this.propReportId || this.editData.state == 2) {
        return true;
      } else {
        return false;
      }
    },

    titleTitle() {
      if (window.app.util.checkEnvFlag(process.env.VUE_APP_USE_REP_EXCONTENT)) {
        return "タイトル（省略可能）";
      } else {
        return "タイトル";
      }
    },
    titleRule() {
      if (window.app.util.checkEnvFlag(process.env.VUE_APP_USE_REP_EXCONTENT)) {
        return [
          (v) =>
            !v || v.length <= 50 || "タイトルは50文字以内で入力してください",
        ];
      } else {
        return [
          (v) => !!v || "タイトルは必須項目です",
          (v) =>
            !v || v.length <= 50 || "タイトルは50文字以内で入力してください",
        ];
      }
    },
    defaultContentTitle() {
      if (window.app.util.checkEnvFlag(process.env.VUE_APP_USE_REP_EXCONTENT)) {
        return "その他";
      } else {
        return "内容";
      }
    },
    defaultContentRule() {
      if (window.app.util.checkEnvFlag(process.env.VUE_APP_USE_REP_EXCONTENT)) {
        return [
          (v) =>
            !v || v.length <= 2000 || "内容は2000文字以内で入力してください",
        ];
      } else {
        return [
          (v) => !!v || "内容は必須項目です",
          (v) =>
            !v || v.length <= 2000 || "内容は2000文字以内で入力してください",
        ];
      }
    },
  },
  methods: {
    fetchData(needDataFetch) {
      let self = this;

      if (!this.isInitialized) {
        this.fetchBasicData(function () {
          self.fetchData(needDataFetch);
        });
        return;
      }

      if (needDataFetch) {
        this.loading = true;

        let reportId = this.propReportId;

        this.checkAuthToken()
          .then((a) => {
            return ReportEntryAPI.getEntry(reportId).then((response) => {
              self.makeDisplayData(response.data);
              self.loading = false;
            });
          })
          .catch(function (err) {
            console.log(err);
            self.loading = false;
            self.showErrorToast("データの取得に失敗しました。");
          });
      }
    },

    fetchBasicData(finishProc) {
      let self = this;

      self.isInitialized = true;
      if (finishProc) {
        finishProc();
      }
    },

    makeDisplayData(data) {
      //console.log(data);
      let self = this;
      let report = data.report;
      let tags = data.tags;
      let extraContents = data.extraContents;

      this.editData.report_type = "A";
      this.editData.dt = report.reportDate;
      this.editData.start_time = report.start_time;
      this.editData.end_time = report.end_time;
      this.editData.title = report.title;
      this.editData.content = report.content;
      this.editData.staff_cd = data.staff_cd;
      this.editData.all_day = report.all_day;
      this.editData.state = report.state;
      this.editData.files = data.files;

      this.editData.goodOrBad = data.goodOrBad;
      this.editData.importance = data.importance;

      if (!this.editData.goodOrBad) {
        this.editData.goodOrBad = "N";
      }
      if (!this.editData.importance) {
        this.editData.importance = "0";
      }

      this.editData.tags = [];
      tags.forEach((t) => {
        this.editData.tags.push(t.name);
      });

      this.editData.extraContents = [];
      if (window.app.util.checkEnvFlag(process.env.VUE_APP_USE_REP_EXCONTENT)) {
        this.extraContentCodes.forEach((excnt) => {
          let cd = excnt.cd;
          let ec = extraContents.find((it) => it.content_type == cd);
          if (ec) {
            this.editData.extraContents.push({
              content_no: this.editData.length,
              content_type: ec.content_type,
              content: ec.content,
              caption: excnt.caption,
            });
          } else {
            this.editData.extraContents.push({
              content_no: this.editData.length,
              content_type: cd,
              content: "",
              caption: excnt.caption,
            });
          }
        });
      }

      console.log("this.editData", this.editData);
    },

    onAddTagBtnClick() {
      let tag = this.editData.inputTag;

      if (tag && this.editData.tags.indexOf(tag) === -1) {
        this.editData.tags.push(tag);
        this.editData.inputTag = "";
        this.tag_ac.search = "";
      }
    },

    onTagChipClick(tag) {
      if (this.editData.tags.indexOf(tag) === -1) {
        this.editData.tags.push(tag);
      }
    },

    onImportantBtnClick() {
      let tag = "重要";
      this.onTagChipClick(tag);
    },

    onDeleteTag(idx) {
      this.editData.tags.splice(idx, 1);
    },

    onSaveBtnClick(state) {
      let self = this;

      let formValid = this.$refs.formEdit.validate();

      if (!formValid) {
        console.log("入力内容に不備があります");
        self.showMessageToast("入力内容に不備があります");
        return;
      }

      this.editData.staff_cd = this.account.staff_cd;

      if (this.updating) {
        return;
      }

      this.updateReport(this.editData, state, function () {
        self.showMessageToast("日報を入力しました。");
        self.backToList();
      });
    },

    onCancelBtnClick() {
      this.backToList();
    },

    async onDeleteBtnClick() {
      let self = this;

      let dialog = this.$refs.deleteConfirmDialog;

      let reportId = this.propReportId;

      if (await dialog.open("確認", "削除してもよろしいですか？")) {
        Promise.resolve()
          .then((a) => {
            this.checkAuthToken();
          })
          .then((a) => {
            return ReportEntryAPI.delete(reportId);
          })
          .then((a) => {
            self.showMessageToast("日報を削除しました。");

            self.backToList();
          })
          .catch(function (err) {
            console.log(err);
            self.showErrorToast("データの削除に失敗しました。");
          });
      }
    },

    updateReport(data, state, finishCb) {
      let self = this;
      let reportDate = this.propReportDate;

      self.updating = true;

      let updateData = {
        report_type: data.report_type,
        dt: reportDate,
        start_time: data.start_time,
        end_time: data.end_time,
        title: data.title,
        content: data.content,
        staff_cd: data.staff_cd,
        tags: data.tags,
        all_day: data.all_day,
        files: data.files,
        state: state,
        goodOrBad: data.goodOrBad,
        importance: data.importance,
        extraContents: [],
      };
      this.editData.extraContents.forEach((rec) => {
        updateData.extraContents.push({
          contentType: rec.content_type,
          content: rec.content,
        });
      });

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportEntryAPI.update(updateData, self.propReportId);
        })
        .then((a) => {
          self.updating = false;
          console.log("日報を更新しました。");
          finishCb();
        })
        .catch(function (err) {
          self.updating = false;
          console.log(err);
          self.showErrorToast("データの更新に失敗しました。");
        });
    },

    backToList() {
      let url = this.buildUrl("/entry", { dt: this.propReportDate });
      this.$router.push(url);
    },

    onFileInputChanged(files) {
      let self = this;

      console.log("onFileInputChanged");
      console.log(files);

      let progressFunc = function (progress) {
        console.log("uploadFile progress:" + progress);
      };

      FileUploadAPI.uploadFile(files, progressFunc)
        .then((a) => {
          //console.log("ファイルをアップロードしました。");
          //console.log(a);
          let fileId = a.data;
          self.editData.files.push(fileId);
          self.selected_file = null;
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの更新に失敗しました。");
        });
    },

    onFileDeleteClick(fileId) {
      //console.log("onFileDeleteClick id:" + fileId);
      let idx = this.editData.files.indexOf(fileId);
      if (idx > -1) {
        this.editData.files.splice(idx, 1);
      }
    },

    onFileDrop(files) {
      this.uploadFiles(files);
    },

    uploadFiles(files) {
      let targetFiles = [];

      if (files) {
        if (files instanceof FileList) {
          for (let i = 0; i < files.length; i++) {
            let file = files[i];
            targetFiles.push(file);
          }
        } else {
          targetFiles.push(files);
        }
      } else return;

      let self = this;
      targetFiles.forEach((file) => {
        console.log(file);

        let progressFunc = function (progress) {
          console.log("uploadFile progress:" + progress);
        };

        Promise.resolve()
          .then((a) => {
            return this.checkAuthToken();
          })
          .then((a) => {
            return FileUploadAPI.uploadFile(file, progressFunc);
          })
          .then((a) => {
            let fileId = a.data;
            console.log("ファイルをアップロードしました。fileId:" + fileId);
            //                    console.log(a);

            self.editData.files.push(fileId);
          })
          .catch(function (err) {
            console.log(err);
            self.showErrorToast("データの更新に失敗しました。");
          });
      });
    },

    tagSearch(inputValue) {
      console.log("tagSearch " + inputValue);

      let self = this;

      if (self.tag_ac.isLoading) {
        self.tag_ac.needReload = true;
        return;
      }

      //console.log("tagSearch");
      //console.log(inputValue);

      self.tag_ac.isLoading = true;
      self.tag_ac.needReload = false;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return TagAPI.listTags(inputValue);
        })
        .then((response) => {
          self.tag_ac.autocompleteList.splice(0);
          response.data.forEach((rec) => {
            self.tag_ac.autocompleteList.push(rec.name);
          });
        })
        .catch(function (err) {
          console.log(err);
          self.tag_ac.needReload = false;
          self.showErrorToast("データの取得に失敗しました。");
        })
        .finally(() => {
          self.tag_ac.isLoading = false;

          if (self.tag_ac.needReload) {
            self.tagSearch(self.editData.inputTag);
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
