<template>
  <v-container>
    <!-- <v-fade-transition mode="out-in"> -->
    <div><span class="title">日報検索</span></div>

    <v-row>
      <v-container>
        <v-form>
          <!-- 1行目 日付範囲-->
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12" lg="6" class="ma-0 pa-0">
              <v-container class="ma-0 pa-0">
                <v-row dense>
                  <v-col class="ma-0 pa-0" cols="12" md="6">
                    <v-container class="ma-0 pa-0" dense>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="ma-0 pa-0" cols="11">
                          <DateInputField
                            v-model="condition.startDate"
                            label="検索開始日"
                          ></DateInputField>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>

                  <v-col class="ma-0 pa-0" cols="12" md="6">
                    <v-container class="ma-0 pa-0" dense>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2">
                          <v-container>
                            <v-row>
                              <v-col class="pr-5">〜</v-col>
                            </v-row>
                          </v-container>
                        </v-col>

                        <v-col class="ma-0 pa-0" cols="10">
                          <DateInputField
                            v-model="condition.endDate"
                            label="検索終了日"
                          ></DateInputField>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

          <!-- 2行目 キーワード、タグ -->
          <v-row dense class="pl-4 pr-1">
            <v-col class="mt-0 pt-0" cols="12" sm="6">
              <v-text-field
                v-model="condition.searchText"
                :counter="50"
                @keydown.enter="onConditionEnter"
                label="検索ワード"
              ></v-text-field>
            </v-col>

            <v-col class="mt-0 pt-0" cols="12" sm="3">
              <v-text-field
                v-model="condition.tag"
                :counter="30"
                @keydown.enter="onConditionEnter"
                label="ハッシュタグ"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="pl-4 pr-1">
            <v-col class="mt-0 pt-0" cols="12" sm="3">
              <v-select
                :items="groupsWithAll"
                label="グループ"
                item-text="group_name"
                item-value="group_cd"
                v-model="condition.groupCd"
              ></v-select>
            </v-col>
            <v-col class="mt-0 pt-0" cols="12" sm="3">
              <v-text-field
                v-model="condition.staffCd"
                :counter="StaffCdLength"
                @keydown.enter="onConditionEnter"
                label="社員番号"
              ></v-text-field>
            </v-col>
            <v-col class="mt-0 pt-0" cols="12" sm="6">
              <v-text-field
                v-model="condition.staffName"
                label="社員名"
                @keydown.enter="onConditionEnter"
                :counter="20"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense class="pl-4 pr-1">
            <v-col class="mt-0 pt-0" cols="12" sm="6">
              <v-select
                v-model="condition.importance"
                :hint="`${importanceHint} `"
                :items="importanceList"
                item-text="name"
                item-value="value"
                label="重要度"
                persistent-hint
                return-object
              />
              <!-- single-line gobList-->
            </v-col>

            <v-col class="mt-0 pt-0" cols="12" sm="6">
              <v-select
                v-model="condition.goodOrBad"
                :hint="` `"
                :items="gobList"
                item-text="name"
                item-value="value"
                label="Good or Bad"
                persistent-hint
                return-object
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-row>

    <v-row dense>
      <v-col class="mt-0 pt-0">
        <v-btn color="primary" @click="onSearchBtnClick">検索</v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col class="mt-5 pt-0">
        <v-pagination
          v-model="page"
          :length="pageCount"
          v-show="fetched"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="reportList"
      :items-per-page="10"
      :loading="loading"
      :page.sync="page"
      :options.sync="tableOptions"
      :server-items-length="totalCount"
      v-on:update:options="onUpdateOptions"
      item-key="report_id"
      no-data-text="データがありません。"
      loading-text="Loading... Please wait"
      class="elevation-0"
      hide-default-footer
    >
      <!-- テーブルボディ -->
      <template slot="item" slot-scope="props">
        <tr v-on:click="onRecordClick(props.item.report_id)">
          <td class="text-xs-center">
            {{ formatDateToDisplay(props.item.report_date) }}
          </td>
          <td class="text-xs-center">
            {{ props.item.staff_name }}<br /><span class="caption">{{
              props.item.staff_cd
            }}</span>
          </td>
          <td class="text-xs-center">
            <v-container class="ma-0 pa-0">
              <v-row dense>
                <v-col> 【 {{ props.item.title }} 】 </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  {{ props.item.content }}
                </v-col>
              </v-row>
            </v-container>
          </td>
        </tr>
      </template>

      <!-- 検索結果なし -->
      <v-alert slot="no-results" :value="true" color="error" icon="warning">
        "{{ search }}" の検索結果なし
      </v-alert>
      <!-- データなしの時の表示 -->
      <template slot="no-data"> No Data </template>

      <!-- フッターの件数表示 -->
      <template slot="pageText" slot-scope="props">
        {{ props.itemsLength }} 件中 {{ props.pageStart }} 件目 〜
        {{ props.pageStop }} 件目
      </template>
    </v-data-table>

    <v-row dense>
      <v-col class="mt-0 pt-0">
        <v-pagination
          v-model="page"
          :length="pageCount"
          v-show="fetched"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Common from "@/components/Common";
import ReportSearchAPI from "@/apis/ReportSearchAPI";
import DateInputField from "../components/controls/DateInputField";
import EnvSupport from "../components/EnvSupport";

export default {
  mixins: [Common, EnvSupport],
  components: {
    DateInputField,
  },
  mounted: function () {
    this.setupConditions();
  },
  props: [],
  watch: {
    $route: function (to, from) {
      this.setupConditions();
    },
  },
  data() {
    return {
      condition: {
        startDate: "",
        endDate: "",
        groupCd: "",
        staffCd: "",
        staffName: "",
        searchText: "",
        tag: "",
        goodOrBad: null,
        importance: { name: "全て", value: "0" },
      },

      importanceList: [
        { name: "全て", value: "0" },
        { name: "重要", value: "1" },
        { name: "最重要", value: "2" },
        { name: "緊急", value: "3" },
      ],

      gobList: [
        { name: "全て", value: "" },
        { name: "GoodNews", value: "G" },
        { name: "BadNews", value: "B" },
      ],

      fetched: false,

      valid: true,
      rules: {
        staffCd: [
          (v) =>
            (v && v.length <= 3) || "社員番号は3文字以内で入力してください",
        ],
      },

      reportList: [
        {
          report_id: null,
          report_type: null,
          report_date: null,
          title: null,
          content: null,
          staff_cd: null,
          staff_name: null,
          group_cd: null,
          group_name: null,
        },
      ],
      headers: [
        {
          text: "日付",
          align: "center",
          value: "report_date",
          sortable: true,
          width: "10em",
        },
        {
          text: "担当者",
          align: "center",
          value: "staff_cd",
          sortable: true,
          width: "10em",
        },
        {
          text: "タイトル/内容",
          align: "center",
          value: "content",
          sortable: false,
        },
      ],
      pagination: {
        descending: true,
        rowsPerPage: 10,
      },
      tableOptions: {},
      totalCount: 0,
      page: 1,

      // ローディングの表示フラグ
      loading: false,
    };
  },
  computed: {
    pageCount: function () {
      return Math.ceil(this.totalCount / this.pagination.rowsPerPage);
    },
    importanceHint() {
      if (this.condition.importance) {
        if (this.condition.importance.value == "0") {
          return "";
        } else {
          return this.condition.importance.name + " 以上";
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    setupConditions() {
      this.reportList.splice(0);
      this.fetchData(true);
    },

    fetchData(needDataFetch) {
      let self = this;

      if (!this.isInitialized) {
        this.fetchBasicData(function () {
          self.fetchData(needDataFetch);
        });
        return;
      }

      if (needDataFetch) {
        //this.search();
      }
    },

    fetchBasicData(finishProc) {
      let self = this;

      self.isInitialized = true;
      if (finishProc) {
        finishProc();
      }
    },

    onUpdateOptions() {
      //console.log("onUpdateOptions");
      if (this.fetched) {
        this.search();
      }
    },

    makeDisplayData(data) {
      //console.log(data);

      this.reportList.splice(0);

      let sourceList = data.list;

      sourceList.forEach((rec) => {
        this.reportList.push({
          report_id: rec.report_id,
          report_type: rec.report_type,
          report_date: rec.report_date,
          title: rec.title,
          content: rec.content,
          staff_cd: rec.staff_cd,
          staff_name: rec.staff_name,
          group_cd: rec.group_cd,
          group_name: rec.group_name,
        });
      });

      this.totalCount = data.totalCount;
    },

    onConditionEnter(event) {
      //console.log("onConditionEnter keyCode=" + event.keyCode)
      // 通常のEnterはkeyCode = 13
      // 日本語入力中のEnterはkeyCode = 229を返す
      if (event.keyCode === 13) {
        this.onSearchBtnClick();
      }
    },

    onSearchBtnClick() {
      this.fetched = false;
      this.page = 1;
      this.search();
    },

    search() {
      let self = this;

      this.loading = true;
      this.fetched = false;

      //console.log("search");
      //console.log(this.tableOptions);

      let itemsPerPage = this.tableOptions.itemsPerPage;
      let page = this.page;
      let sortBy = this.tableOptions.sortBy; // array field name
      let sortDesc = this.tableOptions.sortDesc; // array true or false

      let goodOrBad = null;
      if (this.condition.goodOrBad) {
        goodOrBad = this.condition.goodOrBad.value;
      }
      let importance = null;
      if (this.condition.importance) {
        importance = this.condition.importance.value;
      }

      let searchCondition = {
        searchText: this.condition.searchText,
        startDate: this.condition.startDate,
        endDate: this.condition.endDate,
        staffCd: this.condition.staffCd,
        staffName: this.condition.staffName,
        groupCd: this.condition.groupCd,
        tag: this.condition.tag,
        goodOrBad: goodOrBad,
        importance: importance,
        options: {
          itemsPerPage: itemsPerPage,
          page: page,
          sortBy: sortBy,
          sortDesc: sortDesc,
        },
      };

      //console.log(searchCondition);

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportSearchAPI.search(searchCondition);
        })
        .then((response) => {
          self.makeDisplayData(response.data);
          self.loading = false;
          self.fetched = true;
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの取得に失敗しました。");
          self.loading = false;
        });
    },

    onRecordClick(reportId) {
      if (reportId) {
        //this.$router.push("/report/" + reportId )
        //reportDialog
        this.reportDialogRef.open(reportId, {});
        //let dialogResult = await this.commentDialogRef.open(originalMessage,{});
      }
    },
  },
};
</script>
<style lang="scss"></style>
