import axios from 'axios';
import Util from '../Util'

class ReportEntryAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    list(reportDate){

        let path = "/api/entries/_self/" + reportDate;
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    tasks(reportDate){

        let path = "/api/entries/_self/" + reportDate + "/tasks";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    /**
     * 入力用の日報情報を取得する
     * @param entryId
     * @returns {Promise<AxiosResponse<any>>}
     */
    getEntry(entryId){

        let path = "/api/entry/" + entryId + "/forentry";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    update(cond,entryId){

        let path = "/api/entry";
        if (entryId){
            path += "/" + entryId
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint, cond).then(response =>  response.data)
    }


    delete(entryId){

        let path = "/api/entry/" + entryId;

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.delete(endpoint).then(response =>  response.data)
    }

}

let _api = new ReportEntryAPI();

export default _api;