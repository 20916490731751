<template>
    <v-sheet round>
        <v-slide-group
                v-model="emoteModel"
                class="pa-4"
                active-class="success"
                show-arrows
        >
            <v-slide-item
                    v-for="em in emotes"
                    :key="em.emote_cd"
                    v-slot:default="{ active, toggle }"
            >

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <a class="emote_icon mr-1" :class="'type' + em.emote_cd" v-on="on" v-on:click="fireEmoteClick(em.emote_cd)">
                            <img :src="'/icons/emote' + em.emote_cd + '.svg'" :alt="em.name">
                        </a>
                    </template>
                    <span>{{em.name}}</span>
                </v-tooltip>
            </v-slide-item>
        </v-slide-group>

    </v-sheet>
</template>
<script>
export default {
    props: {
        options:Object,
        emotes:Array,
        targetObject:Object,
    },
    mounted: function() {
    },
    destroyed() {
    },
    data() {
        return {
            emoteModel:false
        }
    },
    computed: {},
    methods: {
        fetchData(){

        },
        fireEmoteClick(emote){
            this.$emit('select',{
                targetObject:this.targetObject,
                emote:emote,
            });
        }
    },
}
</script>