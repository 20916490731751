import axios from 'axios';
import Util from '../Util'

class CalendarAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    listWorkingCalendar(year,month){
        let path = "/api/calendar/" + year + "/" + month;
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    updateWorkingCalendar(data){

        let path = "/api/calendar";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint, data).then(response =>  response.data)
    }

    updateUserCalendar(data){

        let path = "/api/calendar/_self";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint, data).then(response =>  response.data)
    }

}

let _api = new CalendarAPI();

export default _api;