import axios from 'axios';
import Util from '../Util'

class EntryCheckAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    getData(data){

        let path = "/api/entrycheck";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint,data).then(response =>  response.data)
    }

    export(y,m){

        let path = "/api/entrycheck/export/" + y + "/" + m;

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint,{}).then(response =>  response.data)
    }


}

let _api = new EntryCheckAPI();

export default _api;