<template>

    <v-dialog v-model="dialog"  @keydown.esc="cancel">
        <v-card class="black">
            <v-card-title class="title white--text"></v-card-title>
            <v-img  :src="imageSrc" contain :max-height="imageHeight"></v-img>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
    import ImageView from "@/components/controls/ImageView";
    export default {
        components : {
            ImageView
        },
        props: [
        ],
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                message: null,
                title: null,
                imageSrc:"",
                options: {
                    color: 'primary',
                    width: 290
                }
            };
        },
        computed:{
            imageHeight(){
                let winHeight = window.innerHeight;
                return Math.max(winHeight * 0.8,400);
            }
        },
        methods: {
            open(src,options) {
                this.dialog = true;
                this.imageSrc = src;
                this.options = Object.assign(this.options, options);
            },
            cancel() {
                this.dialog = false;
            },
        },

    }
</script>