<template>
    <v-container wrap>
        <div><span class="title">プロフィール設定</span></div>

        <v-form ref="formEdit">
            <v-row>
                <v-col>
                    <label class="v-label theme--light">プロフィール画像</label>
                    <v-container>
                        <v-row>
                            <v-col md="auto">
                                <v-avatar :color="editData.avatarColor" size="62">
                                    <span v-if="!editData.avatarFileId" class="white--text headline">
                                        {{editData.nameInitial}}
                                    </span>
                                    <ImageView v-if="editData.avatarFileId" :imageId="editData.avatarFileId" :width="100"></ImageView>
                                </v-avatar>
                            </v-col>
                            <v-col align-self="center">
                                <a v-on:click.prevent="uploadDialog = true">
                                    <span v-if="editData.avatarFileId" class="body-2">画像を変更</span>
                                    <span v-if="!editData.avatarFileId" class="body-2">画像を設定</span>
                                </a>
                                <br>
                                <a v-if="!!editData.avatarFileId"  v-on:click.prevent="editData.avatarFileId = null">
                                    <span  class="body-2">削除</span>
                                </a>
                                <a v-if="!editData.avatarFileId" v-on:click.prevent="colorDialog = true" >
                                    <span class="body-2">色を変更</span> <!--   -->
                                </a>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-col>

            </v-row>

            <v-row no-gutters class="ml-2">
                <v-col class="mr-2">
                    <v-text-field v-model="editData.familyName" label="姓" readonly></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field v-model="editData.givenName" label="名" readonly></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters class="ml-2">
                <v-col>
                    <v-text-field v-model="editData.name" label="表示名" readonly></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters class="ml-2">
                <v-col>
                    <v-text-field v-model="editData.groupName" label="所属グループ" readonly></v-text-field>
                </v-col>
            </v-row>

            <v-row no-gutters class="ml-2">
                <v-col>
                    <v-checkbox label="自動で既読とする" v-model="editData.useAutoRead"></v-checkbox>
                </v-col>
            </v-row>

            <v-row dense no-gutters>
                <v-col class="ml-2">
                    <v-switch v-model="editData.passwordChange" label="パスワード変更"></v-switch>
                </v-col>
            </v-row>
            <v-row v-if="editData.passwordChange" dense no-gutters>
                <v-col>
                    <v-container>
                        <v-row dense no-gutters>
                            <v-col cols="12" class="mb-2">
                                <v-text-field v-model="editData.currentPassword" label="現在のパスワード" required type="password" :rules="rules.oldPassword"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="mb-2">
                                <v-text-field v-model="editData.newPassword1" label="新しいパスワード" required type="password" :rules="rules.newPassword"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="editData.newPassword2" label="新しいパスワード(確認)" required type="password" :rules="rules.password2"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
                <v-col></v-col>
            </v-row>

        </v-form>

        <v-row>
            <v-col>
                <v-btn  color="primary" @click="updateUser">更新</v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="uploadDialog" max-width="500px" :persistent="true">
            <v-card>
                <v-card-title>
                    <span class="headline">アップロード</span>
                </v-card-title>

                <v-card-text>

                    <v-container class="file-drop-area" ref="uploadTarget" v-on:dragover="onDragOver" v-on:dragenter="onDragEnter" v-on:dragleave="onDragLeave" v-on:drop="onDrop">

                        <v-row>
                            <v-col>
                                画像ファイルをドロップしてください
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1"></div>

                    <v-btn @click="uploadDialog=false">キャンセル</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="colorDialog" max-width="500px" :persistent="true">
            <v-card>
                <v-card-title>
                    <span class="headline">背景色</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col v-for="color in colors" :key="color" cols="4">
                                <a v-on:click.prevent="editData.avatarColor = color;colorDialog = false;">
                                    <v-card flat tile class="d-flex" :color="color" min-height="60" min-width="60">
                                    </v-card>
                                </a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn @click="colorDialog=false">キャンセル</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import Common from '@/components/Common';
import FileUploadAPI from '@/apis/FileUploadAPI';
import UserAPI from '@/apis/UserAPI';
import ImageView from "../components/controls/ImageView";
import Authentication from '../authentication'
import PasswordValidator from 'password-validator';

export default {
    mixins:[Common],
    components : {
        ImageView
    },
    props: [
        'fileIds'
    ],
    mounted: function() {
        this.setupConditions();
        this.passwordSchema = new PasswordValidator();
        this.passwordSchema
            .is().min(6)
            .has().uppercase()
            .has().lowercase()
            .has().digits()
            .has().not().spaces();
    },
    data() {
        return {

            editData:{
                userId:"",
                staffCd : "",
                givenName : "",
                familyName : "",
                name : "",
                groupCd : "",
                groupName : "",
                avatarFileId:null,
                avatarColor:"#E0E0E0",
                passwordChange:false,
                currentPassword:"",
                newPassword1:"",
                newPassword2:"",
                useAutoRead:true,
            },

            selected_file:null,

            rules: {
                oldPassword:[
                    value => !!value || '必須入力です',
                ],
                newPassword:[
                    value => !!value || '必須入力です',
                    v => v.length >= 6 || '6文字以上のパスワードを設定してください',
                    v =>  this.passwordSchema.validate(v) || '大文字、小文字、数字で構成されるパスワードを設定してください',
                ],
                password2:[
                    value => value === this.editData.newPassword1 || 'パスワードが異なります',
                ],
            },

            uploadDialog:false,
            colorDialog:false,

            colors:["#4FC3F7",'#4DD0E1','#4DB6AC','#DCE775','#FFF176','#FFB74D','#A1887F','#90A4AE','#F06292'],

            passwordSchema:null,
        }
    },
    methods: {
        setupConditions(){
            this.fetchData(true);
        },

        fetchData(needDataFetch){
            let self = this;

            if (!this.isInitialized){
                this.fetchBasicData(function () {
                    self.fetchData(needDataFetch)
                });
                return;
            }

            if (needDataFetch){

                this.loading = true;

                Promise.resolve()
                    .then(a => { return this.checkAuthToken() })
                    .then(a => { return UserAPI.myProfile() })
                    .then(response => {
                        self.makeDisplayData(response.data);
                        self.loading = false;
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.loading = false;
                        self.showErrorToast("データの取得に失敗しました。");
                    });

            }

        },
        fetchBasicData(finishProc){

            let self = this;

            self.isInitialized = true;
            if (finishProc){
                finishProc();
            }

        },


        makeDisplayData(data){

            console.log(data);

            let user = data.user;

            this.editData.staffCd = user.staff_cd;
            this.editData.userId = user.user_id;
            this.editData.name = user.name;
            this.editData.givenName = user.given_name;
            this.editData.familyName = user.family_name;
            this.editData.groupCd = user.group_cd;
            this.editData.groupName = this.getGroupName(user.group_cd);
            this.editData.useAutoRead = (data.settings.use_auto_read == 1);

            this.editData.avatarFileId = user.avatar_file_id;
            this.editData.nameInitial = data.nameInitial;
            this.editData.avatarColor = data.user.avatar_color;
            this.editData.passwordChange = false;
            this.editData.currentPassword = "";
            this.editData.newPassword1 = "";
            this.editData.newPassword2 = "";


        },

        // --------------------------------------
        // 更新処理
        // --------------------------------------

        updateUser(){
            let self = this;

            let formValid = this.$refs.formEdit.validate();

            if (!formValid){
                console.log("入力内容に不備があります");
                return;
            }

            let editData = this.editData;

            let updateData = {
                avatar_file_id:this.editData.avatarFileId,
                avatar_color:this.editData.avatarColor,
                use_auto_read:this.editData.useAutoRead,
            };

            Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return this.changePassword(editData) })
                .then(a => { return UserAPI.updateMyProfile(updateData) })
                .then(response => {
                    self.showMessageToast("更新しました。");

                    // storeにprofileを保存する
                    if (response.data){
                        self.$store.commit('setProfile', response.data);
                    }

                    self.editData.passwordChange = false;
                    self.editData.currentPassword = "";
                    self.editData.newPassword1 = "";
                    self.editData.newPassword2 = "";
                })
                .catch(function(err) {
                    console.log(err);
                    self.showErrorToast("データの更新に失敗しました。");
                });

        },

        changePassword(data){
            if (this.editData.passwordChange
                && !!this.editData.newPassword1
                && this.editData.currentPassword != this.editData.newPassword1){
                return Authentication.changePassword(this.editData.currentPassword , this.editData.newPassword1)
            } else {
                return new Promise(function(resolve, reject){
                    resolve("");
                });
            }
        },


        // --------------------------------------
        // ファイルのドロップ処理
        // --------------------------------------
        findTargetElement(){
            return this.$refs.uploadTarget;
        },

        onDrop(e){
            e.preventDefault();
            e.stopPropagation();

            let elm = this.findTargetElement();
            elm.classList.remove('filehover');


            //console.log("onDrop ");

            let files = e.dataTransfer.files;
            //console.log(files);
            //this.processFiles(files);

            this.uploadFiles(files);
        },

        onDragOver(e){
            e.preventDefault();
            let elm = this.findTargetElement();
            elm.classList.add('filehover');
        },
        onDragEnter(e){
            e.preventDefault();
        },
        onDragLeave(e){
            e.preventDefault();
            let elm = this.findTargetElement();
            elm.classList.remove('filehover');
        },


        uploadFiles(files){

            let targetFiles = [];

            if (files){
                if (files instanceof FileList){
                    for (let i=0; i<files.length; i++) {
                        let file = files[i];
                        targetFiles.push(file);
                    }
                } else {
                    targetFiles.push(files);
                }
            } else return;

            targetFiles = targetFiles.filter(file => {
                return file.type === "image/png" || file.type === "image/jpeg";
            });

            let self = this;
            if (targetFiles.length > 0){
                let file = targetFiles[0];

                //console.log(file);

                let progressFunc = function(progress){
                    console.log("uploadFile progress:" + progress);
                };

                Promise.resolve()
                    .then(a => { return this.checkAuthToken() })
                    .then(a => { return FileUploadAPI.uploadFile(file,progressFunc) })
                    .then(a => {

                        let fileId = a.data;
                        self.editData.avatarFileId = fileId;
                        self.uploadDialog = false;

                    })
                    .catch(function(err) {
                        console.log(err);
                        self.showErrorToast("データの更新に失敗しました。");
                    });

            }
        }

    },

}
</script>