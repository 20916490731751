<template>
    <v-container class="user-working-calendar">

        <div><span class="title">個別カレンダー設定</span></div>

        <v-row>
            <v-col>
                <span class="mr-3">{{year}}年 {{month}}月</span>

                <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="monthPickerValue"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on">選択</v-btn>
                    </template>

                    <v-date-picker
                            v-model="monthPickerValue"
                            type="month"
                            no-title
                            scrollable
                            show-current="false"
                            locale="ja-jp"
                            @click:month="onMonthSelect"
                    >
                    </v-date-picker>
                </v-menu>

            </v-col>
            <v-col>
                <table>
                    <tr>
                        <td class="isWorkingDay">全社稼働日</td>
                        <td class="isHoliday">全社定休日</td>
                        <td class="isWorkingDay2">休日出勤日</td>
                        <td class="isHolyDay2">個別休暇日</td>
                    </tr>
                </table>
            </v-col>
        </v-row>




        <table v-show="!loading">
            <thead>
                <tr>
                    <td v-for="w in weeksHeaders" :class="{isSat:(w.weekDay === 6),isSun:(w.weekDay === 0)}">{{w.caption}}</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="w in weeks">
                    <td v-for="dateRow in w"
                        :class="[dateRow.otherMonth ? 'isOtherMonth' : '',(dateRow.weekDay === 6) ? 'isSat':'',(dateRow.weekDay === 0) ? 'isSun' : '', dateRow.commonFlg ? 'isWorkingDay' : 'isHoliday',(dateRow.userFlg === null) ? '' : (dateRow.userFlg ? 'isWorkingDay2':'isHolyDay2') ]"
                        v-on:click="onDateClick(dateRow)"
                    >
                        {{dateRow.caption}}
                    </td>
                </tr>
            </tbody>
        </table>


        <v-container v-if="loading" fluid fill-height>
            <v-row align="center" justify="center">
                <v-col cols="2">
                    <v-progress-circular
                            v-if="loading"
                            :size="50"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-container>


        <v-row v-show="!loading">
            <v-col>
                <v-btn v-on:click.prevent="updateCalendar()" color="primary">
                    保存
                </v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import Common from '@/components/Common';
import moment from 'moment';
import CalendarAPI from  '@/apis/CalendarAPI';

export default {
    mixins:[Common],
    mounted:function() {
        let m = moment();
        this.monthPickerValue = m.format('YYYY-MM');
        this.year = m.year();
        this.month = m.month() + 1;

        this.weeks.splice(0);

        this.fetchData();
    },
    watch: {
        //'month':function (to, from) {
        //    this.makeData()
        //},
    },
    data() {
        return {
            loading:false,
            menu:false,
            year:"",
            month:"",
            monthPickerValue:"",
            today: '2019-01-10',

            // 0〜6 0:日 1:月  2:火  3:水
            weeksHeaders:[
                {caption:'日',weekDay:0},
                {caption:'月',weekDay:1},
                {caption:'火',weekDay:2},
                {caption:'水',weekDay:3},
                {caption:'木',weekDay:4},
                {caption:'金',weekDay:5},
                {caption:'土',weekDay:6}
            ],

            weeks:[
                [{
                    date:"",
                    caption:"1",
                    weekDay:0,
                    otherMonth:false,
                    workingDay:false,
                    commonFlg:true,
                    userFlg:null,
                }]
            ],


        }
    },
    computed: {
    },
    methods: {


        fetchData(){

            let self = this;

            if (!this.year){
                return;
            }

            this.loading = true;

            Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => {
                    return CalendarAPI.listWorkingCalendar(this.year,this.month)
                })
                .then(a =>{

                    this.makeData(a.data);

                    this.loading = false;
                })
                .catch(function(err) {
                    self.loading = false;
                    console.log(err);
                    self.showErrorToast("データの取得に失敗しました。");
                });
        },

        makeData(responseData){

            //console.log(data);

            if (!this.year){
                return;
            }

            //val commonList:List<Calendar>,val userList:List<UserCalendar>,val compoundList:List<Calendar>
            let  commonList = responseData.commonList;
            let userList = responseData.userList;

            this.weeks.splice(0);

            //let startDay = moment("" + this.year + "-" + this.month + "-01","YYYY-MM-DD");
            let startDay = moment(
                {year:this.year,month:this.month - 1,day:1}
            );

            let endDay   = startDay.clone().endOf("month"); // 月末日
            let monthVal = startDay.month();
            let startWeekDay = startDay.day();

            //console.log("month = " + this.month);
            //console.log("startDay = " + startDay.format('YYYY-MM-DD'));
            //console.log("startWeekDay = " + startWeekDay);

            let startDay2 = startDay.clone().subtract(startWeekDay,'d');
            let endDay2 = endDay.clone().endOf("week");

            let dt = startDay2;

            let weekRow = null;



            //console.log("startDay2 = " + startDay2.format('YYYY-MM-DD'));
            //console.log("startDay2.day = " + startDay2.day());

            while (dt.isSameOrBefore(endDay2)){

                if (dt.day() === 0){
                    weekRow = [];
                    this.weeks.push(weekRow);
                }

                let dtStr = dt.format('YYYY-MM-DD');

                let entity = {
                    date:dt,
                    caption:dt.date(),
                    weekDay:dt.day(),
                    otherMonth:dt.month() !== monthVal,
                    workingDay:true,
                    commonFlg:true,
                    userFlg:null,
                };

                let savedCommonEntity = commonList.find((v) => v.dt === dtStr);
                let savedUserEntity = userList.find((v) => v.dt === dtStr);

                //if (savedEntity){
                //    entity.workingDay = (savedEntity.workday == 1)
                //}
                if (savedCommonEntity){
                    entity.commonFlg = (savedCommonEntity.workday == 1)
                }
                if (!!savedUserEntity && (savedUserEntity.workday !== undefined)){
                    entity.userFlg = (savedUserEntity.workday == 1)
                }


                weekRow.push(entity);

                dt = dt.clone().add(1,'d')

            }



        },

        onMonthSelect(){
          this.menu = false;

            let d = moment(this.monthPickerValue + "-01","YYYY-MM-DD");
            this.year = d.year();
            this.month = d.month() + 1;

            this.fetchData();
        },

        onDateClick(dateRow){
            if (!dateRow.otherMonth){
                if (dateRow.commonFlg){
                    // 稼働日

                    if (dateRow.userFlg == null || !!dateRow.userFlg){
                        dateRow.userFlg = false;
                    } else {
                        dateRow.userFlg = null;
                    }

                } else {
                    // 休日

                    if (dateRow.userFlg == null || !dateRow.userFlg){
                        dateRow.userFlg = true;
                    } else {
                        dateRow.userFlg = null;
                    }

                }

            }

        },

        updateCalendar(){

            let self = this;

            let calendarData = {
                year:this.year,
                month:this.month,
                list:[],
            };

            for(let w of this.weeks){
                for(let d of w){
                    if (!d.otherMonth){

                        // commonFlg , userFlg から判定
                        let workingDay = this.calcWorkingFlg(d.commonFlg,d.userFlg);

                        calendarData.list.push({
                            working: workingDay,
                            date:d.date.format('YYYY-MM-DD'),
                        });
                    }
                }
            }

            //console.log(calendarData);

            Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => {
                    return CalendarAPI.updateUserCalendar(calendarData)
                })
                .then(a =>{
                    self.showMessageToast("カレンダーを更新しました。");
                })
                .catch(function(err) {
                    console.log(err);
                    self.showErrorToast("データの登録に失敗しました。");
                });


        },

        calcWorkingFlg(commonFlg,userFlg){

            if (userFlg === null){
                return commonFlg;
            }
            else {
                return userFlg;
            }

        },

    },

}
</script>
<style lang="scss" scoped>
.user-working-calendar {
    table {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        border-collapse:collapse;

        td , th {
            margin: 0;
            padding: 5px;
            box-sizing:border-box;
            border: 1px solid #ccc;
        }

        thead {
            tr {
                th,td {

                }

                background: #eee;
            }
        }

        tbody {
            tr {
                td{
                    height: 40px;
                    text-align: center;
                }
            }
        }

        .isSat {
            color: #3d5afe;
        }

        .isSun {
            color: #b71c1c;
        }

        .isWorkingDay {

        }

        .isHoliday {
            background: #ce93d8;
        }

        .isWorkingDay2 {
            background: #1de9b6;
        }

        .isHolyDay2 {
            background: #ccff90;
        }

        .isOtherMonth {
            background: #90a4ae;
        }
    }
}
</style>