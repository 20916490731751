<script>
    export default {
        mounted: function() {
        },

        data() {
            return {
            }
        },
        computed: {
            env(){
                return window.app.env;
            },
            AppTitle(){
                return window.app.env.VUE_APP_TITLE;
            },
            StaffCdLength(){
                return window.app.env.VUE_APP_STAFF_CD_LEN;
            },
        },
        methods: {
            hasRole(roleName){
                if (roleName){
                    // 配列だったら何かが含まれていたらOKとする
                    if (Array.isArray(roleName)){
                        return roleName.some(rn => this.profileRoles.includes(rn))
                    } else {
                        return this.profileRoles.includes(roleName);
                    }
                } else {
                    return true;
                }
            },
        },

    }
</script>