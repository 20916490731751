import axios from 'axios';
import Util from '../Util'

class ReportForwardAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    putForward(reportId,staffCdList,comment){

        let path = "/api/entry/" + reportId + "/forward";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {
            report_id:reportId,
            staff_cds:staffCdList,
            comment:comment
        }).then(response =>  response.data)
    }


}

let _api = new ReportForwardAPI();

export default _api;