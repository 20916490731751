import axios from 'axios';
import Util from '../Util'

class AttendanceAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    getLeaveTime(dt) {
        let path = "/api/attendance/_self/" + dt + "/leave";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    saveLeaveTime(dt,time){

        let path = "/api/attendance/_self/" + dt + "/leave";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {
            leave_time:time
        }).then(response =>  response.data)
    }

    list(dt,count) {

        let path = "/api/attendance/list/" + dt;
        if (count){
            path += "?count=" + count;
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    download(dt,count) {

        let path = "/api/attendance/download/" + dt;
        if (count){
            path += "?count=" + count;
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint,{}).then(response =>  response.data)
    }
}

let _api = new AttendanceAPI();

export default _api;