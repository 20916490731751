const eventHub = {
    install: function (Vue, options) {
        Vue.prototype.$eventHub = new Vue()
    }
};
export default eventHub

/*
使い方
main.jsとかで

import EventHub from './path/to/EventHub'
Vue.use(EventHub)


イベントを受け取りたいコンポーネントのmountで

mounted: function () {
  this.$eventHub.$off('save-complete-notify',  this.fooMethod)
  this.$eventHub.$on('save-complete-notify', this.fooMethod)
}

イベントを受け取りたいコンポーネントのdestroyで
beforeDestroy() {
  this.$eventHub.$off('save-complete-notify', this.fooMethod);
}

イベントの発行は

this.$eventHub.$emit('save-complete-notify', '保存したよ')



参照：
https://qiita.com/ysKuga/items/ace900e8f4298a5d713a
https://codeday.me/jp/qa/20190320/440901.html


*/