let themes = {
    daiwa:{
        primary: '#009688',
        secondary: '#ffc107',
        accent: '#ff9800',
        error: '#f44336',
        warning: '#673ab7',
        info: '#8bc34a',
    },
};
module.exports = Object.freeze(themes);