<script>
  import { mapMutations } from 'vuex'
  import { mapState } from 'vuex'
  import moment from 'moment';
  import "moment/locale/ja";
  moment.locale('ja');
  import LoginSupport from "./LoginSupport";
  import Util from '../Util'
  import TimelineAPI from '@/apis/TimelineAPI';

  export default {
    mixins:[LoginSupport],
    props: {
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapState([
        'account','groups','groupsWithBlank','users','emotes'
      ]),
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        groupsWithBlank(){
            return this.$store.getters.groupsWithBlank
        },
        groupsWithAll(){
            return this.$store.getters.groupsWithAll
        },
        reportDialogRef:function(){
            return this.$store.state.commonRefs.reportDialog;
        },
        useAttendance(){
            let v = process.env.VUE_APP_USE_ATTENDANCE;
            if (v === null || v === undefined){
                return true;
            }
            else return !!Number(v)
        },
        useReportComment(){
          let v = process.env.VUE_APP_USE_REP_COMMENT;
          if (v === null || v === undefined){
            return true;
          }
          else return !!Number(v)
        },
        // 日報転送機能の有効状態
        useReportForward(){
          let v = process.env.VUE_APP_USE_REP_FORWARD;
          if (v === null || v === undefined){
            return true;
          }
          else return !!Number(v)
        },
        isMoriyasu(){
          let v = process.env.VUE_APP_ENV_NAME;
          return (v === "prod") || (v === "dev");
        },

    },
    methods: {


        findGroup(groupCd){
          return this.groups.find(function(element) {
              return element.group_cd === groupCd;
          });
        },

        getGroupName(groupCd){
          let g = this.findGroup(groupCd);
          if (g){
              return g.group_name;
          } else {
              return "";
          }
        },

        findUser(staffCd){
            return this.users.find(function(element) {
                return element.staff_cd === staffCd;
            });
        },

        getUserName(staffCd){
            let staff = this.findUser(staffCd);
            if (staff){
                return staff.name;
            } else {
                return "";
            }
        },

        getEmoteName(emoteCd){

            let em = this.emotes.find(function(element){
                return element.emote_cd == emoteCd
            });

            if (em){
                return em.name;
            }

            return "????";
        },

        // -------------------------------------------
        // 日付のパース
        // -------------------------------------------
        parseDateTime(dateTimeText) {
            return moment(dateTimeText)
        },

        // -------------------------------------------
        // 各種フォーマット(その他)
        // -------------------------------------------


        formatDateToComponentValue(dt){
            return moment(dt).format("YYYY-MM-DD")
        },


        formatDateTimeToDisplay(dt){
            if (dt){
                return moment(dt).format("YYYY-MM-DD HH:mm");
            } else {
                return "";
            }
        },

        // タイムラインのヘッダに表示する日付フォーマット
        formatDateToHeaderDisplay(dt){
            if (dt){
                return moment(dt).format("YYYY年MM月DD日(ddd)");
            } else {
                return "";
            }
        },

        // タイムラインのタイトルに表示する日付フォーマット
        formatDateToHeaderTitleDisplay(dt){
            if (dt){
                return moment(dt).format("M月D日(ddd)");
            } else {
                return "";
            }
        },

        formatDateToDisplay(dt){
            if (dt){
                return moment(dt).format("YYYY-MM-DD");
            } else {
                return "";
            }
        },

        formatDateToDisplayMD(dt){
            if (dt){
                return moment(dt).format("M月D日");
            } else {
                return "";
            }
        },

        formatDate(dt,format){
            if (dt){
                return moment(dt).format(format);
            } else {
                return "";
            }
        },

        formatDateTimeToJSON(dt){
            if (dt){
                return moment(dt).format()
            } else {
                return "";
            }
        },


        // ----------------------------------
        // トーストメッセージの表示
        // ----------------------------------
        showErrorToast(message){
            this.$toast.error(message);
        },
        showMessageToast(message){
            this.$toast.info(message);
        },

        // ----------------------------------
        // URLユーティリティ
        // ----------------------------------

        // パラメータ付きURLを生成する
        // path
        // paramMap パラメータのマップ ( name:value の形式)
        buildUrl(path,paramMap){
            return Util.buildParamUrl(path,paramMap);
        },



        // ----------------------------------
        // その他
        // ----------------------------------

        setAlreadyRead(reportId,waitTime){

            let self = this;

            if (reportId){
                TimelineAPI.putAlreadyRead(reportId);
                this.$eventHub.$emit('report_read',{reportId:reportId});

                if (waitTime){
                    setTimeout(function() {
                        self.$store.commit('setReportRead', {
                            reportId:reportId
                        });
                    }, waitTime);
                } else {
                    self.$store.commit('setReportRead', {
                        reportId:reportId
                    });
                }

            }

        },

        cancelAlreadyRead(reportId){
            let self = this;

            if (reportId){
                TimelineAPI.deleteAlreadyRead(reportId);
                this.$eventHub.$emit('report_unread',{reportId:reportId});

                self.$store.commit('delReportRead', {
                    reportId:reportId
                });
            }

        },


    }
  }
</script>