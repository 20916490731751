import axios from 'axios';
import Util from '../Util'

class DailyViewAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    getData(dt){

        let path = "/api/daily/" + dt;

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

}

let _api = new DailyViewAPI();

export default _api;