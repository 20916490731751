import axios from 'axios';
import Util from '../Util'

class TagAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    listTags(name){

        let path = "/api/tags";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        if (name){
            endpoint = Util.buildParamUrl(endpoint,{name:name});
        }

        return axios.get(endpoint).then(response =>  response.data)

    }


}

let _api = new TagAPI();

export default _api;