<template>
  <v-avatar :color="avatarColor" :size="size">
    <v-img
      v-if="!!imageSrc"
      v-on:load="onImageLoad"
      v-on:error="onImageError"
      :src="imageSrc"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <span
      v-if="!imageSrc"
      :style="'color:' + fontColor + ';font-size:' + fontSize + 'px;'"
      >{{ nameInitial }}</span
    >
  </v-avatar>
</template>
<script>
import UserAPI from "@/apis/UserAPI";
export default {
  mounted() {
    this.startImageLoading();
  },

  props: {
    staffCd: "",
    color: "",
    initial: "",
    size: {
      type: Number,
      default: 62,
    },
  },

  watch: {
    staffCd: function (val) {
      this.startImageLoading();
    },
  },

  data() {
    return {
      // 画像をロードする時に設定されるフラグ(ajaxも含む)
      loading: true,

      imageSrc: "",
      nameInitial: "",
      avatarColor: "",
      fontColor: "",
    };
  },

  computed: {
    fontSize: function () {
      if (this.size >= 62) {
        return 28;
      }
      return 14;
    },
  },

  methods: {
    startImageLoading() {
      let self = this;

      if (!this.staffCd) {
        self.loading = false;
        self.imageSrc = "";
        return;
      }

      this.getProfile().then((data) => {
        let url = data.avatarUrl;

        self.nameInitial = data.nameInitial;
        if (data.avatarColor) {
          self.avatarColor = data.avatarColor;
        } else {
          self.avatarColor = "#E0E0E0";
        }

        self.fontColor = self.selectFontColorByBkColor(self.avatarColor);

        //console.log("nameInitial " + self.nameInitial);
        //console.log("fontColor " + self.fontColor);
        //console.log("avatarColor " + self.avatarColor);

        if (self.imageSrc === url) {
          return;
        }

        self.loading = true;
        self.imageSrc = url;
      });
    },

    getProfile() {
      return UserAPI.profileByStaffCd(this.staffCd).then((a) => {
        let data = a.data;
        //console.log(a);
        if (data) {
          //console.log("getProfile data fined.");
          let ret = {
            avatarColor: data.user.avatar_color,
            avatarUrl: data.avatarUrl,
            nameInitial: data.nameInitial,
          };
          return ret;
        } else {
          return {
            avatarColor: "",
            avatarUrl: "",
            nameInitial: "",
          };
        }
      });
    },

    onImageLoad(event) {
      this.loading = false;
    },

    onImageError(event) {
      console.log("image loading failed.");
      this.loading = false;
    },

    selectFontColorByBkColor(hexcolor) {
      let r = parseInt(hexcolor.substr(1, 2), 16);
      let g = parseInt(hexcolor.substr(3, 2), 16);
      let b = parseInt(hexcolor.substr(5, 2), 16);

      let z = 168; //128;

      return (r * 299 + g * 587 + b * 114) / 1000 < z ? "white" : "black";
    },
  },
};
</script>
