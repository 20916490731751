import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import vuetify from './plugins/vuetify';
import VuetifyToast from 'vuetify-toast-snackbar'
import { DateTime,Interval } from "luxon";

//import NProgress from "nprogress"
//import "nprogress/nprogress.css"

Vue.config.productionTip = false;

// --------------------------------------------
// Amplifyの設定
// --------------------------------------------

// フルインストール
////import Amplify, * as AmplifyModules  from 'aws-amplify'
//import Amplify  from 'aws-amplify'

// 個別インストール
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

import awsconfig from './aws-exports';
//Amplify.configure(awsconfig);


let amplifyAuth = {
  // フェデレーションアイデンティティのID
  identityPoolId: 'ap-northeast-1:db9eaea4-4000-4d7d-8971-ff2528ac6d97',
  // リージョン
  region: 'ap-northeast-1',
  // ユーザープールのID
  userPoolId: 'ap-northeast-1_hQr8tODU0',
  // ユーザープールのウェブクライアントID
  userPoolWebClientId: '3adpi9ivkbjh5f82cqnssd3roc',
  mandatorySignIn: true
};
if (!!process.env.VUE_APP_ID_POOL_ID){
  amplifyAuth.identityPoolId = process.env.VUE_APP_ID_POOL_ID;
  console.log("amplifyAuth.identityPoolId=" + amplifyAuth.identityPoolId);
}
if (!!process.env.VUE_APP_USER_POOL_ID){
  amplifyAuth.userPoolId = process.env.VUE_APP_USER_POOL_ID;
  console.log("amplifyAuth.userPoolId=" + amplifyAuth.userPoolId);
}
if (!!process.env.VUE_APP_WEB_CLIENT_ID){
  amplifyAuth.userPoolWebClientId = process.env.VUE_APP_WEB_CLIENT_ID;
  console.log("amplifyAuth.userPoolWebClientId=" + amplifyAuth.userPoolWebClientId);
}

Amplify.configure({
  Auth: amplifyAuth
});


//Vue.use(AmplifyPlugin, AmplifyModules);

// --------------------------------------------
// 共通ユーティリティの読み込み
// --------------------------------------------
import Util from './Util';

if (!window.app){
  window.app = {};
}
window.app.util = Util;

// --------------------------------------------
// 設定ファイルの読み込み(env)
// --------------------------------------------
window.app.env = process.env;

// --------------------------------------------
// GoogleAnalyticsの設定
// --------------------------------------------
//console.log("VUE_APP_TRACKING_ID=" + process.env.VUE_APP_TRACKING_ID);
//gtag('config', process.env.VUE_APP_TRACKING_ID);

// --------------------------------------------
// EventHubの設定
// --------------------------------------------
import eventHub from './plugins/EventHub';
Vue.use(eventHub);

// --------------------------------------------
// axios
// --------------------------------------------
Vue.prototype.$http = axios;
// IE11のキャッシュ対応
axios.interceptors.request.use(function (config) {
  if (typeof config.params === 'undefined') {
    config.params = {};
  }
  if (typeof config.params === 'object') {
    if (typeof URLSearchParams === 'function' && config.params instanceof URLSearchParams)
      config.params.append('_', Date.now());
    else
      config.params._ = Date.now();
  }

  return config;
});

// --------------------------------------------
// Vuetify Toast Snackbar
// https://github.com/eolant/vuetify-toast-snackbar
// --------------------------------------------
Vue.use(VuetifyToast, {
  x: 'center',
  y: 'bottom',
  color: 'info', // default
  icon: 'info',
  iconColor: '', // default
  classes: [
    'body-2'
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: false, // default
  closeText: '', // default
  closeColor: '', // default
  shorts: {
    custom: {
      color: 'purple'
    }
  },
  property: '$toast' // default
});



// --------------------------------------------
// 画面遷移のガード処理
// --------------------------------------------
router.beforeEach((to, from, next) => {
  //NProgress.start();

  // ログインしなくても確認できる画面であるか判定
  let isPublic = to.matched.some(record => record.meta.isPublic);

  let loggedIn = store.getters.isAuthenticated;

  let path = to.path;

  // ログインされているのにlogin画面を表示したら(ブックマークされてたり)
  // home画面へ遷移させる
  if (path === "/login" && loggedIn){
    next({ path: '/'});
  }
  else if (!isPublic && !loggedIn){


    let redirectPath = "";
    if (!!path && (path !== "/") && (path !== "/login")){
      redirectPath = window.app.util.combineUrl(window.app.location.baseurl,to.fullPath);
    }

    console.log("redirectPath to.path = " + path);

    next({ path: '/login', query: { backuri: redirectPath }});
  }
  else {
    next();
  }

});

let appTitle = process.env.VUE_APP_TITLE;

// --------------------------------------------
// 画面遷移後の処理
// --------------------------------------------
router.afterEach((to, from) => {

  //NProgress.done();

  // metaでタイトルを更新
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " - " + appTitle
  }

});


// --------------------------------------------
// Scrollディレクティブの定義
// --------------------------------------------
Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    };
    window.addEventListener('scroll', f)
  }
});


// --------------------------------------------
// 共通コンポーネントの読み込み
// --------------------------------------------

// DatePicker(IE11対応)
//import Datepicker from 'vuejs-datepicker';
//Vue.component("vuejs-datepicker",Datepicker);
//import Datepicker from 'vue-bulma-datepicker'

import IconBase from "./components/controls/IconBase";
Vue.component("icon-base",IconBase);
import EmoteIconBase from "./components/controls/EmoteIconBase";
Vue.component("emote-icon-base",EmoteIconBase);

import LoadingView from "./components/controls/LoadingView";
Vue.component("loading-view",LoadingView);

// 共通CSSの読み込み
import "./dazaifu.scss"
import "./dazaifu_print.scss"


let appStarted = false;

let startApp = function () {

  appStarted = true;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
};



// --------------------------------------------
// クライアントリビジョン管理
// --------------------------------------------
let checkRevision = function () {

  let dt = DateTime.now();
  let needCheck = false;
  if (!window.app.lastRevisionCheckTime) {
    needCheck = true;
  }
  else {    
    let interval = Interval.fromDateTimes(window.app.lastRevisionCheckTime,dt);
    let pastmin = interval.length("minutes");
    //console.log("前回のチェックから" + pastmin + "分経過しています。");
    if (pastmin > 60) needCheck = true;
  }
  if (!needCheck) return Promise.resolve();


  let curRev = localStorage.getItem('REVISION');
  if (curRev) {
    console.log("現在のクライアントバージョン:", curRev);
  }
  else { 
    console.log("現在のクライアントバージョン:未設定");
  }
  
  // 最終のチェック時間を保持しておく
  window.app.lastRevisionCheckTime = dt;

  
  return new Promise((resolve, reject) => {
  
    let hash = new Date().getTime();

    axios.get('/revision.json?' + hash).then(response => {
      console.log("client revision", response)
      let nextRev = response.data.revision;
      if (nextRev) {
        
        if (!curRev || curRev < nextRev) {
          
          console.log("新バージョンが確認されたため念の為リロードします。",nextRev);
          localStorage.setItem('REVISION', nextRev);
            
          // リロード処理
          location.reload(true);
          //window.setTimeout(function(){
          //  location.reload(true);
          //}, 5000);          
        }
      }
      resolve("ok");
    })
      .catch(error => {
        console.log(error)
        reject(error)
      });
  });
    
};
window.app.checkRevision = checkRevision;
window.app.lastRevisionCheckTime = 0;



// --------------------------------------------
// アプリ起動フロー
// --------------------------------------------
import Authentication from './authentication'

Authentication.checkLogin()
    .then(data => {

        store.commit('setApiAuthorization', data);

        startApp();

    })
    .catch(err => {
        startApp();
    });

// 設定ファイルの選択をログ
console.log("ENV_NAME=" + process.env.VUE_APP_ENV_NAME);