<template>
    <v-container class="system_maintenance" wrap>

        <div><span class="title">グループ間参照設定</span></div>

        <v-container>
            <v-row>
                <v-col>
                   【 {{selectGroupName}}】から追加で参照できるグループを選択してください。<br>
                    （下位グループ、直上のグループは標準で参照可能です）
                </v-col>
            </v-row>


            <v-container v-if="loading" fluid fill-height>
                <v-row align="center" justify="center">
                    <v-col cols="2">
                        <v-progress-circular
                                v-if="loading"
                                :size="50"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>


            <v-row v-if="!loading && groupList.length === 0">
                <v-col>
                    追加で選択可能なグループは存在しません
                </v-col>
            </v-row>

            <v-simple-table v-if="!loading && groupList.length > 0">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Code</th>
                        <th class="text-left">Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="group in groupList" :key="group.group_cd">
                        <td>
                            <v-checkbox v-model="viewableGroups" hide-details :value="group.group_cd" class="mt-0 mb-1"></v-checkbox>
                        </td>
                        <td>{{ group.name }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>


            <v-row>
                <v-col>
                    <v-btn  v-if="groupList.length > 0" v-on:click="onSave" :loading="updating">保存</v-btn>
                    <v-btn v-on:click="onCancel">キャンセル</v-btn>
                </v-col>
            </v-row>

        </v-container>



    </v-container>
</template>
<script>
import Common from '@/components/Common';
import GroupMaintenanceAPI from '@/apis/GroupMaintenanceAPI';

export default {
    mixins:[Common],
    mounted: function() {
        this.setupConditions();

        this.selectGroupName = this.getGroupName(this.groupCd);
    },
    data: () => ({

        groupList:[],

        selectGroupName:"",

        viewableGroups:[],

        loading: false,
        updating:false,
    }),
    props: {
        groupCd:{
            type: String ,
            required: true
        },


    },

    methods: {

        setupConditions(){

            this.fetchData(true);
        },

        fetchData(needDataFetch){
            let self = this;

            if (!this.isInitialized){
                this.fetchBasicData(function () {
                    self.fetchData(needDataFetch)
                });
                return;
            }

            if (needDataFetch){


                self.loading = true;

                Promise.resolve()
                    .then(a => { return this.checkAuthToken() })
                    .then(a => { return GroupMaintenanceAPI.listUnviewableGroups(this.groupCd) })
                    .then(response => {
                        self.makeDisplayData(response.data);
                        self.loading = false;
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.loading = false;
                        self.showErrorToast("データの取得に失敗しました。");
                    });

            }

        },

        fetchBasicData(finishProc){
            let self = this;
            self.isInitialized = true;
            if (finishProc){
                finishProc();
            }
        },


        makeDisplayData(data){

            let self = this;

            console.log(data);

            let groups = data;

            this.viewableGroups.splice(0);
            this.groupList.splice(0);

            groups.forEach(rec => {
                this.groupList.push(rec);

                if (rec.viewable){
                    this.viewableGroups.push(rec.group_cd);
                }

            });


        },


        onCancel(){
            let url = this.buildUrl("/admin/groups");
            this.$router.push(url)
        },

        onSave(){
            let self = this;
            let updateData = {
                groups:this.viewableGroups
            };

            this.updating = true;

            Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return GroupMaintenanceAPI.updateGroupViewability(this.groupCd,updateData) })
                .then(response => {
                    self.showMessageToast("データを更新しました。");
                    self.updating = false;

                    let url = this.buildUrl("/admin/groups");
                    self.$router.push(url)
                })
                .catch(function(err) {
                    console.log(err);
                    self.updating = false;
                    self.showErrorToast("データの更新に失敗しました。");
                });

        },
    },

}
</script>