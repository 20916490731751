import axios from 'axios';
import Util from '../Util'

class NotificationAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    
    list(){

        let path = "/api/notifications";

        let endpoint =  Util.combineUrl(this.apiHost ,path);

        return axios.get(endpoint).then(response =>  response.data)
    }


    putAlreadyRead(notificationId){

        let path = "/api/notifications/" + notificationId + "/read";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {}).then(response =>  response.data)
    }


}

let _api = new NotificationAPI();

export default _api;