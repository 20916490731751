<template>
  <v-container class="timeline"> <!-- <v-fade-transition mode="out-in"> -->

    <div><span class="title">ユーザリスト</span></div>

    <!-- 編集ダイアログ -->
    <v-dialog v-model="editDialog" max-width="500px" :persistent="true">
      <v-card>
        <v-card-title>
          <span class="headline">ユーザ登録</span>
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="4" class="pr-3">
                  <!-- 社員番号 -->
                  <v-text-field
                          v-model="editData.staffCd"
                          :rules="staffCdRules"
                          :counter="StaffCdLength"
                          label="社員番号"
                          required
                  ></v-text-field>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                          v-model="editData.cognitoUserId"
                          :rules="userIdRules"
                          :counter="30"
                          label="ユーザID"
                          required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col class="mr-2">
                  <v-text-field
                          v-model="editData.familyName"
                          :rules="userFamilyNameRules"
                          :counter="10"
                          label="姓"
                          required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                          v-model="editData.givenName"
                          :rules="userGivenNameRules"
                          :counter="10"
                          label="名"
                          required
                  ></v-text-field>
                </v-col>
              </v-row>






              <v-text-field
                      v-model="editData.name"
                      :rules="userNameRules"
                      :counter="20"
                      label="表示名"
                      required
              ></v-text-field>

              <div v-for="(grp,index) in editData.groupCdList">
                <v-select
                        :items="groupsWithBlank"
                        :label="index === 0 ? '主グループ' : 'グループ' + (index + 1)"
                        item-text="group_name"
                        item-value="group_cd"
                        v-model="editData.groupCdList[index]"
                ></v-select>
              </div>


              <v-row dense no-gutters>
                <v-col class="ml-2">
                  <v-switch v-model="editData.passwordChange" label="パスワード変更"></v-switch>
                </v-col>
              </v-row>

              <v-row v-if="editData.passwordChange" dense no-gutters>
                <v-col>
                  <v-container>
                    <v-row dense no-gutters>
                      <v-col cols="12" class="mb-2">
                        <v-text-field v-model="editData.newPassword1" label="新しいパスワード" required type="password" :rules="rules.newPassword"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="editData.newPassword2" label="新しいパスワード(確認)" required type="password" :rules="rules.password2"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col></v-col>
              </v-row>


            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>

          <!--追加ボタン-->
          <v-btn v-if="editData.isNewEntry"
                  :disabled="!valid"
                  @click="createUser"
                  color="primary">追加</v-btn>

          <!--更新ボタン-->
          <v-btn v-if="!editData.isNewEntry"
                  :disabled="!valid"
                  @click="updateUser"
                  color="primary">更新</v-btn>

          <v-btn @click="editDialog=false">キャンセル</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <!-- 削除ダイアログ -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-container>

          <v-card-title>
            <span class="headline">削除確認</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  このユーザを削除しますか？
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  社員番号:{{deleteData.staffCd}}
                </v-col>
                <v-col cols="6">
                  ユーザID:{{deleteData.cognitoUserId}}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="blue darken-1" text @click="deleteDialog=false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="disableUser()">OK</v-btn>
          </v-card-actions>

        </v-container>
      </v-card>
    </v-dialog>



    <v-container wrap> <!-- text-center -->

      <v-data-table
              :headers="headers"
              :items="userList"
              :items-per-page="10"
              :loading="loading"
              item-key="cognitoUserId"
              no-data-text="ユーザが登録されておりません。"
              loading-text="Loading... "
              class="elevation-1"
              :search="search"
      >


        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title></v-toolbar-title>
            <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="showEditDialogNew">新規追加</v-btn>
          </v-toolbar>
        </template>

        <!-- テーブルボディ -->
        <template slot="item" slot-scope="props">
          <tr>
            <td class="text-xs-center">
              <span v-if="props.item.status==9">無効</span>
              <span v-else-if="props.item.status==1 && !props.item.name">未登録</span>
              <span v-else>有効</span>
            </td>

            <td class="text-xs-center">{{ props.item.cognitoUserId }}</td>
            <td class="text-xs-center">{{ props.item.staffCd }}</td>
            <td class="text-xs-center">{{ props.item.name }}</td>
            <td class="text-xs-center">{{ props.item.groupCd }}:{{ props.item.groupName }}</td>
            <td class="justify-center layout px-0">
              <!-- 編集ボタン -->
              <v-icon class="mr-2" v-on:click.prevent.stop="showEditDialogUpdate(props.item)">
                edit
              </v-icon>
              <!-- 削除ボタン -->
              <v-icon v-on:click.prevent="showDeleteDialog(props.item)">
                delete
              </v-icon>
            </td>
          </tr>
        </template>

        <!-- 検索結果なし -->
        <v-alert slot="no-results" :value="true" color="yellow lighten-4" icon="warning">
          "{{ search }}" の検索結果なし
        </v-alert>
        <!-- データなしの時の表示 -->
        <template slot="no-data">
          No Data
        </template>

        <!-- フッターの件数表示 -->
        <template slot="pageText" slot-scope="props">
          {{ props.itemsLength }} 件中 {{ props.pageStart }} 件目 〜 {{ props.pageStop }} 件目
        </template>

      </v-data-table>

    </v-container>

  </v-container>

</template>

<script>
  import Common from '@/components/Common';
  import UserAPI from '@/apis/UserAPI';
  import PasswordValidator from 'password-validator';
  import EnvSupport from "../components/EnvSupport";

  export default {
    mixins:[Common,EnvSupport],
    components : {
    },
    mounted: function() {
      this.setupConditions();

      this.passwordSchema = new PasswordValidator();
      this.passwordSchema
              .is().min(6)
              .has().uppercase()
              .has().lowercase()
              .has().digits()
              .has().not().spaces();

      // 社員コード桁数によるValidationルールの変更
      let scl = this.StaffCdLength;
      let errorMessage = '社員番号は' + scl + '文字以内で入力してください!';
      let rule = function (v) {
        return (v && v.length <= scl) || errorMessage
      };
      this.staffCdRules = [
        v => !!v || '社員番号は必須項目です',
        rule
      ];


    },
    props: [],

    watch: {

      '$route':function (to, from) {
        this.setupConditions();
      },

      'editData.groupCdList':function(to, from) {

        // 途中の空欄を削除する
        while(true){
          let findBlank = false;
          for (let i = 0; i < this.editData.groupCdList.length -1; i++) {
            if (!this.editData.groupCdList[i]){
              this.editData.groupCdList.splice(i,1);
              findBlank = true;
              break;
            }
          }
          if (!findBlank) break;
        }

        // 常に空の要素を最後に配置する
        if (this.editData.groupCdList[this.editData.groupCdList.length - 1]){
          this.editData.groupCdList.push("");
        }

      },
    },
    data() {
      return {
        search:"",
        userList:[
          {
            cognitoUserId:"",
            staffCd:"",
            name:"",
            givenName:"",
            familyName:"",
            groupCd:"",
            groupName:"",
            status:0,
          },
        ],
        headers: [
          { text: '状態', align: 'center', value: 'status' , sortable: false },
          { text: 'ID', align: 'center', value: 'cognitoUserId' },
          { text: '社員CD', align: 'center', value: 'staffCd' },
          { text: '表示名', align: 'center', value: 'name' },
          { text: 'グループ', align: 'center', value: 'groupCd' },
          { text: '操作', align: 'center', value: 'action', sortable: false }
        ],
        pagination: {
          descending: true,
          rowsPerPage: 10
        },

        // 編集ダイアログの表示フラグ
        editDialog:false,
        editIndex:-1,
        // 編集用データ
        editData:{
          isNewEntry:false,
          cognitoUserId:"",
          staffCd:"",
          name:"",
          givenName:"",
          familyName:"",
          groupCd:"",

          groupCdList:[""],

          passwordChange:false,
          newPassword1:"",
          newPassword2:"",
        },

        // バリデーション
        valid: true,
        staffCdRules: [
          v => !!v || '社員番号は必須項目です',
          v => (v && v.length <= 3) || '社員番号は3文字以内で入力してください'
        ],
        userIdRules: [
          v => !!v || 'ユーザIDは必須項目です',
          v => (v && v.length <= 30) || 'ユーザIDは30文字以内で入力してください'
        ],
        userNameRules:[
          v => !!v || '表示名は必須項目です',
          v => (v && v.length <= 20) || '表示名は20文字以内で入力してください'
        ],
        userFamilyNameRules:[
          v => !!v || '姓は必須項目です',
          v => (v && v.length <= 10) || '姓は10文字以内で入力してください'
        ],
        userGivenNameRules:[
          v => !!v || '名は必須項目です',
          v => (v && v.length <= 10) || '名は10文字以内で入力してください'
        ],


        rules: {
          newPassword:[
            value => !!value || '必須入力です',
            v => v.length >= 6 || '6文字以上のパスワードを設定してください',
            v =>  this.passwordSchema.validate(v) || '大文字、小文字、数字で構成されるパスワードを設定してください',
          ],
          password2:[
            value => value === this.editData.newPassword1 || 'パスワードが異なります',
          ],
        },

        passwordSchema:null,

        // 削除確認ダイアログの表示フラグ
        deleteDialog: false,
        deleteData:{
          cognitoUserId:"",
          staffCd:"",
          name:"",
          givenName:"",
          familyName:"",
        },



        // ローディングの表示フラグ
        loading: false,
      }
    },
    methods: {

      setupConditions(){

        this.fetchData(true);

      },

      fetchData(needDataFetch){
        let self = this;

        if (!this.isInitialized){
          this.fetchBasicData(function () {
            self.fetchData(needDataFetch)
          });
          return;
        }

        if (needDataFetch){

          this.loading = true;

          this.checkAuthToken().then(a => {
            return UserAPI.list().then(response => {
              self.makeDisplayData(response.data);
              self.loading = false;
            })
          })
          .catch(function(err) {
            console.log(err);
            self.loading = false;
            self.showErrorToast("データの取得に失敗しました。");
          });

        }

      },

      fetchBasicData(finishProc){

        let self = this;

        self.isInitialized = true;
        if (finishProc){
          finishProc();
        }

      },


      makeDisplayData(data){

        //console.log(data);

        this.userList.splice(0);

        let sourceList = data;

        sourceList.forEach( rec => {

          let user = rec.user;
          let groups = rec.groups;

          this.userList.push({
            staffCd:user.staff_cd,
            cognitoUserId:user.user_id,
            name:user.name,
            givenName:user.given_name,
            familyName:user.family_name,
            groupCd:user.group_cd,
            groupName:this.getGroupName(user.group_cd),
            status:user.status,
            groups:groups,
          })
        })

      },


      showDeleteDialog (item) {
        //const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?')

        this.deleteDialog = true;
        this.deleteData.cognitoUserId = item.cognitoUserId;
        this.deleteData.staffCd = item.staffCd;
        this.deleteData.userName = item.userName;
        this.deleteDialog.name = item.name;
        this.deleteData.givenName= item.givenName;
        this.deleteData.familyName= item.familyName;
      },

      showEditDialogNew(){
        this.editData.isNewEntry = true;
        this.editData.cognitoUserId = "";
        this.editData.staffCd = "";
        this.editData.userName = "";
        this.editData.givenName= "";
        this.editData.familyName= "";
        this.editData.name = "";
        this.editData.groupCd = "";
        this.editData.groupName = "";
        this.editData.groupCdList = [""];

        this.editData.passwordChange = false;
        this.editData.newPassword1 = "";
        this.editData.newPassword2 = "";

        this.editDialog = true;
      },
      showEditDialogUpdate(item){
        this.editIndex = this.userList.indexOf(item);
        if (this.editIndex > -1){
          //this.editData = Object.assign({}, item);
          this.editData.isNewEntry = false;

          this.editData.cognitoUserId = item.cognitoUserId;
          this.editData.staffCd = item.staffCd;
          this.editData.userName = item.staffCd;
          this.editData.givenName= item.givenName;
          this.editData.familyName= item.familyName;
          this.editData.name = item.name;
          this.editData.groupCd = item.groupCd;
          this.editData.groupName = item.groupName;

          this.editData.groupCdList = item.groups.map(function(v){
            return v.group_cd
          });
          this.editData.groupCdList.push("");

          console.log(this.editData.groupCdList );

          this.editData.passwordChange = false;
          this.editData.newPassword1 = "";
          this.editData.newPassword2 = "";

          this.editDialog = true;
        }
      },

      createUser(){
        let self = this;
        this.editDialog = false;

        let updateData = {
          staff_cd:this.editData.staffCd,
          cognito_user_name:this.editData.cognitoUserId,
          name:this.editData.name,
          given_name:this.editData.givenName,
          family_name:this.editData.familyName,
          group_cds:this.editData.groupCdList,
          passwordChange:this.editData.passwordChange,
          newPassword:this.editData.newPassword1,
        };

        this.checkAuthToken().then(a => {
          return UserAPI.createUser(updateData).then(response => {


          })
        }).catch(function(err) {
          console.log(err);
          self.showErrorToast("データの更新に失敗しました。");
        });

      },

      updateUser(){
        let self = this;

        let updateData = {
          staff_cd:this.editData.staffCd,
          cognito_user_name:this.editData.cognitoUserId,
          name:this.editData.name,
          given_name:this.editData.givenName,
          family_name:this.editData.familyName,
          group_cds:this.editData.groupCdList,
          passwordChange:this.editData.passwordChange,
          newPassword:this.editData.newPassword1,
        };

        this.checkAuthToken().then(a => {
          return UserAPI.updateUser(updateData).then(response => {

            self.showMessageToast("ユーザを更新しました。");

            console.log(response.data);

            self.applyUpdateDataToList(response.data);

            self.editDialog = false;
          })
        }).catch(function(err) {
          console.log(err);
          self.showErrorToast("データの更新に失敗しました。");
        });
      },

      disableUser(){
        let self = this;

        let userId = this.deleteData.cognitoUserId;
        if (!userId){
          return;
        }

        Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return UserAPI.disableUser(userId)})
                .then(response => {
                  self.showMessageToast("ユーザを削除しました。");
                  self.deleteDialog = false;

                  self.fetchData(true);

                })
                .catch(function(err) {
                  console.log(err);
                  self.showErrorToast("データの登録に失敗しました。");
                });

      },


      applyUpdateDataToList(data){

        let user = data.user;
        let groups = data.groups;

        let dispRec = {
          staffCd:user.staff_cd,
          cognitoUserId:user.user_id,
          name:user.name,
          givenName:user.given_name,
          familyName:user.family_name,
          groupCd:user.group_cd,
          groupName:this.getGroupName(user.group_cd),
          status:user.status,
          groups:groups,
        };

        if (this.editIndex > -1) {
          Object.assign(this.userList[this.editIndex],dispRec)
        } else {
          this.userList.push(dispRec)
        }
      },


    }
  }
</script>
<style lang="scss">

</style>