<template>
  <v-container class="thanks-view">

    <div><span class="title">Thanks! カード</span></div>

    <v-tabs v-model="selected_tab">
      <v-tab>みんなのありがとう</v-tab>
      <v-tab>わたしのありがとう</v-tab>
    </v-tabs>


    <div v-show="selected_tab == 0">
      <v-row >
        <v-col v-for="rec in everyoneRecords" :key="rec.id" cols="4" xs="6" sm="6" md="3" lg="3" xl="3">
          <v-card
              color="#26C6DA"
              dark
          >
            <v-card-text>
              <div>
                <v-chip v-if="rec.is_unread" class="mr-2" x-small color="#E57373" text-color="white">NEW</v-chip>
                <span class="caption"> {{ formatDateToDisplay(rec.create_at) }} </span>
                <span class="caption"> {{ rec.user_name }}</span>
              </div>

              <div class="mt-1 font-weight-bold">
                {{ rec.content }}
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions>
            </v-card-actions>
          </v-card>
          <!-- 既読処理をキックするオブザーバ -->
          <IntersectionObserver v-if="rec.is_unread && !rec.read_event_fired" @intersect="onEntryIntersected(rec)"/>

        </v-col>

        <v-col cols="4" xs="6" sm="6" md="3" lg="3" xl="3">
          <!-- 次を読み込むオブザーバ  -->
          <IntersectionObserver v-if="nextFetchDateTime" @intersect="fetchNext"/>
        </v-col>

      </v-row>

      <loading-view :loading="loading"/>



    </div>
    <div v-show="selected_tab == 1">
      <v-row >
        <v-col v-for="rec in myRecords" :key="rec.id" cols="4" xs="6" sm="6" md="3" lg="3" xl="3">
          <v-card
              color="#26C6DA"
              dark
          >
            <v-card-text>
              <div>
                <span class="caption"> {{ formatDateToDisplay(rec.create_at) }} </span>
              </div>
              <div class="mt-1 font-weight-bold">
                {{ rec.content }}
              </div>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <a href="#" v-on:click.prevent="onEditClick(rec)"><i class="fas fa-edit"></i></a>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <loading-view :loading="loading"/>

      <v-divider></v-divider>

      <v-btn  @click="showEditDialogNew" color="primary" class="mt-3">
        新規登録
      </v-btn>

    </div>




    <v-dialog v-model="editDlgDisplay" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">ありがとうを伝えよう</span>
        </v-card-title>
        <v-card-text>
          <v-container class="pt-3">
            <v-form ref="formEdit" v-model="editData.isValid">
              <v-textarea
                  name="thanks-content"
                  label=""
                  hint=""
                  v-model="editData.content"
                  :counter="500"
                  maxlength="500"
                  :rules="validateRules.contentRules"
                  required
              ></v-textarea>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn v-show="!!this.canDelete" color="secondary" text v-on:click.prevent="onEditDlgDeleteBtnClick" >削除</v-btn>
          <div class="flex-grow-1"></div>
          <v-btn color="primary"  @click="onSaveClick(editData.id)">保存</v-btn>
          <v-btn color="grey" text @click="editDlgDisplay = false">キャンセル</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="deleteConfirmDialog"></ConfirmDialog>

  </v-container>
</template>

<script>
import moment from 'moment';
import Common from '@/components/Common';
import ThanksCardAPI from "@/apis/ThanksCardAPI";
import DateInputField from "../components/controls/DateInputField";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import IntersectionObserver from '@/components/IntersectionObserver';

export default {
  mixins:[Common],
  components : {
    DateInputField,ConfirmDialog,IntersectionObserver
  },
  mounted:function() {
    this.fetchData();
  },
  destroyed() {

  },
  watch: {

  },
  data() {
    return {
      loading:false,

      selected_tab:0,

      editDlgDisplay:false,

      editData:{
        isValid: true,
        id: null,
        content: "",
      },
      validateRules:{
        contentRules: [
          v => !!v || '内容は必須項目です',
          v => (v && v.length <= 500) || '内容は500文字以内で入力してください'
        ],
      },

      myRecords:[
        {
          id : 0,
          content : "今日もありがとう。",
          create_at : "2021-08-02",
          user_name: "",
          is_unread: 0,
        },
      ],
      everyoneRecords:[
          {
            id : 0,
            content : "今日もありがとう。",
            create_at : "2021-08-02",
            user_name: "",
            is_unread: 0,
          },
      ],

      nextFetchDateTime:null,
    }
  },
  computed: {
    canDelete:function () {
      return !!this.editData.id;
    }
  },
  methods: {

    fetchData(){

      let self = this;

      this.loading = true;

      this.everyoneRecords.splice(0);
      this.myRecords.splice(0);


      let taskMyTh = ThanksCardAPI.listMyThanks().then( res => {
        this.makeData(res.data,this.myRecords);
      });

      let taskEvTh = ThanksCardAPI.listEveryonesThanks().then( res => {
        this.nextFetchDateTime = this.makeData(res.data,this.everyoneRecords);
      });

      Promise.resolve()
          .then(a => { return this.checkAuthToken() })
          .then(a => Promise.all([taskMyTh, taskEvTh]))
          .then(a =>{
            this.loading = false;
          })
          .catch(function(err) {
            self.loading = false;
            console.log(err);
            self.showErrorToast("データの取得に失敗しました。");
          });
    },

    fetchNext(){

      console.log("fetchNext called." + this.nextFetchDateTime)

      let self = this;

      if (this.nextFetchDateTime){

        let taskEvTh = ThanksCardAPI.listEveryonesThanks(this.nextFetchDateTime).then( res => {
          this.nextFetchDateTime = this.makeData(res.data,this.everyoneRecords);
        });

        this.loading = true;

        Promise.resolve()
            .then(a => { return this.checkAuthToken() })
            .then(a => taskEvTh )
            .then(a =>{
              this.loading = false;
            })
            .catch(function(err) {
              self.loading = false;
              console.log(err);
              self.showErrorToast("データの取得に失敗しました。");
            });

        this.nextFetchDateTime = null;
      }
    },

    makeData(responseData,records){

      //console.log(responseData);

      let dt = null;

      responseData.forEach( rec => {
        records.push({
          id: rec.id,
          content: rec.content,
          create_at: rec.create_at,
          post_user_id: rec.post_user_id,
          post_staff_cd: rec.post_staff_cd,
          design_cd: rec.design_cd,
          is_unread: rec.is_unread,
          user_name: this.getUserName(rec.post_staff_cd),
          read_event_fired: false,
        });

        dt = rec.create_at;
      });

      // 最終日時を返す
      return dt;
    },

    onSelectReport(report){
      this.reportDialogRef.open(report.report_id,{});
    },

    showEditDialogNew(){
      this.editData.isValid = true;
      this.editData.id = null;
      this.editData.content = "";
      this.editDlgDisplay = true;
    },

    onEditClick(data){
      this.editData.isValid = true;
      this.editData.id = data.id;
      this.editData.content = data.content;
      this.editDlgDisplay = true;
    },

    onSaveClick(cardId){
      let self = this;

      let data = {
        id : cardId ,
        content : this.editData.content ,
      };
      Promise.resolve()
          .then(a => { return this.checkAuthToken() })
          .then(a => ThanksCardAPI.saveThanksCard(data) )
          .then(response => {
            self.showMessageToast("登録しました。");
            self.editDlgDisplay = false;
            self.fetchData();
          })
          .catch(function(err) {
            self.loading = false;
            console.log(err);
            self.showErrorToast("データの更新に失敗しました。");
          });
    },



    async onEditDlgDeleteBtnClick(){
      let self = this;

      let cardId = this.editData.id;
      if (!cardId) return;

      let dialog = this.$refs.deleteConfirmDialog;

      if (await dialog.open('確認', '削除してもよろしいですか？')) {
        Promise.resolve()
            .then(a => { return this.checkAuthToken() })
            .then(a => {
              return ThanksCardAPI.deleteThanksCard(cardId)
            })
            .then(a => {
              self.editDlgDisplay = false;
              self.showMessageToast("削除しました。");
              self.fetchData();
            })
            .catch(function(err) {
              console.log(err);
              self.showErrorToast("データの削除に失敗しました。");
            });
      }

    },

    onEntryIntersected(cardRec){
      if (!cardRec.read_event_fired){
        cardRec.read_event_fired = true;

        Promise.resolve()
            .then(a => { return this.checkAuthToken() })
            .then(a => { return ThanksCardAPI.readCard(cardRec.id) })
            .catch(function(err) {
              console.log(err);
            });
      }
    },

  //<IntersectionObserver v-if="rec.is_unread && !rec.read_event_fired" @intersect="onEntryIntersected(rec)"/>

  },

}
</script>
<style lang="scss" scoped>
</style>