class Util {

    constructor() {
    }

    combineUrl(parent,child){

        if (!child){
            return parent;
        }
        let rootpath   = (parent.slice(-1) == "/") ? parent : parent + "/";
        let appendpath = (child.slice(0,1) == "/") ? child.slice(1) : child;
        return rootpath + appendpath;
    }

    // パラメータ付きURLを生成する
    // path
    // paramMap パラメータのマップ ( name:value の形式)
    buildParamUrl(path,paramMap){

        let params = [];
        if (paramMap){
            for (let key in paramMap) {
                if (paramMap.hasOwnProperty(key)){
                    let v = paramMap[key];
                    if (v !== null && v !== undefined){
                        params.push("" + key + "=" + encodeURI(v))
                    }
                }
            }
        }


        let result = path;

        for (let i = 0; i < params.length; i++) {

            if (i === 0){
                result += "?" + params[i];
            } else {
                result += "&" + params[i];
            }
        }

        return result;
    }

    checkEnvFlag(v, defaultValue) {
        if (defaultValue === undefined) {
            defaultValue = false;
        }

        if (v === null || v === undefined) {
            return defaultValue;
        } else return !!Number(v);
    }

}

let _util = new Util();

export default _util;