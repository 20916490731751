import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';

// Loaderでのインストール
import Vuetify, { VDialog,VIcon,VSnackbar } from 'vuetify/lib';
Vue.use(Vuetify,{
  components: { VDialog,VIcon,VSnackbar },
});

//import Vuetify from 'vuetify/lib';
//Vue.use(Vuetify);

// フルインストール
//import Vuetify from 'vuetify'
//import 'vuetify/dist/vuetify.min.css'
//Vue.use(Vuetify);

import themes from '../theme'
//console.log(theme);

// テーマの選択
let theme = {};
let themeName = process.env.VUE_APP_THEME_NAME;
if (themeName){
  theme = themes[themeName]
}
if (!theme) theme = {};


export default new Vuetify({
  theme: {
    dark: false, // dark or light はここで指定するようになった
    themes:{
      light:theme,
      dark:theme,
    },

  },
  icons: {
    iconfont: 'mdi', // // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
});
