<template>
    <v-img v-on:load="onImageLoad" v-on:error="onImageError"
           :src="imageSrc" :height="height" :width="width" contain>
        <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center"  justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
    </v-img>
</template>
<script>
import FileUploadAPI from '@/apis/FileUploadAPI';
export default {
    mounted() {
        this.startImageLoading();
    },
    props: [
        'imageId',  // イメージID
        'noImage',  // ソース未設定の時にNoImageを表示するか true/false
        'height',
        'width',
    ],

    watch:{
        'src':function(val){
            this.startImageLoading();
        },
        'imageId':function(val){
            this.startImageLoading();
        },
    },

    data() {
        return {

            // srcを直接imgでロードする時に設定されるフラグ
            isBasicLoading:false,

            // 画像をロードする時に設定されるフラグ(ajaxも含む)
            loading:true,

            // ロード処理中のSRC
            processingSrc:"",

            imageSrc : "",

        }
    },

    computed:{

    },

    methods: {


        startImageLoading(){

            let self = this;

            let urlProc = this.getImageUrl();
            if (urlProc == null){
                return;
            }

            urlProc.then(url => {

                if (!url){
                    self.loading = false;
                    self.isBasicLoading = false;
                    self.processingSrc = null;
                    self.imageSrc = "";
                } else {

                    if (self.processingSrc == url){
                        return;
                    }

                    self.processingSrc = url;
                    self.isBasicLoading = true;
                    self.loading = true;
                    self.imageSrc = url;

                }

            });
        },

        getImageUrl(){
            let self = this;

            if (this.imageId){

                return FileUploadAPI.getThumbnailUrl(this.imageId,this.width,this.height,"c")
                    .then(a => {
                        console.log("getThumbnailUrl " + a);
                        return a
                    });

            }
            else return null;
        },


        onImageLoad(event){
            console.log("image loaded.");
            this.loading = false;
        },

        onImageError(event){
            console.log("image loading failed.");

            if (this.isBasicLoading && !!this.processingSrc){
                // 読み込みエラーになったらAjaxでロードする
                //this.ajaxLoadImage(1000);
            }
        },

    },

}
</script>