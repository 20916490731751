import Vue from 'vue'
import Vuex from 'vuex'
import ThanksCardAPI from "@/apis/ThanksCardAPI";
import Authentication from "@/authentication";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    account:{
      cognito_user_name: "",
      first_name: "",
      last_name: "",
      email: "",
      email_verified: false,
      staff_cd:"",
      attributes: {},
      roles:{},
      idToken:"",
    },
    profile:{
      user:{},
      groupCd:"",
      groupName:"",
      nameInitial:"",
      roles:[],
      settings:{
        use_auto_read:1
      },
    },
    groups:[
      {group_cd:"",group_name:"",group_sname:""}
    ],
    users:[
      {
        user_id : "",
        staff_cd : "",
        name     : "",
        family_name : "",
        given_name : "",
        kana     : "",
        group_cd : "",
        email    : "",
        mobile_tel : "",
        title_cd   : ""
      }
    ],
    emotes:[1,2,3,4,5,6,7],

    commonRefs:{
      commentDialog:null,
      replyDialog:null,
      forwardDialog:null,
      reportDialog:null,
    },

    reports:{
      xxx:{
        id:0,
        entry_type:"A",
        date:"",
        all_day:false,
        time:"",
        title:"",
        content:"",
        user_name:"",
        goodOrBad:"N",
        importance:"0",
        tags:[
          {id:0,name:""},
        ],
        emote:{
          emotes:[],
          emote_count:0,
          popup_data:{},
        },
        comments:[
          {
            user_name:"",
            time:"",
            content:"",
            can_edit:true,
            replies:[
              {
                reply_id:0,
                user_name:"",
                time:"",
                content:"",
                can_edit:true,
              }
            ]
          }
        ],
        files:[],
        forwards:[],
        totalReads:3,
        readsStaffs:[],
        can_edit:false,
        new_arrivals:false,
        can_forward:false,
        is_bookmarked:false,
      },
    },

    // 通知件数
    notification_count:0,

    // 通知データ
    notifications:[
      {
        noticeId:"",
        noticeType:"",
        occurTime:"",
        state:"",
        reportId:"",
        reportTitle:"",
        emoteStaffCd:"",
        emoteStaffName:"",
        emoteCd:"",
        commentStaffCd:"",
        commentStaffName:"",
        replyStaffCd:"",
        replyStaffName:"",
        forwardFromStaffCd:"",
        forwardFromStaffName:"",
        forwardComment:"",
        state2:"",
      }
    ],

    // 未読のサンクスカード件数
    unreadThanksCardCount: 0,

  },
  getters: {
    isAuthenticated: state => {
      return (state.account != null) && !!state.account.cognito_user_name;
    },
    groupsWithAll:state => {
      let empty = {group_cd:"",group_name:"全て",group_sname:""};
      let list = state.groups.slice(0);
      //list.push(empty);
      list.splice(0,0,empty);
      return list;
    },
    groupsWithBlank:state => {
      let empty = {group_cd:"",group_name:"",group_sname:""};
      let list = state.groups.slice(0);
      list.splice(0,0,empty);
      return list;
    },
  },
  mutations: {

    setApiAuthorization(state,data){
      //console.log("setApiAuthorization");
      //console.log(data);

      if (data){
        //cognitoのstaff_cdは使わない state.account.staff_cd = data.staff_cd;
        state.account.cognito_user_name = data.user_name;
        if (state.account.idToken !== data.idToken){
          console.log("cognito idToken has changed!!");
        }
        state.account.idToken = data.idToken;
      } else {

        state.account.staff_cd = "";
        state.account.cognito_user_name = "";
        state.account.idToken = "";

      }
    },

    setProfile(state,data){
        //console.log("setProfile");
        //console.log(data);

        state.profile = data;

        if (data.user){
          console.log("set staff_cd " + data.user.staff_cd);
          state.account.staff_cd = data.user.staff_cd
        }

    },

    setGroups(state,data){
      state.groups.splice(0);
      if (data){
        data.forEach(rec => {
          state.groups.push({
            group_cd:rec.group_cd,
            group_name:rec.name,
            group_sname:rec.sname,
          });
        });
      }
    },

    setUsers(state,data){
      state.users.splice(0);
      if (data){
        data.forEach(rec => {
          state.users.push({
            user_id : rec.user_id,
            staff_cd : rec.staff_cd,
            name     : rec.name,
            family_name : rec.family_name,
            given_name : rec.given_name,
            kana     : rec.kana,
            group_cd : rec.group_cd,
            email    : rec.email,
            mobile_tel : rec.mobile_tel,
            title_cd   : rec.title_cd
          })
        });
      }
    },

    setEmotes(state,data){
      state.emotes.splice(0);
      if (data){
        data.forEach(rec => {
          state.emotes.push({
            emote_cd:rec.emote_cd,
            name:rec.name,
            explain:rec.explain,
            display_no:rec.display_no,
            state:rec.state,
          });
        });
      }

    },

    setCommonRefs(state,data){

      for (let k in data) {
        state.commonRefs[k] = data[k];
      }

    },

    setReport(state,data){
      let reportId = data.id;
      //if (state.reports[reportId]){
      //  state.reports[reportId] = Object.assign({}, state.reports[reportId], data)
      //} else {
      //  Vue.set(state.reports, reportId, data);
      //}
      Vue.set(state.reports, reportId, data);
    },


    // レポートにコメントを追加・更新する
    setReportComment(state,payload){

      let reportId = payload.reportId;
      let commentId = payload.commentId;
      let commentData = payload.data; // makeCommentDisplayData の結果

      let report = state.reports[reportId];
      if (report){

        if (!commentId){
          report.comments.push(commentData);
        } else {
          let updateIdx = report.comments.findIndex( v => v.comment_id == commentId);
          if (updateIdx > -1){
            report.comments.splice(updateIdx,1,commentData)
          }
        }

      }

    },

    delReportComment(state,payload){

      let reportId = payload.reportId;
      let commentId = payload.commentId;

      let report = state.reports[reportId];
      if (report){
        let updateIdx = report.comments.findIndex( v => v.comment_id == commentId);
        if (updateIdx > -1){
          report.comments.splice(updateIdx,1)
        }
      }
    },

    // レポートのコメントに返信を追加・更新する
    setReportReply(state,payload){

      let reportId = payload.reportId;
      let commentId = payload.commentId;
      let replyId = payload.replyId;
      let replyData = payload.data;



      let report = state.reports[reportId];
      if (report){
        let comment = report.comments.find( v => v.comment_id == commentId);
        if (comment){

          if (!replyId){
            comment.replies.push(replyData);
          } else {
            let updateIdx = comment.replies.findIndex( v => v.reply_id == replyId);
            if (updateIdx > -1){
              comment.replies.splice(updateIdx,1,replyData)
            }
          }

        }
      }

    },

    delReportReply(state,payload){

      let reportId = payload.reportId;
      let commentId = payload.commentId;
      let replyId = payload.replyId;

      let report = state.reports[reportId];
      if (report){
        let comment = report.comments.find( v => v.comment_id == commentId);
        if (comment){
          let updateIdx = comment.replies.findIndex( v => v.reply_id == replyId);
          if (updateIdx > -1){
            comment.replies.splice(updateIdx,1)
          }
        }
      }
    },

    // 既読状態の設定
    setReportRead(state,payload){

      let reportId = payload.reportId;
      let report = state.reports[reportId];
      if (report){

        if (!report.already_read){
          report.totalReads = report.totalReads + 1;
        }

        report.already_read = true;
        report.updated = false;

      }

    },

    // 既読状態の解除
    delReportRead(state,payload){

      let reportId = payload.reportId;
      let report = state.reports[reportId];
      if (report){

        if (report.already_read){
          report.totalReads = report.totalReads - 1;
        }

        report.already_read = false;
        report.updated = false;

        // 自動既読イベントを無効化する
        report.cancel_auto_read = true;


      }

    },

    // レポートにいいねを設定する
    setReportEmote(state,payload){

      let reportId = payload.reportId;
      let emoteData = payload.data;

      let report = state.reports[reportId];
      if (report){
        report.emote = emoteData;
      }

    },

    // レポートコメントにいいねを設定する
    setReportCommentEmote(state,payload){

      let reportId = payload.reportId;
      let commentId = payload.commentId;
      let emoteData = payload.data;

      let report = state.reports[reportId];
      if (report){

        let comment = report.comments.find( v => v.comment_id == commentId);
        if (comment){
          comment.emote = emoteData;
        }

      }

    },

    // 返信にいいねを設定する
    setReportReplyEmote(state,payload){

      let reportId = payload.reportId;
      let commentId = payload.commentId;
      let replyId = payload.replyId;
      let emoteData = payload.data;

      let report = state.reports[reportId];
      if (report){
        let comment = report.comments.find( v => v.comment_id == commentId);
        if (comment){
          let reply = comment.replies.find( v => v.reply_id == replyId);
          if (reply){
            reply.emote = emoteData;
          }
        }
      }

    },

    // ブックマーク状態の設定
    setReportBookmark(state,data){
      let reportId = data.id;
      let v = data.is_bookmarked;

      let report = state.reports[reportId];
      if (report){
        Vue.set(report, 'is_bookmarked', v);
      }
    },

    setNotifications(state,payload){

      let c = 0;

      state.notifications.splice(0);

      if (payload){
        payload.forEach(rec => {
          // stateは現在の最新情報
          // state2は表示時の状態
          rec.state2 = rec.state;
          state.notifications.push(rec);

          if (rec.state == 0) c += 1
        });
      }

      state.notification_count = c;

    },


    setNotificationState(state,data){
      let noticeId = data.id;
      let v = data.state;

      let rec = state.notifications.find(rec => rec.noticeId == noticeId);
      if (rec){
        Vue.set(rec, 'state', v);
      }

      state.notification_count = state.notifications.filter(n => n.state == 0 ).length;
    },

    updateNotificationState2(state,data){
      state.notifications.forEach(rec => {
        Vue.set(rec, 'state2', rec.state);
      });
    },

    // 未読のサンクスカード件数を設定
    setUnreadThanksCount(state,data){
      state.unreadThanksCardCount = data.count;
    },

  },
  actions: {


    // this.$store.dispatch('bookmark',reportId);
    bookmark(context,reportId){
      context.commit('setReportBookmark', {
        id:reportId,is_bookmarked:true,
      });
    },

    unBookmark(context,reportId){
      context.commit('setReportBookmark', {
        id:reportId,is_bookmarked:false,
      });
    },

    // 通知を既読にする
    // this.$store.dispatch('readNotification',お知らせID);
    readNotification(context,noticeId){
      context.commit('setNotificationState', {
        id:noticeId,state:1,
      });
    },

    getUnreadThanks(context){

      let checkLogin = function (){
        return Authentication.checkLogin();
      }

      Promise.resolve()
          .then(a => { return checkLogin() })
          .then(a => { return ThanksCardAPI.countUnread() })
          .then(data => {
            context.commit('setUnreadThanksCount', {
              count:data.data,
            });
            console.log("unreadThanksCount " + data.data);
          });
    },


  }
});
