<template>
    <v-dialog v-model="dialog" persistent max-width="600px" @keydown.esc="onCommentDlgCancelClick">
        <v-card>
            <v-card-title class="popup-header">
                <span class="headline">返信</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea counter label="返信" required v-model="message"></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn v-show="!!this.replyId" color="secondary" text @click.native="onCommentDlgDeleteClick">削除</v-btn>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" @click.native="onCommentDlgSaveClick">保存</v-btn>
                <v-btn color="grey lighten-2" @click.native="onCommentDlgCancelClick">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import Common from '@/components/Common';
import ReportCommentAPI from '@/apis/ReportCommentAPI'
import DialogMoveSupport from "../DialogMoveSupport";

export default {
    mixins:[Common,DialogMoveSupport],
    components : {
    },
    props: [
    ],
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,

            replyId: null,
            message: "",

            options: {
                color: 'primary',
                width: 290
            }
        };
    },
    watch:{
        'dialog':function(val){
            this.setupMoveEvents(this.dialog);
        },
    },
    methods: {
        open(staffCd,reportId,commentId,replyId,message, options) {

            this.staffCd = staffCd;
            this.reportId = reportId;
            this.commentId = commentId;
            this.replyId = replyId;
            this.message = message;

            this.options = Object.assign(this.options, options); // 使っていないけど一応

            this.dialog = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        onCommentDlgSaveClick() {

            let self = this;

            let replyData = {
                report_id:this.reportId,
                staff_cd:this.staffCd,
                comment:this.message
            };

            return Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => {
                    return ReportCommentAPI.saveReply(replyData,this.commentId,this.replyId)
                })
                .then(responseData => {
                    this.resolve([1,responseData]);
                    this.dialog = false;
                })
                .catch(function(err) {
                    console.log(err);
                    self.showErrorToast("データの登録に失敗しました。");
                });

        },

        onCommentDlgCancelClick() {
            this.resolve([0,""]);
            this.dialog = false;
        },

        onCommentDlgDeleteClick() {

            let self = this;

            Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return ReportCommentAPI.deleteReply(this.commentId,this.replyId) })
                .then(response => {
                    this.resolve([9,""]);
                    this.dialog = false;
                })
                .catch(function(err) {
                    console.log(err);
                    self.showErrorToast("データの更新に失敗しました。");
                });

        },
    },

}
/*
// 呼び出し方法
// https://qiita.com/ta1nakamura/items/f844dd2437af8ecc5674
async onClickOpen(){
  console.log('--onClickOpen')
  if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
    console.log('--yes')
  }else{
    console.log('--no')
  }
}
* */
</script>
