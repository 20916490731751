<template>
  <v-app>
    <span id="page_top_anchor"></span>

    <v-navigation-drawer
      v-if="isAuthenticated"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <left-drawer
        :items="items"
        :selected-menu-id="selectedMenuId"
        v-on:menuClick="menuItemClick"
      />

      <v-divider></v-divider>

      <!-- ログアウト -->
      <!--
      <v-list-item @click="" v-if="isAuthenticated"  @click="signOut">
          <v-list-item-action>
              <v-icon>logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
              <v-list-item-title>
                  Logout
              </v-list-item-title>
          </v-list-item-content>
      </v-list-item>
      --->
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      dark
      color="primary"
    >
      <!-- darken-3  -->

      <v-toolbar-title class="ml-0 pl-2">
        <!--  style="width: 150px"  -->
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ AppTitle }}</span>
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <!--
      <v-btn icon>
        <v-icon>apps</v-icon>
      </v-btn>-->

      <!-- ブックマーク -->
      <v-menu
        v-if="isInitialized && isAuthenticated"
        offset-y
        v-model="bookmarkMenuOpened"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon color="white">star</v-icon>
          </v-btn>
        </template>

        <v-card>
          <div v-if="bookmarks.length === 0" class="pa-3">
            <span class="caption">ブックマークされた日報はありません</span>
          </div>
          <v-list v-else class="bookmark-list" dense>
            <v-list-item v-for="bkm in bookmarks" :key="bkm.reportId">
              <v-list-item-content>
                <v-list-item-title>
                  <a v-on:click.prevent="onBookmarkClick(bkm)">
                    <span class="subtitle-2">{{ bkm.reportTitle }}</span>
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle
                  ><span
                    >{{ bkm.reportDate }} {{ bkm.reportStaffName }}</span
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon v-on:click="onDeleteBookmarkClick(bkm)">
                  <v-icon color="grey lighten-1">delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <!-- 通知 -->
      <v-menu
        v-if="isInitialized && isAuthenticated"
        offset-y
        v-model="notificationMenuOpened"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge overlap color="orange" v-if="notification_count > 0">
              <template v-slot:badge
                ><span>{{ notification_count }}</span></template
              >
              <v-icon>notifications</v-icon>
            </v-badge>
            <v-icon v-if="notification_count === 0">notifications</v-icon>
          </v-btn>
        </template>

        <NoticeList v-on:noticeClick="onNoticeClick" />
      </v-menu>

      <v-menu v-if="isInitialized && isAuthenticated" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-if="isAuthenticated" v-on="on" class="pl-0 pr-2">
            <!-- icon -->
            <v-avatar size="32px" item>
              <v-icon>person</v-icon>
            </v-avatar>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ loginUserName }}</span>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <!--<v-avatar size="28" color="grey lighten-1">
                  <span class="white--text headline"></span>
                </v-avatar>-->
                <ProfileImageView
                  :staff-cd="account.staff_cd"
                ></ProfileImageView>
                <span class="white--text headline"></span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ loginUserName }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  loginUserGroupName
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <!--
              <v-list-item-action>
                <v-btn class="red--text" icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
              </v-list-item-action>
              -->
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-action
                ><v-icon>account_box</v-icon></v-list-item-action
              >
              <v-list-item-title
                ><router-link to="/profile"
                  >プロフィール設定</router-link
                ></v-list-item-title
              >
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-action><v-icon>logout</v-icon></v-list-item-action>
              <v-list-item-title
                ><a v-on:click.prevent="signOut"
                  >ログアウト</a
                ></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-btn
        v-if="visibleReloadBtn && isInitialized"
        text
        icon
        v-on:click.prevent="onReloadBtnClick"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- ここがメインのコンテンツ -->
    <v-main v-scroll="handleScroll">
      <router-view v-if="isInitialized"></router-view>

      <v-container v-else fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- ダイアログ色々 -->
    <ReportDialog ref="reportDialog"></ReportDialog>
    <CommentDialog ref="commentDialog"></CommentDialog>
    <ReplyDialog ref="replyDialog"></ReplyDialog>
    <ForwardDialog ref="forwardDialog"></ForwardDialog>

    <ImageViewer ref="imageViewer"></ImageViewer>

    <!-- TOPへ戻るボタン -->
    <v-fab-transition>
      <v-btn
        v-show="visibleLinkToTop"
        color="pink"
        dark
        fixed
        bottom
        right
        fab
        v-on:click.prevent="onScrollToTopClicked"
        class="top-link-btn"
      >
        <v-icon>arrow_upward</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DateTime } from "luxon";
import Authentication from "./authentication";
import Common from "@/components/Common";
import RoleSupport from "@/components/RoleSupport";
import { AmplifyEventBus } from "aws-amplify-vue";
import LoginSupport from "./components/LoginSupport";
import NotificationSupport from "./components/NotificationSupport";
import NoticeList from "./components/NoticeList";
import CommentDialog from "./components/dialogs/CommentDialog";
import ReplyDialog from "./components/dialogs/ReplyDialog";
import ForwardDialog from "./components/dialogs/ForwardDialog";
import ReportDialog from "./components/dialogs/ReportDialog";
import ImageViewer from "./components/dialogs/ImageViewer";
import ProfileImageView from "@/components/controls/ProfileImageView";
import NotificationAPI from "./apis/NotificationAPI";
import BookmarkAPI from "./apis/BookmarkAPI";
import LeftDrawer from "./components/parts/LeftDrawer";
import EnvSupport from "@/components/EnvSupport";
import ThanksCardAPI from "@/apis/ThanksCardAPI";

export default {
  name: "App",
  components: {
    NoticeList,
    CommentDialog,
    ReplyDialog,
    ForwardDialog,
    ReportDialog,
    ImageViewer,
    ProfileImageView,
    LeftDrawer,
  },
  mixins: [Common, LoginSupport, RoleSupport, NotificationSupport, EnvSupport],
  props: {
    source: String,
  },
  created() {
    // https://qiita.com/wataruoguchi/items/3a2e641aca25190b4178
    AmplifyEventBus.$on("authState", (info) => {
      //console.log("authState event. [" + info + "]");

      if (info === "signedIn") {
      } else if (info === "signedOut") {
      }
    });
  },
  mounted: function () {
    let self = this;
    this.selectMenu(this.$router.currentRoute.path);

    // イベントハブのリッスンを開始
    this.$eventHub.$off("signin", this.onSignIn);
    this.$eventHub.$on("signin", this.onSignIn);

    this.$eventHub.$off("bookmark", this.onBookmark);
    this.$eventHub.$on("bookmark", this.onBookmark);

    this.$eventHub.$off("unbookmark", this.onUnBookmark);
    this.$eventHub.$on("unbookmark", this.onUnBookmark);

    self.loginCheck();

    // ダイアログなどのrefsを登録
    this.$store.commit("setCommonRefs", {
      commentDialog: this.$refs.commentDialog,
      replyDialog: this.$refs.replyDialog,
      forwardDialog: this.$refs.forwardDialog,
      reportDialog: this.$refs.reportDialog,
      imageViewerDialog: this.$refs.imageViewer,
    });

    let meta = this.$router.currentRoute.meta;
    if (!!meta && meta.useReload) {
      this.visibleReloadBtn = true;
    } else {
      this.visibleReloadBtn = false;
    }

    // metaから scrollToTop ボタンの表示有無を設定
    if (!!meta && meta.scrollToTop !== undefined && !meta.scrollToTop) {
      this.enableScrollToTopBtn = false;
    } else {
      this.enableScrollToTopBtn = true;
    }

    // リビジョンチェック
    window.app.checkRevision();
  },
  beforeDestroy() {
    this.$eventHub.$off("signin", this.onSignIn);
    this.$eventHub.$off("bookmark", this.onBookmark);
    this.$eventHub.$off("unbookmark", this.onUnBookmark);
  },
  watch: {
    $route: function (to, from) {
      let urlPath = to.path;

      this.selectMenu(urlPath);
      this.reportDialogRef.close();

      let meta = to.meta;
      // metaからリロードボタンの有無を取得、設定
      if (!!meta && meta.useReload) {
        this.visibleReloadBtn = true;
      } else {
        this.visibleReloadBtn = false;
      }

      // metaから scrollToTop ボタンの表示有無を設定
      if (!!meta && meta.scrollToTop !== undefined && !meta.scrollToTop) {
        this.enableScrollToTopBtn = false;
      } else {
        this.enableScrollToTopBtn = true;
      }

      // GAへページビューを送信
      //gtag('config', process.env.VUE_APP_TRACKING_ID, {'page_path': urlPath});

      // リビジョンチェック
      window.app.checkRevision();
    },
    notificationMenuOpened: function (to, from) {
      if (this.notificationMenuOpened) {
        //console.log("notificationMenu Opened")
        this.markNotificationReaded();
      } else {
        // 閉じられた時は notice.state → state2 に更新
        this.$store.commit("updateNotificationState2", {});
      }
    },
  },
  computed: {
    ...mapState(["unreadThanksCardCount"]),

    loginUserName() {
      if (this.account) {
        let staff_cd = this.account.staff_cd;
        let user = this.findUser(staff_cd);
        if (user) {
          return user.name;
        }
      }
      return "";
    },

    loginUserGroupName() {
      if (this.account) {
        let staff_cd = this.account.staff_cd;
        let user = this.findUser(staff_cd);
        if (user) {
          let group_cd = user.group_cd;
          return this.getGroupName(group_cd);
        }
      }
      return "";
    },
  },
  data: () => ({
    // 初期化フラグ
    isInitialized: false,

    // 通知のポップアップ
    notificationMenuOpened: false,

    // ブックマーク
    bookmarks: [
      {
        reportId: "",
        reportTitle: "",
        reportDate: "",
      },
    ],

    bookmarkMenuOpened: false,

    dialog: false,
    drawer: null,
    items: [
      { id: "1", icon: "home", text: "Home", link: "/" },
      { id: "2", icon: "view_day", text: "Timeline", link: "/timeline" },
      { id: "3", icon: "view_module", text: "Daily", link: "/daily" },

      { id: "5", icon: "search", text: "Search", link: "/search" },
      { id: "20", icon: "create", text: "Entry", link: "/entry" },

      {
        id: "26",
        icon: "fa-heart",
        text: "Thanks!",
        link: "/thanks/_self",
        badge: "unreadThanksCardCount",
        thanks: true,
      },

      { id: "31", icon: "group_add", text: "Follow", link: "/follows" },
      {
        id: "32",
        icon: "calendar_today",
        text: "Calendar",
        link: "/user_calendar",
      },

      {
        id: "98",
        icon: "fa-user-shield",
        text: "Admin",
        children: [
          {
            id: "51",
            text: "EntryCheck",
            link: "/entrycheck",
            role: "manager",
          },
          {
            id: "52",
            text: "Attendance",
            link: "/attendance",
            role: ["system", "PERSONNEL"],
            attendance: true,
          },
        ],
      },

      {
        id: "99",
        icon: "settings_applications",
        text: "SystemSettings",
        role: "system",
        children: [
          {
            id: "101",
            icon: "supervisor_account",
            text: "Users",
            link: "/users",
          },
          {
            id: "102",
            icon: "group_add",
            text: "Follow",
            link: "/admin/follows",
          },
          {
            id: "103",
            icon: "calendar_today",
            text: "CompanyCalendar",
            link: "/calendar",
          },
          {
            id: "104",
            icon: "group_add",
            text: "Group",
            link: "/admin/groups",
          },
          {
            id: "105",
            text: "UserRoles",
            link: "/admin/user_roles",
            role: "developer",
          },
          {
            id: "999",
            text: "Debug",
            link: "/system_maintenance",
            role: "developer",
          },
        ],
      },
    ],
    selectedMenuId: "",

    // TOPへ戻るボタン
    visibleLinkToTop: false,

    enableScrollToTopBtn: false,

    // 更新ボタン
    visibleReloadBtn: false,
  }),
  methods: {
    ...mapActions(["getUnreadThanks"]),

    // 起動時のログインチェック処理
    loginCheck() {
      let self = this;
      // ログインチェック
      return self
        .checkAuthToken()
        .then((data) => {
          self.$eventHub.$emit("signin", data);
          return data;
        })
        .then((data) => self.appLoginFlow(data))
        .then((data) => {
          self.isInitialized = true;
        })
        .catch(function (err) {
          console.log(err);
          self.isInitialized = true;
        });
    },

    // サインインされた時にEventHubから呼び出される
    onSignIn(data) {
      //console.log("handle onSignIn");

      // 通知を求める
      this.fetchNotifications().catch(function (err) {
        console.log(err);
        self.showErrorToast("データの取得に失敗しました。");
      });

      // ブックマークを求める
      this.fetchBookmarks();

      // 未読のサンクスカード件数
      this.getUnreadThanks();
    },

    // ブックマークされた時、解除された時にEventHubから呼び出される
    onBookmark() {
      // ブックマークを求める
      this.fetchBookmarks();
    },

    onUnBookmark() {
      this.fetchBookmarks();
    },

    menuItemClick(item) {
      let link = item.link;
      if (link) {
        this.$router.push({ path: link, query: {} });
      }
    },

    signIn() {
      this.$router.push({ path: "/login", query: { backuri: "" } });
    },

    signOut() {
      let self = this;
      Authentication.signOut().then((data) => {
        self.setupApiAuthorization(null);
        self.$router.push({ path: "/login", query: { backuri: "" } });
      });
    },

    fetchBookmarks() {
      this.bookmarks.splice(0);

      let self = this;
      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return BookmarkAPI.listBookmark();
        })
        .then((a) => {
          let list = a.data;

          list.forEach((bk) => {
            let e = {
              reportId: bk.report.id,
              reportTitle: bk.report.title,
              reportDate: bk.report.dt,
            };

            if (bk.report.report_type == "B") {
              e.reportTitle = "定型業務";
            }
            if (!!bk.staff) {
              e.reportStaffName = bk.staff.name;
            }

            self.bookmarks.push(e);
          });
        });
    },

    // お知らせのクリック
    onNoticeClick(notice) {
      // クリックしたお知らせを既読とする
      this.notificationSetRead(notice);

      // 対象の日報を開く
      let reportId = notice.reportId;
      if (reportId) {
        //let url = "/report/" + reportId;
        //this.$router.push(url);
        this.reportDialogRef.open(reportId, {});
      }
    },

    // お知らせを一括で既読とする
    // お知らせ画面を開いた時に呼び出される
    markNotificationReaded() {
      // 既読とする対象は「いいね」だけ
      let emoteList = this.notifications.filter(
        (n) =>
          n.noticeType === "emote" ||
          n.noticeType === "cmt_emote" ||
          n.noticeType === "rep_emote"
      );
      this.notificationListSetRead(emoteList);
    },

    selectMenu(path) {
      //console.log("selectMenu");
      //console.log(path);

      this.selectedMenuId = "";

      for (let item of this.items) {
        if (item.link === path) {
          this.selectedMenuId = item.id;
          break;
        }
        if (!!item.children) {
          for (let child of item.children) {
            if (child.link === path) {
              this.selectedMenuId = child.id;
              break;
            }
          }
          if (!!this.selectedMenuId) break;
        }
      }
    },

    handleScroll() {
      if (!this.enableScrollToTopBtn) {
        this.visibleLinkToTop = false;
        return;
      }

      // スクロール値が151以上の場合にvisibleをtrue、そうでない場合にfalseにする
      //this.visibleLinkToTop = window.pageYOffset > 300
      //;
      let scY = window.scrollY;
      let offY = window.pageYOffset;

      //console.log("handleScroll scrollY:" + scY + " pageYOffset:" + offY);
      // window.scrollY はIE11で使えないみたい。。

      this.visibleLinkToTop = offY > 300;
    },

    onScrollToTopClicked() {
      this.$vuetify.goTo("#page_top_anchor", {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },

    onReloadBtnClick() {
      this.$eventHub.$emit("reload", {});
    },

    onBookmarkClick(bkm) {
      this.bookmarkMenuOpened = false;

      let reportId = bkm.reportId;
      if (reportId) {
        this.reportDialogRef.open(reportId, {});
      }
    },

    onDeleteBookmarkClick(bkm) {
      let self = this;
      let reportId = bkm.reportId;

      this.bookmarkMenuOpened = false;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return BookmarkAPI.deleteBookmark(reportId);
        })
        .then((response) => {
          this.showMessageToast("ブックマークを解除しました。");

          let idx = this.bookmarks.findIndex((b) => b.reportId === reportId);
          if (idx > -1) {
            this.bookmarks.splice(idx, 1);
          }

          self.$store.dispatch("unBookmark", reportId);
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの更新に失敗しました。");
        });
    },
  },
};
</script>
