<template>
  <!-- 日報の個別表示ダイアログ -->
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <loading-view :loading="loading" />

      <template v-if="!loading">
        <v-list-item>
          <ProfileImageView :staff-cd="userEntry.staff_cd"></ProfileImageView>
          <v-list-item-content class="ml-3">
            <v-list-item-title class="headline">{{
              userEntry.user_name
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              userEntry.group_name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- 標準日報 -->
        <ReportDetail :report-id="reportId" />

        <!-- A,B共通のツールボックスとコメント-->
        <ReportComments :report-id="reportId" />

        <!-- 転送の履歴 転送可能な人だけ表示 -->
        <ReportForwards :report-id="reportId" />
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import Common from "@/components/Common";
import ReportViewSupport from "@/components/ReportViewSupport";
import ReportAPI from "@/apis/ReportAPI";
import UserAPI from "@/apis/UserAPI";
import ProfileImageView from "@/components/controls/ProfileImageView";
import ReportComments from "@/views/sub/ReportComments";
import ReportDetail from "@/views/sub/ReportDetail";
import ReportForwards from "../../views/sub/ReportForwards";

export default {
  mixins: [Common, ReportViewSupport],
  components: {
    ProfileImageView,
    ReportComments,
    ReportDetail,
    ReportForwards,
  },
  mounted: function () {},
  props: [],
  watch: {},
  data() {
    return {
      // 制御用変数
      dialog: false,
      loading: false,

      resolve: null,
      reject: null,

      // 起動パラメータ
      reportId: null,
      options: {},

      // 以下表示データ
      userEntry: {
        staff_cd: "",
        user_name: "",
        group_name: "",
      },
    };
  },
  methods: {
    //--------------------------------------------
    // ダイアログ制御
    //--------------------------------------------
    open(reportId, options) {
      this.reportId = reportId;
      this.options = Object.assign(this.options, options); // 使っていないけど一応

      this.fetchData();
      this.dialog = true;
      /*
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });*/

      this.$nextTick(() => {
        this.$el.focus();
      });
    },
    close() {
      this.dialog = false;
    },

    //--------------------------------------------
    // データ処理
    //--------------------------------------------
    fetchData() {
      let self = this;

      this.loading = true;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportAPI.getEntry(this.reportId);
        })
        .then((a) => {
          return UserAPI.profileByStaffCd(a.data.report.staff_cd).then((b) => {
            return {
              reportEntity: a.data,
              profile: b.data,
            };
          });
        })
        .then((data) => {
          self.makeDisplayData(data);
          self.loading = false;

          // 自動の場合にはこのタイミングで既読にする
          if (self.useAutoRead) {
            this.setAlreadyRead(self.reportId);
          }
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
          self.showErrorToast("データの取得に失敗しました。");
        });
    },

    makeDisplayData(data) {
      let self = this;

      //console.log(data);
      let entrySet = data.reportEntity;
      let profile = data.profile;

      let reportEntry = self.makeReportDisplayData(entrySet);

      // vuex.stateに日報データを保存
      this.$store.commit("setReport", reportEntry);

      let report = entrySet.report;
      let staffCd = report.staff_cd;

      //this.entry = reportEntry;

      this.userEntry.staff_cd = staffCd;
      this.userEntry.user_name = profile.user.name;
      this.userEntry.group_name = profile.groupName;
    },
  },
};
</script>
