import axios from 'axios';
import Util from '../Util'

class TimelineAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    issueTimelineNo(condition){

        let path = "/api/timeline/issue";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint, condition).then(response =>  response.data)

    }


    listEntries(timelineNo,baseIndex){

        let path = "/api/timeline/i/" + timelineNo;
        if (baseIndex){
            path += "/" + baseIndex
        }

        let endpoint =  Util.combineUrl(this.apiHost ,path);

        return axios.get(endpoint).then(response =>  response.data)
    }


    listMyEntries(baseIndex){

        let path = "/api/home";
        if (baseIndex){
            path += "/" + baseIndex
        }

        let endpoint =  Util.combineUrl(this.apiHost ,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    putAlreadyRead(reportId){

        let path = "/api/entry/" + reportId + "/read";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {}).then(response =>  response.data)
    }

    deleteAlreadyRead(reportId){

        let path = "/api/entry/" + reportId + "/read";

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.delete(endpoint, {}).then(response =>  response.data)
    }

}

let _api = new TimelineAPI();

export default _api;