<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
        <v-card>
            <v-card-title class="title grey lighten-2" primary-title>
                {{ title }}
            </v-card-title>

            <v-card-text v-show="!!message" class="body-1 pt-5 black--text">{{ message }}</v-card-text>

            <!-- <v-divider></v-divider> -->

            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" elevation="0" @click.native="agree">はい</v-btn>
                <v-btn color="normal"  elevation="0" @click.native="cancel">キャンセル</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        components : {
        },
        props: [
        ],
        data() {
            return {
                dialog: false,
                resolve: null,
                reject: null,
                message: null,
                title: null,
                options: {
                    //color: 'primary',
                    width: 290
                }
            };
        },
        methods: {
            open(title, message, options) {
                this.dialog = true;
                this.title = title;
                this.message = message;
                this.options = Object.assign(this.options, options);
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            agree() {
                this.resolve(true);
                this.dialog = false;
            },
            cancel() {
                this.resolve(false);
                this.dialog = false;
            }
        },

    }
    /*
    // 呼び出し方法
    // https://qiita.com/ta1nakamura/items/f844dd2437af8ecc5674
    async onClickOpen(){
      console.log('--onClickOpen')
      if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
        console.log('--yes')
      }else{
        console.log('--no')
      }
    }
    * */
</script>
