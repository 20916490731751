<template>
    <v-menu
            v-model="menuFlg"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    v-model="inputDate"
                    :label="label"
                    prepend-icon="event"
                    readonly
                    v-on="on"
            >
                <template v-slot:append v-if="stepper">
                    <a v-on:click.prevent="onPrevClick"><v-icon>navigate_before</v-icon></a>
                    <a v-on:click.prevent="onNextClick"><v-icon>navigate_next</v-icon></a>
                </template>

            </v-text-field>
        </template>
        <v-date-picker v-model="inputDate" @input="menuFlg = false" locale="ja-jp" :day-format="date => new Date(date).getDate()"></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';
export default {
    name: "DateInputField",
    mounted: function() {
        this.refId = this.makeRefId();
        //console.log("DateInputField mounted value " + this.value);
        this.emittedDate = this.value;
        this.inputDate = this.value;
    },
    watch: {
        value() {
            if (this.inputDate !== this.value){
                this.emittedDate = this.value;
                this.inputDate = this.value;
            }
        }
    },
    props: {
        label:"",
        value: {
            type: String,
            required: true,
        },
        stepper:{
            type: Boolean,
            default:true
        }
    },
    data() {
        return {
            redId:"",
            menuFlg:false,
            inputDate:null,
            emittedDate:null,
        }
    },
    methods: {
        makeRefId(){
            // 生成する文字列の長さ
            let l = 8;
            // 生成する文字列に含める文字セット
            let c = "abcdefghijklmnopqrstuvwxyz0123456789";
            let cl = c.length;
            let r = "";
            for(let i=0; i<l; i++){
                r += c[Math.floor(Math.random()*cl)];
            }
            return "datef_" + r;
        },
        onPrevClick(){
            if (this.inputDate){
                let a = moment(this.inputDate, "YYYY-MM-DD").subtract(1, 'days');
                this.inputDate = a.format("YYYY-MM-DD");
            } else {
                this.inputDate = moment().format("YYYY-MM-DD");
            }
        },
        onNextClick(){
            if (this.inputDate){
                let a = moment(this.inputDate, "YYYY-MM-DD").add(1, 'days');
                this.inputDate = a.format("YYYY-MM-DD");
            } else {
                this.inputDate = moment().format("YYYY-MM-DD");
            }
        },
    },
    updated: function() {
        //console.log("DateInputField updated." + this.inputDate);
        if (this.emittedDate != this.inputDate){
            //console.log("DateInputField fire input." + this.inputDate);
            this.$emit('input', this.inputDate);
        }
        this.emittedDate = this.inputDate;
    },
}
</script>

<style scoped>

</style>