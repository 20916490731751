import axios from 'axios';
import Util from '../Util'

class ReportEmoteAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    putEmote(reportId,emoteCd){

        // /entry/:id/emote

        let path = "/api/entry/" + reportId + "/emote";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {
            emote_cd:emoteCd
        }).then(response =>  response.data)
    }

    putCommentEmote(commentId,emoteCd){

        // /comment/:commentId/emote

        let path = "/api/comment/" + commentId + "/emote";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {
            emote_cd:emoteCd
        }).then(response =>  response.data)
    }

    putReplyEmote(commentId,replyId,emoteCd){

        // /comment/:commentId/reply/:replyId/emote

        let path = "/api/comment/" + commentId + "/reply/" + replyId + "/emote";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.put(endpoint, {
            emote_cd:emoteCd
        }).then(response =>  response.data)
    }




}

let _api = new ReportEmoteAPI();

export default _api;