<template>
  <v-container class="report_entry">
    <template v-if="entry.entry_type === 'A'">
      <!-- 通常業務日報 -->

      <div class="report_time">
        <span class="mr-2">{{
          formatDateToHeaderTitleDisplay(entry.date)
        }}</span>
        <span v-if="!!entry.all_day">終日</span>
        <span v-if="!entry.all_day"
          >({{ entry.start_time }} - {{ entry.end_time }})</span
        >
      </div>

      <div class="report_title">
        <span v-if="entry.title"
          >【 <span class="font-weight-bold">{{ entry.title }}</span> 】</span
        >

        <v-chip
          class="report_attr"
          small
          v-if="entry.goodOrBad === 'B'"
          color="red darken-1"
          text-color="white"
          >BadNews</v-chip
        >
        <v-chip
          class="report_attr"
          small
          v-if="entry.goodOrBad === 'G'"
          color="blue darken-1"
          text-color="white"
          >GoodNews</v-chip
        >

        <v-chip
          class="report_attr"
          small
          v-if="entry.importance === '1'"
          color="blue darken-1"
          text-color="white"
          >重要</v-chip
        >
        <v-chip
          class="report_attr"
          small
          v-if="entry.importance === '2'"
          color="orange darken-1"
          text-color="white"
          >最重要</v-chip
        >
        <v-chip
          class="report_attr"
          small
          v-if="entry.importance === '3'"
          color="red darken-1"
          text-color="white"
          >緊急!</v-chip
        >
      </div>

      <div class="report_content">
        <div
          v-for="(contentBlock, blockIdx) in entry.parsedContentBlocks"
          :key="blockIdx"
          class="mb-6"
        >
          <div v-if="contentBlock.title" class="py-3">
            <div class="report_subtitle_icon"></div>
            <span class="text-subtitle-1">{{ contentBlock.title }}</span>
          </div>
          <div class="text-body-1">
            <template v-for="pc in contentBlock.contents">
              <span v-if="pc.type === 'text'">{{ pc.value }}</span>
              <a v-if="pc.type === 'url'" :href="pc.value" target="_blank">{{
                pc.value
              }}</a>
              <br v-if="pc.type === 'break'" />
            </template>
          </div>
        </div>
      </div>
      <!--<div class="report_content">{{entry.content}}</div>-->

      <MultiFilePreview
        v-if="entry.files && entry.files.length > 0"
        :file-ids="entry.files"
        @click="onFileClick"
      ></MultiFilePreview>

      <div class="report_tags">
        <!--class="tags" -->
        <v-chip-group multiple column active-class="primary--text">
          <v-chip small v-for="tag in entry.tags">#{{ tag.name }}</v-chip>
        </v-chip-group>
      </div>
    </template>

    <template v-if="entry.entry_type === 'B'">
      <!-- 定型業務日報 -->

      <div class="report_time">
        <span>{{ formatDateToDisplayMD(entry.date) }}</span>
      </div>

      <div class="report_title">
        【<span class="font-weight-bold">定型業務</span>】
      </div>

      <v-layout column>
        <template v-for="todo in entry.todoList">
          <v-row dense class="pl-6">
            <v-col>
              <i v-if="todo.state == 0" class="fas fa-times"></i>
              <i
                v-if="todo.state == 1"
                class="fas fa-check-circle color-success"
              ></i>
              <i v-if="todo.state == 2" class="fas fa-check"></i>
              {{ todo.title }}
              <i class="fas fa-arrow-right grey--text mr-2"></i>
              <span v-if="todo.state == 0"
                >未処理<span class="caption">(明日実行)</span></span
              >
              <span v-if="todo.state == 1">処理済</span>
              <span v-if="todo.state == 2">今回は処理しない</span>
            </v-col>
          </v-row>
          <v-row dense class="pl-10">
            <v-col cols="12">{{ todo.note }}</v-col>
          </v-row>
        </template>
      </v-layout>

      <v-layout>
        <v-row>
          <v-col cols="12" class="report_content">{{ entry.content }}</v-col>
        </v-row>
      </v-layout>
    </template>
  </v-container>
</template>
<script>
import Common from "@/components/Common";
import ReportViewSupport from "@/components/ReportViewSupport";
import MultiFilePreview from "@/components/controls/MultiFilePreview";
export default {
  mixins: [Common, ReportViewSupport],
  components: {
    MultiFilePreview,
  },
  props: {
    reportId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    entry() {
      return this.$store.state.reports[this.reportId];
    },
  },
  methods: {
    //--------------------------------------------
    // ファイルのクリック
    //--------------------------------------------
    onFileClick(data) {
      if (data.isImage) {
        this.imageViewerDialogRef.open(data.downloadUrl, {});
      }
    },
  },
};
</script>
