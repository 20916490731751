import axios from 'axios';
import Util from '../Util'

class ReportSearchAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    search(cond){

        let path = "/api/entries/search";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint, cond).then(response =>  response.data)
    }

}

let _api = new ReportSearchAPI();

export default _api;