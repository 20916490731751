<template>
    <div>
        <v-container v-if="!isMobile" fluid class="file-drop-area" ref="uploadTarget" v-on:dragover="onDragOver" v-on:dragenter="onDragEnter" v-on:dragleave="onDragLeave" v-on:drop="onDrop">
            <v-row>
                <v-col v-if="fileIds == null || fileIds.length === 0">
                    <span>ファイルをドロップ</span>
                </v-col>
                <v-col v-for="fileId in fileIds" :key="fileId" class="d-flex child-flex" cols="6">
                    <FilePreview :fileId="fileId" :canDelete="true" @delete="onDelete" ></FilePreview>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else fluid>
            <v-row>
                <label for="fileInput" >
                    <span class="browse_btn">ファイルを添付</span>
                    <input type="file" style="display: none" v-on:change="onFileChange" id="fileInput" ref="fileInput">
                </label>
            </v-row>
            <v-row>
                <v-col v-for="fileId in fileIds" :key="fileId" class="d-flex child-flex" cols="6">
                    <FilePreview :fileId="fileId" :canDelete="true" @delete="onDelete" ></FilePreview>
                </v-col>
            </v-row>
        </v-container>
    </div>

</template>
<script>
import FileUploadAPI from '@/apis/FileUploadAPI';
import FilePreview from "@/components/controls/FilePreview";
export default {
    components : {
        FilePreview
    },
    props: [
        'fileIds'
    ],
    data() {
        return {
        }
    },
    computed: {
        isMobile:function () {
            return window.app.isMobile;
            //return true;
        }
    },
    methods: {
        onDelete(fileId){
            this.$emit('delete',fileId);
        },


        // ファイルのドロップ処理
        findTargetElement(){
            return this.$refs.uploadTarget;
        },

        onDrop(e){
            e.preventDefault();
            e.stopPropagation();

            let elm = this.findTargetElement();
            elm.classList.remove('filehover');


            console.log("onDrop ");

            let files = e.dataTransfer.files;
            console.log(files);
            //this.processFiles(files);

            this.fireDropEvent(files);
        },

        onDragOver(e){
            e.preventDefault();
            let elm = this.findTargetElement();
            elm.classList.add('filehover');
        },
        onDragEnter(e){
            e.preventDefault();
        },
        onDragLeave(e){
            e.preventDefault();
            let elm = this.findTargetElement();
            elm.classList.remove('filehover');
        },

        fireDropEvent(files){
            this.$emit('drop',files);
        },


        onFileChange(e){
            let files = e.target.files;
            console.log(files);
            this.fireDropEvent(files);

            this.$refs.fileInput.value = ""

        }

    },

}
</script>
<style lang="scss">
    .browse_btn {
        background-color: #d3d3d3;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 6px;
        padding-bottom: 6px;
        border-radius: 4px;
        cursor: pointer;
    }
</style>