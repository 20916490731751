<script>
export default {
    mounted: function() {
    },

    data() {
        return {
        }
    },
    computed: {
        profileRoles(){
            if (this.$store.state.profile){
                return this.$store.state.profile.roles;
            }
            else {
                return [];
            }
        },
        hasSystemRole(){
            return this.hasRole("system");
        },
    },
    methods: {
        hasRole(roleName){
            if (roleName){
                // 配列だったら何かが含まれていたらOKとする
                if (Array.isArray(roleName)){
                    return roleName.some(rn => this.profileRoles.includes(rn))
                } else {
                    return this.profileRoles.includes(roleName);
                }
            } else {
                return true;
            }
        },
    },

}
</script>