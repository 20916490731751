<template>
    <v-container fluid>
        <v-row>
            <v-col v-for="(fileId,idx) in fileIds" :key="fileId" class="d-flex child-flex" cols="12" sm="6" md="4" lg="4" xl="4">
                <FilePreview :fileId="fileId" @click="onClick"></FilePreview>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import FileUploadAPI from '@/apis/FileUploadAPI';
import FilePreview from "@/components/controls/FilePreview";
export default {
    components : {
        FilePreview
    },
    props: [
        'fileIds'
    ],
    data() {
        return {
        }
    },
    methods: {
        onClick(data){
            this.fireClickEvent(data)
        },
        fireClickEvent(data){
            this.$emit('click',data);
        },
    },

}
</script>