<script>
    export default {
        mounted: function() {
        },

        data() {
            return {
                moveHandleEnabled:false,
                moveHandleEventObject : null,
            }
        },
        computed: {
        },
        methods: {

            setupMoveEvents(dialogFlg){

                // https://github.com/vuetifyjs/vuetify/issues/4058

                if (window.app.isMobile) return;

                if (this.moveHandleEnabled && dialogFlg) return;
                if (!this.moveHandleEnabled && !dialogFlg) return;

                let self = this;

                if (!this.moveHandleEventObject){
                    this.moveHandleEventObject = {};
                    this.moveHandleEventObject.d = {};

                    this.moveHandleEventObject.mousedown = function(e){
                        let d = self.moveHandleEventObject.d;
                        const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
                        if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title")) { // element which can be used to move element
                            d.el = closestDialog; // element which should be moved
                            d.mouseStartX = e.clientX;
                            d.mouseStartY = e.clientY;
                            d.elStartX = d.el.getBoundingClientRect().left;
                            d.elStartY = d.el.getBoundingClientRect().top;
                            d.el.style.position = "fixed";
                            d.el.style.margin = 0;
                            d.oldTransition = d.el.style.transition;
                            d.el.style.transition = "none"
                        }
                    };

                    this.moveHandleEventObject.mousemove = function(e){
                        let d = self.moveHandleEventObject.d;
                        if (d.el === undefined) return;
                        d.el.style.left = Math.min(
                            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
                            window.innerWidth - d.el.getBoundingClientRect().width
                        ) + "px";
                        d.el.style.top = Math.min(
                            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
                            window.innerHeight - d.el.getBoundingClientRect().height
                        ) + "px";
                    };

                    this.moveHandleEventObject.mouseup = function (e) {
                        let d = self.moveHandleEventObject.d;
                        if (d.el === undefined) return;
                        d.el.style.transition = d.oldTransition;
                        d.el = undefined
                    };


                }

                if (dialogFlg){

                    document.addEventListener("mousedown", this.moveHandleEventObject.mousedown);
                    document.addEventListener("mousemove", this.moveHandleEventObject.mousemove);
                    document.addEventListener("mouseup",  this.moveHandleEventObject.mouseup);

                    this.moveHandleEventObject.timer = setInterval(() => { // prevent out of bounds
                        const dialog = document.querySelector(".v-dialog.v-dialog--active");
                        if (dialog === null) return;
                        dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
                        dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
                    }, 100);

                    this.moveHandleEnabled = true;
                }
                else {

                    document.removeEventListener("mousedown", this.moveHandleEventObject.mousedown);
                    document.removeEventListener("mousemove", this.moveHandleEventObject.mousemove);
                    document.removeEventListener("mouseup",  this.moveHandleEventObject.mouseup);

                    if (this.moveHandleEventObject.timer){
                        clearInterval(this.moveHandleEventObject.timer);
                        this.moveHandleEventObject.timer = null;
                    }

                    this.moveHandleEnabled = false;
                }

            }
        },

    }
    /*
    使い方

    watch:{
        'dialog':function(val){
            this.setupMoveEvents(this.dialog);
        },
    },


    */
</script>