import axios from 'axios';
import Util from '../Util'

class FileUploadAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    uploadFile(file,onProgress){

        console.log("FileAPI.uploadFile");
        console.log(file);

        let path = "/api/file";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        let formData = new FormData();
        formData.append('file', file);
        formData.append('status', "1");        // 仮登録としてupload
        formData.append('type', file.type);

        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total );
                if (onProgress) onProgress(percentCompleted);
                return percentCompleted;
            },
        };

        return axios.post(endpoint,formData,config).then(response =>  response.data);
    }

    fileInfo(fileId){
        let path = "/api/file/" + fileId + "/info";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }

    getDownloadUrl(fileId){
        let path = "/api/file/" + fileId + "/info";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data.downloadUrl)
    }

    getThumbnailUrl(fileId,w,h,m){

        let url = "/image/" + fileId;
        url += "?w=" + w;
        url += "&h=" + h;
        url += "&m=" + m;

        let endpoint =  Util.combineUrl(this.apiHost,url);

        return new Promise(function(resolve, reject){
            resolve(endpoint);
        });
    }

}

let _api = new FileUploadAPI();

export default _api;