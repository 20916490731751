<template>
    <v-img v-on:load="onImageLoad" v-on:error="onImageError"
           :src="imageSrc" :height="height" :max-height="maxHeight" :width="width" :max-width="maxWidth" contain>
        <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center"  justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
        </template>
    </v-img>
</template>
<script>
import FileUploadAPI from '@/apis/FileUploadAPI';
export default {
    mounted() {
        this.startImageLoading();
    },
    props: [
        'src',      // 画像のURL（最優先）
        'imageId',  // イメージID（src未設定の時に利用される）
        'noImage',  // ソース未設定の時にNoImageを表示するか true/false
        'height',
        'maxHeight',
        'width',
        'maxWidth',
    ],

    watch:{
        'src':function(val){
            this.startImageLoading();
        },
        'imageId':function(val){
            this.startImageLoading();
        },
    },

    data() {
        return {

            // srcを直接imgでロードする時に設定されるフラグ
            isBasicLoading:false,

            // 画像をロードする時に設定されるフラグ(ajaxも含む)
            loading:true,

            // ロード処理中のSRC
            processingSrc:"",

            imageSrc : "",

            //val fileId:Long,val fileName:String,val contentType:String,val downloadUrl:String
            downloadUrl:"",

        }
    },

    computed:{

    },

    methods: {


        startImageLoading(){

            let self = this;

            let urlProc = this.getImageUrl();
            if (urlProc == null){
                return;
            }

            urlProc.then(url => {

                console.log("urlProc url");
                console.log(url);

                if (!url){
                    self.loading = false;
                    self.isBasicLoading = false;
                    self.processingSrc = null;
                    self.imageSrc = null;
                } else {

                    if (self.processingSrc == url){
                        return;
                    }

                    self.processingSrc = url;
                    self.isBasicLoading = true;
                    self.loading = true;

                    self.fireStartLoadingEvent();
                    self.imageSrc = url;

                }

            });
        },

        getImageUrl(){
            let self = this;
            self.downloadUrl = "";

            if (this.src){
                return new Promise(function(resolve, reject){
                    self.downloadUrl = self.src;
                    resolve(self.downloadUrl);
                });
            }
            else if (this.imageId){
                return FileUploadAPI.getDownloadUrl(this.imageId)
                    .then(a => {
                        self.downloadUrl = a;
                        console.log("image " + self.imageId + " download url " + a);
                        return self.downloadUrl;
                    });
            }
            else return null;
        },


        onImageLoad(event){
            console.log("image loaded.");
            this.loading = false;
            this.fireFinishLoadingEvent();
        },

        onImageError(event){
            console.log("image loading failed.");
            console.log(this.downloadUrl);
            if (this.isBasicLoading && !!this.processingSrc){
                // 読み込みエラーになったらAjaxでロードする
                //this.ajaxLoadImage(1000);
            }
        },


        ajaxLoadImage(delay){
            this.isBasicLoading = false;
            this.loading = true;
            this.fireStartLoadingEvent();

            let vm = this;
            let srcUrl = this.processingSrc;

            this.$http.get(srcUrl, { responseType : 'blob' }).then(response => {

                if (vm.processingSrc === srcUrl){
                    vm.loading = false;

                    let blob = response.data;
                    let url = window.URL || window.webkitURL;
                    vm.imageSrc = url.createObjectURL(blob);
                }

            }).catch(e => {
                if (e.response){
                    if (e.response.status == 409){
                        // 409の時は再読み込みする
                        setTimeout( function(){
                            vm.ajaxLoadImage(delay);
                        },delay);
                        return;
                    }
                }
                // この時は存在しない画像だと判断する
                console.error(e);
            })
        },

        fireStartLoadingEvent(){
            this.$emit('start',this.isBasicLoading);
        },
        fireFinishLoadingEvent(){
            this.$emit('finish');
        },
    },

}
</script>