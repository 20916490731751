<template>
    <v-container fluid fill-height class="login"> <!-- fill-height -->

        <v-container>

            <v-layout wrap justify-center>

                <v-card class="elevation-1 pa-3 login-card">
                    <v-card-text>
                        <!--<div class="layout column align-center">
                            <h1 class="flex my-4 primary--text font-weight-bold">ログイン</h1>
                        </div>-->
                        <v-form ref="loginForm">
                            <v-text-field
                                    append-icon="person"
                                    name="login"
                                    label="ユーザID"
                                    type="text"
                                    v-model="model.username"
                                    required
                                    maxlength="30"
                                    id="userNameField"
                                    placeholder=" "
                            ></v-text-field>
                            <v-text-field
                                    append-icon="lock"
                                    name="password"
                                    label="パスワード"
                                    type="password"
                                    v-model="model.password"
                                    :rules="passwordRules"
                                    required
                                    maxlength="32"
                                    id="passwordField"
                                    placeholder=" "
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <div class="login-btn">
                        <v-btn block color="primary" @click="login" :loading="loading" :id="'loginBtn'">ログイン</v-btn>
                    </div>
                </v-card>

            </v-layout>
        </v-container>

    </v-container>

</template>
<script>
import Authentication from '../authentication'
import Common from '@/components/Common';
import LoginSupport from "../components/LoginSupport";

export default {
    mixins:[Common,LoginSupport],
    mounted () {
        let self = this;


        /*
        this.$nextTick(function () {
            console.log("username → " + self.model.username);
            //console.log(self.$refs.loginBtn);

        });


        setTimeout(function() {

            //self.$refs.passwordField.
            console.log("username → " + self.model.username);
            document.getElementById("passwordField").focus();
        }, 250);*/
    },
    props: ['propSSO','propBackuri'],
    data: () => ({
        loading: false,
        /*emailRules: [
            v => !!v || "メールアドレスは必須項目です。",
            v => (v && v.length <= 128) || "メールアドレスは128文字以内で入力してください。",
            v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "メールアドレスの形式が正しくありません。"
        ],*/
        passwordRules: [
            v => !!v || "パスワードは必須項目です。",
            v => (v && v.length <= 32) || "パスワードは32文字以内で入力してください。"
        ],
        model: {
            username: "",
            password: ""
        }
    }),

    methods: {
        login() {

            let self = this;

            // バリデーションが通った場合
            if (this.$refs.loginForm.validate()) {
                // ぐるぐる表示にしてボタンを二度押しできなくする
                this.loading = true;

                let username = this.model.username;
                let password = this.model.password;

                console.log("ログインを行います。" + username);

                Authentication.signIn(username,password)
                    .then(data => {
                        console.log("認証に成功しました " + username + " sso:"+ self.propSSO);

                        if (!self.propSSO){
                            // Apiへ認証情報を設定
                            self.setupApiAuthorization(data);

                            // アプリケーションへログイン
                            self.appLoginFlow(data)
                                .then(a => {
                                    self.loading = false;
                                    self.$eventHub.$emit('signin',{});
                                    self.$router.push({path: "backuri" in self.$route.query && self.$route.query.backuri.match(/^\//) ? self.$route.query.backuri : '/'})
                                })
                                .catch(err => {
                                    console.log(err);
                                    console.log("アプリケーションへログインできませんでした");
                                    self.loading = false;
                                    self.showErrorToast("ログインできませんでした。利用権限が設定されていない可能性があります。");
                                });
                        } else {
                            // 他のアプリ向けのログイン
                            console.log("呼び出し元サイトへリダイレクトします。" + self.propBackuri);
                            location.href='' + self.propBackuri;
                        }

                    })
                    .catch(err => {
                        console.log(err);
                        console.log("ログインできませんでした");
                        if (err.code === "NotAuthorizedException"){
                            self.showErrorToast("ログインできませんでした。正しいユーザID、パスワードを入力してください。");
                        } else {
                            self.showErrorToast("ログインできませんでした。");
                        }
                        self.loading = false;
                    })


            }
        }
    }
}
</script>