<script>
import { mapState } from "vuex";
import Authentication from "../authentication";
import BasicInfoAPI from "@/apis/BasicInfoAPI";
import UserAPI from "@/apis/UserAPI";

export default {
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["account"]),
  },
  methods: {
    // ----------------------------------
    // ログイン状態の制御
    // ----------------------------------

    // ヘッダへapi呼び出しのための認証情報を設定する
    setupApiAuthorization(data) {
      if (data) {
        // ヘッダへidTokenを設定
        this.$http.defaults.headers.common["Authorization"] = data.idToken;
        this.$store.commit("setApiAuthorization", data);
      } else {
        this.$http.defaults.headers.common["Authorization"] = "";
        this.$store.commit("setApiAuthorization", null);
      }
    },

    // cognitoログインされた後、アプリケーションへのログインを行う
    // data - congnitoログインから得た戻り値
    appLoginFlow(data) {
      let self = this;

      return Promise.resolve()
        .then((a) => {
          return UserAPI.myProfile().then((response) => {
            let data = response.data;

            // storeにprofileを保存する
            this.$store.commit("setProfile", data);

            // GAに送信
            if (data.user) {
              //gtag('config', process.env.VUE_APP_TRACKING_ID, {'staff_cd': data.user.staff_cd,'user_id':data.user.user_id});
              //console.log("GAに担当者情報を送信しました。" + data.user.staff_cd + " " + data.user.user_id)
            }
          });
        })
        .then((response) => {
          // 基本情報を取得する
          return self.storeBasicDataAfterLogin();
        });
    },

    // 強制的にトークンのリフレッシュを行う
    refreshToken() {
      /*
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
                console.log('session', err, session);
                const { idToken, refreshToken, accessToken } = session;
                // do whatever you want to do now :)
            });*/
    },

    // ----------------------------------
    // ログイン状態の検証
    // ----------------------------------
    checkAuthToken() {
      let self = this;
      return Authentication.checkLogin().then((data) => {
        // APIの認証情報を設定する
        self.setupApiAuthorization(data);
      });
    },

    // ----------------------------------
    // ログイン状態の制御
    // ----------------------------------

    // 基本情報を取得するPromiseを返す
    storeBasicDataAfterLogin() {
      let self = this;

      let taskGroup = BasicInfoAPI.listGroup().then((a) => {
        //console.log(a);
        self.$store.commit("setGroups", a.data);
      });

      let taskUsers = BasicInfoAPI.listUser().then((a) => {
        //console.log(a);
        self.$store.commit("setUsers", a.data);
      });

      let taskEmotes = BasicInfoAPI.listEmote().then((a) => {
        console.log(a);
        self.$store.commit("setEmotes", a.data);
      });

      return Promise.all([taskGroup, taskUsers, taskEmotes]);
    },
  },
};
</script>
