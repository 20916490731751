import { render, staticRenderFns } from "./EmoteIconBase.vue?vue&type=template&id=04b275f4&scoped=true"
import script from "./EmoteIconBase.vue?vue&type=script&lang=js"
export * from "./EmoteIconBase.vue?vue&type=script&lang=js"
import style0 from "./EmoteIconBase.vue?vue&type=style&index=0&id=04b275f4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b275f4",
  null
  
)

export default component.exports