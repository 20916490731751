<template>
    <div class="d-flex align-center">
        <div style="width:8rem">
            <v-text-field
                    v-model="inputTime1"
                    :label="label"
                    prepend-icon="access_time"
                    readonly
            ></v-text-field>
        </div>
        <div>:</div>
        <div>
            <v-text-field
                    v-model="inputTime2"
                    readonly
            ></v-text-field>
        </div>

    </div>
</template>

<script>
export default {
    name: "TimeInputField",
    mounted: function() {
        this.refId = this.makeRefId();
        this.inputTime = this.value;
    },
    watch: {
        value() {
            let a = this.value;
            if (this.inputTime !== this.value){
                if (a){
                    let b = a.split(":");
                    this.inputTime1 = b[0];
                    this.inputTime2 = b[2];
                }
            }
        }
    },
    props: {
        label:"",
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            redId:"",
            menuFlg:false,
            inputTime1:null,
            inputTime2:null,
        }
    },
    computed: {
        inputTime(){
            return this.inputTime1 + ":" + this.inputTime2;
        },
    },
    methods: {
        makeRefId(){
            // 生成する文字列の長さ
            let l = 8;
            // 生成する文字列に含める文字セット
            let c = "abcdefghijklmnopqrstuvwxyz0123456789";
            let cl = c.length;
            let r = "";
            for(let i=0; i<l; i++){
                r += c[Math.floor(Math.random()*cl)];
            }
            return "timef_" + r;
        }
    },
    updated: function() {
        this.$emit('input', this.inputTime)
    },
}
</script>

<style scoped>

</style>