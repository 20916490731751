<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         :viewBox="viewport"
         :aria-labelledby="iconName"
         role="presentation"
    >
        <title :id="iconName"> {{iconName}} icon </title>
        <circle :cx="width / 2" :cy="height / 2" :r="width / 2" :fill="bgColor"/>
        <g :fill="iconColor" :transform="iconTransform">
            <slot />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        iconName: {
            type: String,
            default: 'box'
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        },
        bgColor: {
            type: String,
            default: 'currentColor'
        },
        iconMargin:{
            type: [Number, String],
            default: 3
        },
    },
    computed:{

        viewport:function(){
            return "0 0 " + this.width + " " + this.height
        },

        iconTransform:function(){
            let iw = this.width - this.iconMargin * 2;
            let ih = this.height - this.iconMargin * 2;

            let sw = iw / this.width;
            let sh = ih / this.height;

            let t = "translate(" + this.iconMargin + "," + this.iconMargin + ")";
            let s = "scale(" + sw + "," + sh + ")";
            return s + " " + t;
        }
    }
}
</script>

<style scoped>
    svg {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: -2px; /* yes, I'm that particular about formatting */
    }
</style>