<template>
  <v-container fluid class="timeline"> <!-- <v-fade-transition mode="out-in"> -->

    <!--<div><span class="title">Timeline</span></div>-->

    <v-expansion-panels  v-model="isFilterExpand">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">


            <v-fade-transition leave-absolute>
                <span v-if="open" key="0">
                  条件を選択してください。
                </span>
                <span v-else  key="1">
                  <span v-if="!!filterName">{{ filterName }}</span>
                  <span v-else>フィルタ</span>
                </span>
            </v-fade-transition>

          </template>

        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-container>
            <v-row>
              <v-col class="py-0 my-0" cols="12">
                <header>重要度</header>
              </v-col>
              <v-col class="py-0 my-0">
                <v-radio-group class="py-0 my-0" v-model="filter.importance" row>
                  <v-radio label="全て" value="0" />
                  <v-radio label="重要以上" color="blue" value="1" />
                  <v-radio label="最重要以上" color="orange" value="2" />
                  <v-radio label="緊急" color="red" value="3" />
                </v-radio-group>
              </v-col>

              <v-col class="py-0 my-0"  cols="12" dense>
                <header>状況</header>
              </v-col>
              <v-col class="py-0 my-0" cols="12" dense>
                <v-container class="py-0 my-0">
                  <v-row class="py-0 my-0" justify="start"><!-- space-around -->
                    <v-checkbox class="py-0 my-0 mr-3" v-model="filter.goodOrBad" label="通常" value="N"/>
                    <v-checkbox class="py-0 my-0 mr-3" v-model="filter.goodOrBad" label="GoodNews" value="G"/>
                    <v-checkbox class="py-0 my-0" v-model="filter.goodOrBad" label="BadNews" value="B"/>
                  </v-row>
                </v-container>
              </v-col>

            </v-row>

            <v-row>
              <v-col class="py-0 my-0"  cols="12" dense>
                <header>グループ</header>
              </v-col>
              <v-col class="py-0 my-0" cols="12" dense>

                <v-container class="py-0 my-0">
                  <v-row class="py-0 my-0" justify="start">
                    <v-switch
                            v-model="filter.useGroups"
                            label="グループを選択する"
                    />
                  </v-row>
                </v-container>

                <v-container v-if="filter.useGroups" class="py-0 my-0">
                  <v-row>
                    <v-btn class="ml-3 mb-2" x-small elevation="1" v-on:click="selectAllGroups">全選択</v-btn>
                    <v-btn class="ml-1 mb-2" x-small elevation="1" v-on:click="deselectAllGroups">選択解除</v-btn>
                  </v-row>
                  <v-row class="py-0 my-0" justify="start">
                    <v-checkbox v-for="(grp,idx) in followGroups" :key="grp.following_group_cd" class="py-0 my-0 mr-3" v-model="filter.groups" :label="grp.following_group_name" :value="grp.following_group_cd"/>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>

            <v-row>
              <v-btn v-on:click="onApplyFilter">適用</v-btn>
            </v-row>
          </v-container>


        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <v-layout wrap> <!-- text-center -->

      <v-container class="" v-for="(dateData,idx) in list" :key="dateData.date + '_' + dateData.new_arrivals">

        <h3>
          <span v-if="dateData.new_arrivals"><v-icon class="mr-2" color="red lighten-1">fiber_new</v-icon> </span>
          <span>{{formatDateToHeaderDisplay(dateData.date)}}</span>
        </h3>

        <v-card v-for="userEntry in dateData.userEntries" :key="userEntry.staff_cd" class="pa-3 mt-3">

          <v-list-item>
            <ProfileImageView :staff-cd="userEntry.staff_cd"></ProfileImageView>
            <v-list-item-content class="ml-3">
              <v-list-item-title class="headline">{{userEntry.user_name}}</v-list-item-title>
              <v-list-item-subtitle>{{userEntry.group_name}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-for="(entry,idx) in userEntry.entries">

            <!-- 次のエントリの読み込み用 -->
            <IntersectionObserver v-if="entry.isLastEntry" @intersect="onLastEntryIntersected(entry)"/>

            <v-divider v-if="idx>0"/>

            <!-- 日報の表示 -->
            <ReportDetail :report-id="entry.reportId"/>

            <!-- A,B共通のツールボックスとコメント-->
            <ReportComments :report-id="entry.reportId" />

            <!-- 既読処理 自動ロードが有効な場合に有効とする -->
            <IntersectionObserver v-if="useAutoRead && !entry.read_event_fired" @intersect="onEntryIntersected(entry)"/>

            <!-- 転送の履歴 転送可能な人だけ表示 -->
            <ReportForwards :report-id="entry.reportId" />

          </template>

        </v-card>

      </v-container>

      <loading-view :loading="loading"/>


    </v-layout>

  </v-container>

</template>

<script>
import Common from '@/components/Common';
import IntersectionObserver from '@/components/IntersectionObserver';
import UserSelectField from "@/components/controls/UserSelectField";
import TimelineAPI from '@/apis/TimelineAPI';
import FollowAPI from '@/apis/FollowAPI'
import ProfileImageView from "../components/controls/ProfileImageView";
import ReportViewSupport from "../components/ReportViewSupport";
import ReportComments from "./sub/ReportComments";
import ReportForwards from "./sub/ReportForwards";
import ReportDetail from "./sub/ReportDetail";

export default {
    mixins:[Common,ReportViewSupport],
    components : {
      IntersectionObserver,UserSelectField,ProfileImageView,
      ReportComments,ReportForwards,ReportDetail
    },
    mounted: function() {

      this.list.splice(0);

      // イベントハブのリッスンを開始
      this.$eventHub.$off('reload', this.onReload);
      this.$eventHub.$on('reload', this.onReload);

      this.setupConditions();
    },
    destroyed() {
      this.$eventHub.$off('reload', this.onReload);
    },
    props: [],
    watch: {
      '$route':function (to, from) {
        this.setupConditions();
      },
    },
    data() {
      return {
        loading:false,
        timelineNo:0,
        list:[
          {
            date:"2019年8月20日",
            new_arrivals:true,

            userEntries:[
              {
                staff_cd:"",
                user_name:"森康 太郎",
                group_name:"改革室",
                name_initial:"TM",
                entries:[
                ],
              },

            ],

          },

        ],

        isFilterExpand:null,
        filter:{
          importance:"0",
          goodOrBad:["N","G","B"],
          useGroups:false,
          groups:[],
        },
        filterName:"",

        followGroups:[
          {
            following_group_cd:"",
            following_group_name:"",
          }
        ],

        lastIndex:"",
        // -- コメント情報
        commentDlgDisplay:false,
        commentDlgData:{
          entity:{},
          content:"",
          comment_id:null,
        },
        // -- リプライ情報
        replyDlgDisplay:false,
        replyDlgData:{
          entity:{},
          content:"",
          reply_id:null,
        },
        emoteModel:null,
        // -- 人へ転送
        forwardDlgDisplay:false,
        forwardDlgData:{
          staff_cd:"",
          entity:{},
          isValid:false,
        },
        forwardCommentRules: [
          v => !!v || 'コメントは必須項目です',
        ],
      }
    },
    methods: {

      setupConditions(){

        this.fetchData(true);

      },

      fetchData(needDataFetch){
        let self = this;

        if (!this.isInitialized){
          this.fetchBasicData(function () {
            self.fetchData(needDataFetch)
          });
          return;
        }

        if (needDataFetch){
          this.list.splice(0);
          this.lastIndex = "";
          this.fetchFromFirst();
        }

      },

      fetchFromFirst(){

        let self = this;
        this.loading = true;

        this.lastIndex = "";
        this.timelineNo = "";

        let isAllGroup = this.checkAllGroupSelected();

        let condition = {
          staffCd:null,
          importance:this.filter.importance,
          goodOrBad:this.filter.goodOrBad,
          groups:null,
        };

        if (!isAllGroup){
          condition.groups = this.filter.groups;
        }


        Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return TimelineAPI.issueTimelineNo(condition) })
                .then(issueResponse => {
                  let timelineNo = issueResponse.data;
                  self.timelineNo = timelineNo;
                  return TimelineAPI.listEntries(timelineNo,self.lastIndex)
                })
                .then(response => {
                  self.makeDisplayData(response.data);
                  self.loading = false;
                })
                .catch(function(err) {
                  console.log(err);
                  self.loading = false;
                  self.showErrorToast("データの取得に失敗しました。");
                });

      },

      fetchNext(){

        let self = this;
        this.loading = true;

        let timelineNo = this.timelineNo;

        Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return TimelineAPI.listEntries(timelineNo,this.lastIndex) })
                .then(response => {
                  self.makeDisplayData(response.data);
                  self.loading = false;
                })
                .catch(function(err) {
                  console.log(err);
                  self.loading = false;
                  self.showErrorToast("データの取得に失敗しました。");
                });
      },

      fetchBasicData(finishProc){

        let self = this;
        this.loading = true;

        this.followGroups.splice(0);

        // フォロー状況を求める
        Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return FollowAPI.list()})
                .then(response => {

                  response.data.followGroups.forEach(a => {
                    self.followGroups.push({
                      following_group_cd:a.following_group_cd,
                      following_group_name:self.getGroupName(a.following_group_cd),
                    });
                  });

                  self.selectAllGroups();

                  self.isInitialized = true;
                  if (finishProc){
                    finishProc();
                  }
                })
                .catch(function(err) {
                  console.log(err);
                  self.loading = false;
                  self.showErrorToast("データの取得に失敗しました。");
                });

      },

      // リロード処理
      onReload(){
        this.list.splice(0);
        this.lastIndex = "";
        this.fetchFromFirst();
      },


      //--------------------------------------------
      // 表示用データ生成
      //--------------------------------------------

      makeDisplayData(data){

        let self = this;

        //console.log(data);

        let canForward = this.hasRole("CAN_REPORT_FORWARD");

        let dateRecords = data.records;
        this.lastIndex = data.lastIndex;

        // 基本的には後ろに追加していく
        // でも日付レコードが存在していたらそのレコードに追加する

        let lastEntry = null;

        dateRecords.forEach(srcDateRec => {
          let isNew = srcDateRec.isNew;
          let reportDate = srcDateRec.dt;

          //console.log("date: " + reportDate);

          let dateRec = {
            date:reportDate,
            new_arrivals:isNew,
            userEntries:[]
          };

          srcDateRec.records.forEach(srcUserRec => {

            //console.log("  user: " + srcUserRec.user.staff_cd);
            let userRec = {
              staff_cd:srcUserRec.user.staff_cd,
              user_name:srcUserRec.user.name,
              group_cd:srcUserRec.user.group_cd,
              group_name:this.getGroupName(srcUserRec.user.group_cd),
              name_initial:"HK",
              entries:[],
            };

            let canEdit = (srcUserRec.user.staff_cd === self.account.staff_cd);


            srcUserRec.records.forEach(entrySet => {

              let reportId = entrySet.report.id;

              let reportEntry = self.makeReportDisplayData(entrySet);

              // vuex.stateに日報データを保存
              self.$store.commit('setReport', reportEntry);

              // entriesにはキー情報 + 制御情報を保持する
              let needReadEvent = !reportEntry.already_read || reportEntry.updated;
              let entry = {
                reportId:reportId,
                read_event_fired:!needReadEvent,
                isLastEntry:false,
              };
              lastEntry = entry;

              userRec.entries.push(entry);
            });

            dateRec.userEntries.push(userRec)

          });

          // 同じ日付&新着フラグが存在しているか？
          if (this.list.length > 0){
            let lastDateRec = this.list[this.list.length -1];
            if (lastDateRec.date === reportDate && lastDateRec.new_arrivals === isNew){
              dateRec.userEntries.forEach(a => lastDateRec.userEntries.push(a));
              dateRec = null;
            }
          }

          if (dateRec){
            this.list.push(dateRec);
          }

        });

        if (lastEntry){
          lastEntry.isLastEntry = true;
        }

      },

      //--------------------------------------------
      // フィルタの制御
      //--------------------------------------------

      onApplyFilter(){
        this.updateFilterName();
        this.isFilterExpand = null;

        this.list.splice(0);
        this.lastIndex = "";
        this.fetchFromFirst();
      },

      selectAllGroups(){

        this.filter.groups.splice(0);
        this.followGroups.forEach(a => {
          this.filter.groups.push(a.following_group_cd);
        });

      },

      deselectAllGroups(){
        this.filter.groups.splice(0);
      },

      updateFilterName(){

        let isAllGroup = this.checkAllGroupSelected();

        let result = "";

        let impText = "";
        let imp = Number(this.filter.importance);
        if (imp === 1) impText = " 重要以上 ";
        if (imp === 2) impText = " 最重要以上 ";
        if (imp === 3) impText = " 緊急 ";
        if (impText){
          if (result) result += " 、 ";
          result += "重要度:" + impText;
        }

        let gob = this.filter.goodOrBad;
        if (gob.length === 0 || gob.length === 3){
        } else {
          let gobText = "";
          gob.forEach(g => {
            if (gobText) gobText += ",";
            if (g === "N") gobText += "通常";
            if (g === "G") gobText += "GoodNews";
            if (g === "B") gobText += "BadNews";
          });
          if (result) result += " 、 ";
          result += "種別:" + gobText;
        }


        if (!isAllGroup) {
          if (result) result += " 、 ";
          result += "" + this.filter.groups.length + " グループ選択";
        }


        this.filterName = result;
      },

      checkAllGroupSelected(){

        if (this.filter.groups.length === 0){
          return true;
        }

        if (!this.filter.useGroups){
          return true;
        }

        let m = {};
        this.followGroups.forEach(a => {
          m[a.following_group_cd] = true;
        });

        this.filter.groups.forEach(a => {
          m[a.following_group_cd] = false;
        });

        let idx = Object.keys(m).findIndex(key => !!m[key]);

        return idx === -1;
      },

      //--------------------------------------------
      // 既読処理
      //--------------------------------------------

      onEntryIntersected(entry){
        //console.log("onEntryIntersected");
        //console.log(reportEntry);

        let self = this;

        let reportEntry = this.reportMap[entry.reportId];
        // 既読設定を行う
        if (!!reportEntry && this.useAutoRead){

          if (!reportEntry.cancel_auto_read){

            if (!reportEntry.already_read || reportEntry.updated){
              this.setAlreadyRead(reportEntry.id,1000);
            }

          }

          entry.read_event_fired = true;

        }

      },

      onLastEntryIntersected(entry){

        // 最後のエントリーが表示されたら次のエントリーを読み込む
        if (entry.isLastEntry){
          entry.isLastEntry = false;
          this.fetchNext()
        }

      },

    }
}
</script>
<style lang="scss">
  .color-success {
    color:#A4C639;
  }
</style>