<template>
  <v-container class="report_entry">
    <!-- 登録されたEmoteの表示 -->
    <div class="report_emotes d-flex align-baseline">
      <template v-if="!!entry.emote && entry.emote.emote_count > 0">
        <span v-for="em in entry.emote.emotes" :key="em">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span class="emote_icon" :class="'type' + em" v-on="on">
                <img :src="'/icons/emote' + em + '.svg'" />
              </span>
            </template>

            <template
              v-if="!!entry.emote.popup_data && !!entry.emote.popup_data[em]"
            >
              <template
                v-for="(name, index) in entry.emote.popup_data[em].staff_names"
              >
                <br v-if="index > 0" />
                <span>{{ name }}</span>
              </template>
            </template>
          </v-tooltip>
        </span>

        <span> {{ entry.emote.emote_count }}件 </span>
      </template>
    </div>

    <div class="report_tools mt-2 mb-2">
      <!-- いいねボタン -->
      <v-menu v-if="canEmote" open-on-hover top offset-y content-class="round">
        <template v-slot:activator="{ on }">
          <a v-on="on"> <v-icon>thumb_up_alt</v-icon>いいね！　 </a>
        </template>

        <EmoteSelectorSheet
          :emotes="emotes"
          :target-object="entry"
          v-on:select="onEmoteBtnClick"
        ></EmoteSelectorSheet>
      </v-menu>

      <!-- コメントボタン -->
      <a
        v-if="canComment && useReportComment"
        v-on:click.prevent="onCommentBtnClick(entry, null)"
      >
        <v-icon>sms</v-icon>コメント　</a
      >
      <!-- 転送ボタン -->
      <a
        v-if="canForward && useReportForward"
        v-on:click.prevent="onForwardBtnClick(entry)"
      >
        <v-icon>share</v-icon>転送
      </a>
    </div>

    <div v-for="comment in entry.comments" class="report_comment">
      <!-- コメント -->
      <div class="d-flex">
        <ProfileImageView
          :staff-cd="comment.staff_cd"
          :size="28"
        ></ProfileImageView>

        <v-sheet color="blue-grey lighten-5 pa-3 ml-3">
          <div class="report_commenter font-weight-bold subtitle-2">
            {{ comment.user_name }}
            <span class="comment_time"
              >( {{ formatDateTimeToDisplay(comment.time) }} )</span
            >
            <v-icon
              class="ml-3"
              v-if="comment.can_edit"
              v-on:click="onCommentBtnClick(entry, comment)"
              >edit</v-icon
            >
          </div>
          <div class="comment_content">
            <template v-for="pc in comment.parsedContent">
              <span v-if="pc.type === 'text'">{{ pc.value }}</span>
              <a v-if="pc.type === 'url'" :href="pc.value" target="_blank">{{
                pc.value
              }}</a>
            </template>
          </div>

          <v-container class="pa-0 ma-0">
            <v-row class="pt-2 pr-2 pl-3">
              <!-- コメントへのいいねボタン -->
              <v-menu open-on-hover top offset-y content-class="round">
                <template v-slot:activator="{ on }">
                  <a v-on="on">
                    <v-icon class="pr-6">thumb_up_alt</v-icon>
                  </a>
                </template>
                <EmoteSelectorSheet
                  :emotes="emotes"
                  :target-object="comment"
                  v-on:select="onCommentEmoteBtnClick"
                ></EmoteSelectorSheet>
              </v-menu>

              <div class="flex-grow-1"></div>

              <!-- 登録されたEmoteの表示 -->
              <div
                class="report_emotes"
                v-if="!!comment.emote && comment.emote.emote_count > 0"
              >
                <span v-for="em in comment.emote.emotes" :key="em">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span
                        class="emote_icon small"
                        :class="'type' + em"
                        v-on="on"
                      >
                        <img :src="'/icons/emote' + em + '.svg'" />
                      </span>
                    </template>

                    <template
                      v-if="
                        !!comment.emote.popup_data &&
                        !!comment.emote.popup_data[em]
                      "
                    >
                      <template
                        v-for="(name, index) in comment.emote.popup_data[em]
                          .staff_names"
                      >
                        <br v-if="index > 0" />
                        <span>{{ name }}</span>
                      </template>
                    </template>
                  </v-tooltip>
                </span>

                <span class="caption"> {{ comment.emote.emote_count }}件 </span>
              </div>
            </v-row>
          </v-container>
        </v-sheet>
      </div>

      <!-- コメントへの返信 -->
      <div v-for="reply in comment.replies" class="comment_reply d-flex">
        <ProfileImageView
          :staff-cd="reply.staff_cd"
          :size="28"
        ></ProfileImageView>
        <v-sheet color="blue-grey lighten-5 pa-3 ml-3">
          <div class="font-weight-bold subtitle-2">
            {{ reply.user_name }} ( {{ formatDateTimeToDisplay(reply.time) }} )
            <v-icon
              class="ml-3"
              v-if="reply.can_edit"
              v-on:click="onReplyBtnClick(comment, reply)"
              >edit</v-icon
            >
          </div>
          <div class="reply_content">
            <template v-for="pc in reply.parsedContent">
              <span v-if="pc.type === 'text'">{{ pc.value }}</span>
              <a v-if="pc.type === 'url'" :href="pc.value" target="_blank">{{
                pc.value
              }}</a>
            </template>
          </div>

          <v-container class="pa-0 ma-0">
            <v-row class="pt-2 pr-2 pl-3">
              <!-- 返信へのいいねボタン -->
              <v-menu open-on-hover top offset-y content-class="round">
                <template v-slot:activator="{ on }">
                  <a v-on="on">
                    <v-icon class="pr-6">thumb_up_alt</v-icon>
                  </a>
                </template>
                <EmoteSelectorSheet
                  :emotes="emotes"
                  :target-object="reply"
                  v-on:select="onReplyEmoteBtnClick"
                ></EmoteSelectorSheet>
              </v-menu>

              <div class="flex-grow-1"></div>

              <!-- 登録されたEmoteの表示 -->
              <div
                class="report_emotes"
                v-if="!!reply.emote && reply.emote.emote_count > 0"
              >
                <span v-for="em in reply.emote.emotes" :key="em">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <span
                        class="emote_icon small"
                        :class="'type' + em"
                        v-on="on"
                      >
                        <img :src="'/icons/emote' + em + '.svg'" />
                      </span>
                    </template>

                    <template
                      v-if="
                        !!reply.emote.popup_data && !!reply.emote.popup_data[em]
                      "
                    >
                      <template
                        v-for="(name, index) in reply.emote.popup_data[em]
                          .staff_names"
                      >
                        <br v-if="index > 0" />
                        <span>{{ name }}</span>
                      </template>
                    </template>
                  </v-tooltip>
                </span>

                <span class="caption"> {{ reply.emote.emote_count }}件 </span>
              </div>
            </v-row>
          </v-container>
        </v-sheet>
      </div>

      <div class="comment_reply_tools">
        <a v-on:click.prevent="onReplyBtnClick(comment)"
          ><v-icon>sms</v-icon>返信</a
        >
      </div>
    </div>

    <div class="d-flex flex-wrap align-baseline mt-5 report-footer">
      <template v-if="!!entry.readsStaffs && entry.totalReads > 0">
        <!-- 既読数の表示 -->
        <div v-if="entry.readsStaffs.length === 0" class="ml-3 report_read">
          {{ entry.totalReads }}人が読みました
        </div>

        <v-tooltip top v-if="entry.readsStaffs.length > 0">
          <template v-slot:activator="{ on }">
            <!-- ml-auto caption-->
            <div class="ml-3 report_read" v-on="on">
              <span v-if="$vuetify.breakpoint.smAndUp"
                >{{ entry.totalReads }}人が読みました</span
              >
              <span v-else>{{ entry.totalReads }}人既読</span>

              <!--<span style="display:none" v-for="(staffName,index) in entry.readsStaffs">{{index}}:{{staffName}}</span>-->
            </div>
          </template>

          <template v-for="(staffName, index) in entry.readsStaffs">
            <br v-if="index > 0" />
            <span>{{ staffName }}</span>
          </template>
        </v-tooltip>
      </template>

      <div class="report_read ml-3">
        <v-chip small v-if="!entry.already_read" color="amber darken-4" outlined
          >未読</v-chip
        >
        <v-chip
          small
          v-if="entry.already_read && entry.updated"
          color="green darken-2"
          outlined
          >更新</v-chip
        >
        <v-chip small v-if="entry.already_read && !entry.updated" outlined
          >既読</v-chip
        >

        <v-btn
          v-if="!useAutoRead && (!entry.already_read || entry.updated)"
          small
          dark
          class="pa-1 ml-3"
          color="primary"
          v-on:click="onReadBtnClick"
          >既読とする</v-btn
        >
        <v-btn
          v-if="entry.already_read && !entry.updated && !entry.is_self_report"
          small
          class="pa-1 ml-3"
          v-on:click="onUnReadBtnClick"
          >未読とする</v-btn
        >
        <a
          v-if="entry.can_edit"
          v-on:click.prevent="onEditReportClick(entry)"
          class="pl-6"
          ><v-icon>edit</v-icon></a
        >
      </div>

      <div class="bookmark">
        <a v-on:click.prevent="onBookmarkBtnClick">
          <v-icon v-if="entry.is_bookmarked" large color="yellow darken-2"
            >star</v-icon
          >
          <v-icon v-else large>star</v-icon>
        </a>
      </div>
    </div>
  </v-container>
</template>
<script>
import Common from "@/components/Common";
import ProfileImageView from "../../components/controls/ProfileImageView";
import ReportViewSupport from "../../components/ReportViewSupport";
import EmoteSelectorSheet from "./EmoteSelectorSheet";
import ReportEmoteAPI from "@/apis/ReportEmoteAPI";
import BookmarkAPI from "@/apis/BookmarkAPI";

export default {
  mixins: [Common, ReportViewSupport],
  components: {
    ProfileImageView,
    EmoteSelectorSheet,
  },
  props: {
    reportId: {
      type: [String, Number],
      required: true,
    },
  },
  mounted: function () {},
  destroyed() {},
  data() {
    return {
      emoteModel: false,
    };
  },
  computed: {
    canEmote() {
      return !this.entry.can_edit;
    },
    canComment() {
      return !this.entry.can_edit;
    },
    canForward() {
      return this.entry.can_forward;
    },
    entry() {
      return this.$store.state.reports[this.reportId];
    },
  },
  methods: {
    //fireCommentEvent(){
    //    this.$emit('comment',data);
    //},

    //--------------------------------------------
    // いいね選択処理
    //--------------------------------------------

    onEmoteBtnClick(data) {
      let reportEntry = data.targetObject;
      let emoteCd = data.emote;

      let self = this;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportEmoteAPI.putEmote(reportEntry.id, emoteCd);
        })
        .then((response) => {
          let a = self.makeEmoteDisplayData(response.data);

          self.$store.commit("setReportEmote", {
            reportId: this.reportId,
            data: a,
          });
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの登録に失敗しました。");
        });
    },

    onCommentEmoteBtnClick(data) {
      //console.log("ReportComments.vue onCommentEmoteBtnClick called.");
      //console.log(data);

      let commentEntry = data.targetObject;
      let emoteCd = data.emote;

      let self = this;

      let commentId = commentEntry.comment_id;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportEmoteAPI.putCommentEmote(commentId, emoteCd);
        })
        .then((response) => {
          let a = self.makeEmoteDisplayData(response.data);

          self.$store.commit("setReportCommentEmote", {
            reportId: this.reportId,
            commentId: commentId,
            data: a,
          });
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの登録に失敗しました。");
        });
    },

    onReplyEmoteBtnClick(data) {
      //console.log("ReportComments.vue onReplyEmoteBtnClick called.");
      //console.log(data);

      let replyEntry = data.targetObject;
      let emoteCd = data.emote;

      let self = this;
      let commentId = replyEntry.comment_id;
      let replyId = replyEntry.reply_id;

      Promise.resolve()
        .then((a) => {
          return this.checkAuthToken();
        })
        .then((a) => {
          return ReportEmoteAPI.putReplyEmote(commentId, replyId, emoteCd);
        })
        .then((response) => {
          //console.log("save reply emote");
          //console.log(response);
          let a = self.makeEmoteDisplayData(response.data);
          //replyEntry.emote = a;

          self.$store.commit("setReportReplyEmote", {
            reportId: this.reportId,
            commentId: commentId,
            replyId: replyId,
            data: a,
          });
        })
        .catch(function (err) {
          console.log(err);
          self.showErrorToast("データの登録に失敗しました。");
        });
    },

    onReadBtnClick() {
      let self = this;
      let reportId = this.reportId;

      if (reportId) {
        self.setAlreadyRead(reportId, 0);
      }
    },

    onUnReadBtnClick() {
      let self = this;
      let reportId = this.reportId;

      if (reportId) {
        self.cancelAlreadyRead(reportId);
      }
    },

    onBookmarkBtnClick() {
      let self = this;

      if (!this.entry.is_bookmarked) {
        Promise.resolve()
          .then((a) => {
            return this.checkAuthToken();
          })
          .then((a) => {
            return BookmarkAPI.putBookmark(this.entry.id);
          })
          .then((response) => {
            self.showMessageToast("日報をブックマークしました。");

            self.$store.dispatch("bookmark", self.entry.id);
            self.$eventHub.$emit("bookmark", { reportId: self.entry.id });
          })
          .catch(function (err) {
            console.log(err);
            self.showErrorToast("データの登録に失敗しました。");
          });
      } else {
        Promise.resolve()
          .then((a) => {
            return this.checkAuthToken();
          })
          .then((a) => {
            return BookmarkAPI.deleteBookmark(this.entry.id);
          })
          .then((response) => {
            self.showMessageToast("ブックマークを解除しました。");

            self.$store.dispatch("unBookmark", self.entry.id);
            self.$eventHub.$emit("unbookmark", { reportId: self.entry.id });
          })
          .catch(function (err) {
            console.log(err);
            self.showErrorToast("データの登録に失敗しました。");
          });
      }
    },
  },
};
</script>
