<template>
    <v-container class="system_maintenance" wrap>

        <div><span class="title">グループメンテナンス</span></div>

        <v-container>

            <v-container v-if="loading" fluid fill-height>
                <v-row align="center" justify="center">
                    <v-col cols="2">
                        <v-progress-circular
                                v-if="loading"
                                :size="50"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>


            <v-simple-table v-if="!loading" >
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Code</th>
                        <th class="text-left">Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="group in groupList" :key="group.group_cd" v-on:click="onSelectGroup(group)">
                        <td>{{ group.group_cd }}</td>
                        <td>{{ group.name }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>


        </v-container>


    </v-container>
</template>
<script>
import Common from '@/components/Common';
import GroupMaintenanceAPI from '@/apis/GroupMaintenanceAPI';

export default {
    mixins:[Common],
    mounted: function() {
        this.setupConditions();
    },
    data: () => ({

        groupList:[],


        loading: false,
    }),

    methods: {

        setupConditions(){

            this.fetchData(true);
        },

        fetchData(needDataFetch){
            let self = this;

            if (!this.isInitialized){
                this.fetchBasicData(function () {
                    self.fetchData(needDataFetch)
                });
                return;
            }

            if (needDataFetch){


                self.loading = true;

                Promise.resolve()
                    .then(a => { return this.checkAuthToken() })
                    .then(a => { return GroupMaintenanceAPI.listGroups() })
                    .then(response => {
                        self.makeDisplayData(response.data);
                        self.loading = false;
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.loading = false;
                        self.showErrorToast("データの取得に失敗しました。");
                    });

            }

        },

        fetchBasicData(finishProc){
            let self = this;
            self.isInitialized = true;
            if (finishProc){
                finishProc();
            }
        },


        makeDisplayData(data){

            let self = this;

            console.log(data);

            let groups = data;

            this.groupList.splice(0);
            groups.forEach(rec => {
                this.groupList.push(rec);
            });

        },


        onSelectGroup(group){
            let url = this.buildUrl("/admin/groups/" + group.group_cd + "/viewability");
            this.$router.push(url)
        },

    },

}
</script>