import axios from 'axios';
import Util from '../Util'

class UserAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    // 自分のプロフィール情報
    myProfile(){
        let path = "/api/profile/_self";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }

    updateMyProfile(data){
        let path = "/api/profile/_self";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.put(endpoint,data).then(response =>  response.data)
    }

    // スタッフCD指定のプロフィール情報
    profileByStaffCd(staffCd){
        let path = "/api/staff_profile/" + staffCd;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }

    // userId指定のプロフィール情報
    profileByUserId(userId){
        let path = "/api/user_profile/" + userId;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint).then(response =>  response.data)
    }

    list(){

        let path = "/api/admin/users";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.get(endpoint).then(response =>  response.data)
    }

    createUser(data){

        let path = "/api/admin/users";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint,data).then(response =>  response.data)
    }

    updateUser(data){

        let path = "/api/admin/users";
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.post(endpoint,data).then(response =>  response.data)
    }

    disableUser(userId){

        let path = "/api/admin/users/" + userId;
        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.delete(endpoint,{}).then(response =>  response.data)
    }
}

let _api = new UserAPI();

export default _api;