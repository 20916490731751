import Auth from '@aws-amplify/auth';

class Authentication {

    constructor (params) {
    }

    checkLogin() {
        return Auth.currentSession()
            .then(data => {
                console.log("checkLogin -> ログインしています");
                //console.log(data);

                let idToken = data.idToken.jwtToken;
                let payload = data.idToken.payload;

                return {
                    user_name:payload["cognito:username"],
                    staff_cd:payload["custom:staff-number"],
                    email:payload["email"],
                    email_verified:payload["email_verified"],
                    idToken:idToken,
                }
            })
    }

    signIn(username, password) {

        let AuthInstance = Auth;


        return Promise.resolve()
            .then(a => { return Auth.signIn(username, password) })
            .then(data => {
                console.log("signIn data 1");
                console.log(data);

                // user.challengeParam

                // 初回ログイン時は、必ずパスワードを再設定するように求めてくる
                // 新しいパスワードは同じでも構わないので、completeNewPasswordに同じパスワードを渡してしまう
                if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
                    console.log("CognitoからのNEW_PASSWORD_REQUIREDの処理を実行します。");
                    let reqAttr = {
                        email:"" + username + "@mykk.co.jp"
                    };
                    return Auth.completeNewPassword(data, password,reqAttr)
                        .then(a => Auth.currentSession())
                        .then(a => { return Auth.signIn(username, password) })
                }
                return data
            })
            .then(data => {
                console.log("signIn data 2");
                console.log(data);

                let accessToken = data.signInUserSession.accessToken;
                let idToken = data.signInUserSession.idToken.jwtToken;
                let refreshToken = data.signInUserSession.refreshToken;

                return {
                    user_name:data.username,
                    staff_cd:data.attributes["custom:staff-number"],
                    email:data.attributes["email"],
                    email_verified:data.attributes["email_verified"],
                    idToken:idToken,
                }

            });

    }

    signOut() {
        return Auth.signOut()
            .then(data => {
                console.log(data);
            })
    }

    changePassword(currentPass,newPass){

        return Promise.resolve()
            .then(a => Auth.currentUserPoolUser())
            .then(user => Auth.changePassword(user,currentPass,newPass) )

    }
}

let _aws = new Authentication();

export default _aws;