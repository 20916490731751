<template>
    <v-container ref="attendanceList" fill-height>

        <div><span class="title">入力チェック</span></div>

        <v-row dense>
            <v-col cols="12" sm="6" md="3" lg="3">
                <DateInputField v-model="selectedDate" label="検索日"></DateInputField>
            </v-col>
        </v-row>

        <v-row dense>
            <v-btn v-on:click="fetchData" class="primary mr-3">検索</v-btn>
            <v-btn v-on:click="onDownloadClick" :loading="downloading">ダウンロード</v-btn>
        </v-row>


        <v-row dense>

            <v-simple-table fixed-header :height="tableHeight" v-if="!loading"> <!--  fixed-header height="300px" -->
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th v-for="dt in headerRecords" class="text-left">{{dt.dt}}<br>{{dt.wd}}</th>
                    </tr>
                    </thead>
                    <tbody>

                    <template v-for="rec in list">

                        <tr>
                            <td>{{rec.staff_name}}</td>
                            <td v-for="v in rec.values">{{v}}</td>
                        </tr>

                    </template>

                    </tbody>
                </template>
            </v-simple-table>

        </v-row>

        <loading-view :loading="loading"/>

    </v-container>
</template>
<script>
    import moment from 'moment';
    import Common from '@/components/Common';
    import AttendanceAPI from '@/apis/AttendanceAPI';
    import DateInputField from "../components/controls/DateInputField";
    import Util from "../Util";

    export default {
        mixins:[Common],
        components : {
            DateInputField
        },
        mounted:function() {

            let m = moment();
            let h = m.hour();

            if (h < 17){
                m.subtract(1, 'days');
            }

            this.selectedDate = m.format("YYYY-MM-DD");

            this.fetchData();

            this.checkHeight();

            window.addEventListener('resize', this.handleResize)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        data() {
            return {
                loading:false,
                downloading:false,

                selectedDate:"",

                headerRecords:[
                    {
                        dt:"",
                        wd:"",
                    }
                ],
                list:[
                    {
                        staff_cd:"",
                        staff_name:"",
                        values:[],
                    },
                ],


                containerHeight:100,
            }
        },
        computed: {
            tableHeight(){
                return "" + (this.containerHeight) + "px"
            }
        },
        methods: {

            checkHeight(){
                let clientHeight = this.$refs.attendanceList.clientHeight;
                //
                //let tableTop = this.$refs.dailyviewtable.offsetTop;

                //console.log("clientHeight = " + clientHeight + ", tableTop = " + tableTop);

                this.containerHeight = clientHeight - 200;
            },

            handleResize(){
                this.checkHeight();
            },

            fetchData(){

                let self = this;

                if (!this.selectedDate){
                    return;
                }

                this.loading = true;

                let requestDates = 14;
                let m = moment(this.selectedDate).subtract(requestDates - 1, 'days');
                let dateStr = m.format("YYYY-MM-DD");

                this.headerRecords.splice(0);
                this.list.splice(0);

                Promise.resolve()
                    .then(a => { return this.checkAuthToken() })
                    .then(a => { return AttendanceAPI.list(dateStr,14) })
                    .then(a => {

                        console.log(a);

                        this.makeData(a.data);

                        this.loading = false;
                    })
                    .catch(function(err) {
                        self.loading = false;
                        console.log(err);
                        self.showErrorToast("データの取得に失敗しました。");
                    });
            },

            makeData(data){

                let dates = data.dates;
                let records = data.records;

                dates.forEach(dt => {
                    this.headerRecords.push(
                        {
                            dt:this.formatDate(dt,"MM/DD"),
                            wd:this.formatDate(dt,"(ddd)")
                        });
                });

                records.forEach(rec => {

                    let viewRec = {
                        staff_cd:rec.user.staff_cd,
                        staff_name:rec.user.name,
                        values:[],
                    };

                    rec.list.forEach(atRec => {
                        if (atRec){
                            let lt = atRec.leave_time;
                            if (lt){
                                viewRec.values.push(this.formatDate(lt,"HH:mm"));
                            } else {
                                viewRec.values.push("???");
                            }
                        }
                        else {
                            viewRec.values.push("-");
                        }
                    });

                    this.list.push(viewRec);

                });

            },

            onDownloadClick(){

                let self = this;

                if (!this.selectedDate){
                    return;
                }

                this.downloading = true;

                let requestDates = 14;
                let m = moment(this.selectedDate).subtract(requestDates - 1, 'days');
                let dateStr = m.format("YYYY-MM-DD");

                Promise.resolve()
                    .then(a => { return this.checkAuthToken() })
                    .then(a => { return AttendanceAPI.download(dateStr,14) })
                    .then(a => {

                        console.log(a);
                        let downloadId = a.data.downloadId;
                        let url = this.buildApiServerUrl(a.data.url);
                        let filename = a.data.fileName;

                        //let downloadUrl = this.buildApiEndpoint("/download/" + this.downloadId);
                        //let link = document.createElement('a');
                        //link.href = downloadUrl;
                        //link.click();

                        this._downloadFile(url,filename).then(a =>{
                            self.downloading = false;
                        });

                    })
                    .catch(function(err) {
                        self.downloading = false;
                        console.log(err);
                        self.showErrorToast("データの取得に失敗しました。");
                    });

            },

            _downloadFile(url,fileName){
                let authToken = this.$store.state.account.idToken;
                return fetch(url, { method: 'GET', headers: new Headers([["Authorization", authToken]])})
                    .then(response => response.blob())
                    .then(blob => {
                        let anchor = document.createElement("a");
                        anchor.href = window.URL.createObjectURL(blob);
                        anchor.download = fileName;
                        anchor.click();
                    })
            },

            buildApiServerUrl(path){
                return Util.combineUrl(process.env.VUE_APP_API_HOST,path)
            },

        },

    }

</script>