import axios from 'axios';
import Util from '../Util'

class BookmarkAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    listBookmark(){
        let path = "/api/bookmark/_self";
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.get(endpoint, {}).then(response =>  response.data)
    }

    putBookmark(reportId){
        let path = "/api/bookmark/_self/" + reportId;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.put(endpoint, {}).then(response =>  response.data)
    }

    deleteBookmark(reportId){
        let path = "/api/bookmark/_self/" + reportId;
        let endpoint =  Util.combineUrl(this.apiHost,path);
        return axios.delete(endpoint, {}).then(response =>  response.data)
    }


}

let _api = new BookmarkAPI();

export default _api;