<template>
    <div class="observer" ></div>
</template>
<script>
    export default {
        props: {options:Object,fireOnce:{type:Boolean,default:true}},
        mounted: function() {
            const options = this.options || {};
            this.observer = new IntersectionObserver(([entry]) => {
                if (entry && entry.isIntersecting) {
                    this.fireIntersect();
                }
            },options);

            this.observer.observe(this.$el);
        },
        destroyed() {
            if (this.observer){
                this.observer.disconnect();
                this.observer = null;
            }
        },
        data() {
            return {
                observer: null
            }
        },
        computed: {},
        methods: {
            fireIntersect(){
                this.$emit("intersect");
                if (this.fireOnce){
                    if (this.observer){
                        this.observer.disconnect();
                        this.observer = null;
                    }
                }
            }
        },
    }
</script>