<template>
  <!-- 左のドローワーの中身 -->

  <v-list class="left-drawer" dense>
    <template v-for="item in items">
      <v-row v-if="item.heading" :key="item.heading" align="center">
        <v-col cols="6">
          <v-subheader v-if="item.heading">
            {{ item.heading }}
          </v-subheader>
        </v-col>
        <v-col cols="6" class="text-center">
          <a href="#!" class="body-2 black--text">EDIT</a>
        </v-col>
      </v-row>

      <!-- サブメニューがある場合 -->
      <v-list-group
        v-else-if="item.children && checkMenuVisible(item)"
        :key="item.text"
        v-model="item.model"
        :prepend-icon="item.icon"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </template>

        <!-- サブメニュー -->
        <template v-for="(child, i) in item.children">
          <v-list-item
            @click="menuItemClick(child)"
            :class="{ active: child.id === selectedMenuId }"
            v-if="checkMenuVisible(child)"
          >
            <!--<v-list-item-action v-if="child.icon">
                          <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>-->
            <v-list-item-action> </v-list-item-action>
            <v-list-item-content class="pr-5">
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-group>

      <!-- サブメニューが無い場合 -->
      <v-list-item
        v-else-if="checkMenuVisible(item)"
        :key="item.id"
        @click="menuItemClick(item)"
        :class="{ active: item.id === selectedMenuId }"
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon v-if="item.badge">
          <v-chip
            v-if="
              item.badge === 'unreadThanksCardCount' &&
              unreadThanksCardCount > 0
            "
            class="my-2"
            x-small
            color="#E57373"
            text-color="white"
          >
            {{ unreadThanksCardCount }}
          </v-chip>
        </v-list-item-icon>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapState } from "vuex";
import RoleSupport from "@/components/RoleSupport";

export default {
  mixins: [RoleSupport],
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedMenuId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["unreadThanksCardCount"]),

    useAttendance() {
      let v = process.env.VUE_APP_USE_ATTENDANCE;
      if (v === null || v === undefined) {
        return true;
      } else return !!Number(v);
    },

    useThanksCard() {
      let v = process.env.VUE_APP_USE_THANKS;
      if (v === null || v === undefined) {
        return true;
      } else return !!Number(v);
    },
  },
  methods: {
    menuItemClick(item) {
      this.$emit("menuClick", item);
    },

    checkMenuVisible(menu) {
      if (menu.attendance) {
        if (!this.useAttendance) {
          return false;
        }
      }
      if (menu.thanks) {
        if (!this.useThanksCard) {
          return false;
        }
      }

      let roles = menu.role;

      return this.hasRole(roles);
    },
  },
};
</script>
