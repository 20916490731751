<template>
    <v-container class="report_forwards" v-show="!!forwards && forwards.length > 0">
        <!--
        <a v-on:click.prevent="opened = !opened" class="body-2">転送されています
        <v-icon v-if="!opened">keyboard_arrow_down</v-icon>
        <v-icon v-if="opened">keyboard_arrow_up</v-icon>
        </a>-->

        <v-row dense>
            <v-col v-for="(forward,index) in forwardsSummary" cols="12" sm="6" md="4" lg="4" xl="4">

                <v-card outlined class="d-flex pl-2 report_forward_card">

                    <div class="pt-1">
                        <v-icon small>share</v-icon>
                    </div>
                    <div class="report_forward_content pr-2">
                        <v-container class="ma-0 pa-0">
                            <v-row dense>
                                <v-col>
                                    <span class="ml-1 caption">{{ getUserName(forward.from_staff_cd) }} さんから </span>
                                    <span class="caption" v-for="(staffCd,staffIdx) in forward.staff_cds" :key="staffCd"><span v-if="staffIdx>0">、</span> {{ getUserName(staffCd) }} </span>
                                    <span class="caption">さんへ転送</span>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col class="caption">『{{ forward.comment }}』</v-col>
                            </v-row>
                        </v-container>
                    </div>

                </v-card>

                <!-- {{ formatDateTimeToDisplay(forward.create_at) }} -->

            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import Common from '@/components/Common';
import Vue from "vue";

export default {
    mixins:[Common],
    props: {
        reportId:{
            type: [String, Number],
            required: true
        },
    },
    mounted: function() {
        this.makeData();
    },
    destroyed() {
    },
    computed: {
        forwards(){
            return this.$store.state.reports[this.reportId].forwards;
        },
        entry(){
            return this.$store.state.reports[this.reportId];
        },
    },
    data() {
        return {
            opened:false,
            forwardsSummary:[
                {
                    from_staff_cd:"",
                    comment:"",
                    staff_cds:[]
                }
            ],
        }
    },
    methods: {
        makeData(){

            let source = this.forwards;

            let fm = {};

            source.forEach(f => {
                let k = "" + f.from_staff_cd + "_" + f.comment;
                let el;
                if (fm[k]){
                    el = fm[k];
                } else {
                    el = {
                        from_staff_cd:f.from_staff_cd,
                        comment:f.comment,
                        staff_cds:[]
                    };
                    fm[k] = el;
                }

                el.staff_cds.push(f.staff_cd);

            });

            let self = this;

            this.forwardsSummary.splice(0);

            Object.keys(fm).forEach(function (key) {
                self.forwardsSummary.push(fm[key]);
            });
        },

    },
}
</script>