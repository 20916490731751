<template>
    <v-container v-if="loading"  fluid fill-height>
        <v-row align="center" justify="center">
            <v-col cols="2">
                <v-progress-circular
                        v-if="loading"
                        :size="50"
                        color="primary"
                        indeterminate
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
    }
}
</script>