<template>
    <v-container class="follow">

        <div class="mb-3">
            <span class="title" v-if="adminMode">フォロー設定(管理者用)</span>
            <span class="title" v-if="!adminMode">フォロー設定</span>
        </div>

        <UserSelectField v-if="adminMode" required label="設定対象ユーザ" v-model="target_staff_cd" ></UserSelectField>

        <v-tabs v-model="selected_tab" v-if="!adminMode || !!follower">
            <v-tab >グループ</v-tab>
            <v-tab >担当者</v-tab>
        </v-tabs>
        <v-tabs-items v-model="selected_tab" v-if="!adminMode || !!follower">
            <v-tab-item :value="0" class="pa-5">
                <v-container>
                    <v-btn small v-on:click.prevent="onSelectAllClick">全て選択</v-btn>
                    <v-simple-table dense>
                        <thead>
                        <tr>
                            <th class="text-left"></th>
                            <th class="text-left">グループ名</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="grp in availableGroups" :key="grp.group_cd">
                            <td> <v-checkbox v-model="followGroups" hide-details :value="grp.group_cd" class="mt-0 mb-1"></v-checkbox></td>
                            <td>{{grp.group_cd}}:{{ grp.name }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                    <v-btn class="mt-3"  @click="onUpdateGroupClick" color="primary">
                        更新
                    </v-btn>
                </v-container>
            </v-tab-item>
            <v-tab-item :value="1" class="pa-5">

                <v-container>

                    <v-row>
                        <v-col cols="2">
                            <UserSelectField label="担当者" v-model="append_staff_cd" ></UserSelectField>
                        </v-col>
                        <v-col md="auto" class="pt-6">
                            <v-btn :disabled="!append_staff_cd" v-on:click.prevent="onAppendUserClick">追加</v-btn>
                        </v-col>

                    </v-row>

                    <v-simple-table dense>
                        <thead>
                        <tr>
                            <th class="text-left">担当者名</th>
                            <th class="text-left">所属グループ</th>
                            <th class="text-left"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(user,idx) in followUsers" :key="user.staff_cd">
                            <td class="pt-2 pb-2">{{ user.name }}</td>
                            <td>{{ user.group_name }}</td>
                            <td> <a v-on:click.prevent="onDeleteUserClick(user,idx)"><v-icon>clear</v-icon></a> </td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                </v-container>


            </v-tab-item>
        </v-tabs-items>


    </v-container>
</template>
<script>
import Common from '@/components/Common';
import FollowAPI from '@/apis/FollowAPI';
import UserSelectField from "@/components/controls/UserSelectField";

export default {
    mixins:[Common],
    components: {
        UserSelectField
    },
    mounted: function() {
        this.target_staff_cd = "";
        this.setupConditions();
    },
    props: {
        adminMode: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        '$route':function (to, from) {
            this.setupConditions();
        },
        target_staff_cd:function(to, from) {
            if (this.adminMode){
               this.fetchData(true);
            }
        },
    },
    data() {
        return {
            loading:false,
            target_staff_cd:"",

            selected_tab:0,
            followGroups:[
            ],
            followUsers:[
                {
                    user_id:"1",
                    staff_cd:"123",
                    name:"担当者名",
                    group_cd:30,
                    group_name:"営業調達1課",
                }
            ],
            availableGroups:[],
            append_staff_cd:"",
        }
    },
    computed:{

        follower(){
            if (this.adminMode){
                return this.target_staff_cd;
            } else {
                return null;
            }
        },

    },
    methods: {
        setupConditions(){

            this.fetchData(true);
        },

        fetchData(needDataFetch){
            let self = this;

            if (!this.isInitialized){
                this.fetchBasicData(function () {
                    self.fetchData(needDataFetch)
                });
                return;
            }

            if (needDataFetch){

                let follower = null;
                if (this.adminMode){
                    follower = this.follower;
                    if (!follower){
                        this.followUsers.splice(0);
                        this.followGroups.splice(0);
                        return;
                    }
                }

                self.loading = true;

                Promise.resolve()
                    .then(a => { return this.checkAuthToken() })
                    .then(a => { return FollowAPI.list(follower) })
                    .then(response => {
                        self.makeDisplayData(response.data);
                        self.loading = false;
                    })
                    .catch(function(err) {
                        console.log(err);
                        self.loading = false;
                        self.showErrorToast("データの取得に失敗しました。");
                    });

            }

        },

        fetchBasicData(finishProc){
            let self = this;
            self.isInitialized = true;
            if (finishProc){
                finishProc();
            }
        },


        makeDisplayData(data){

            let self = this;

            console.log(data);

            this.followUsers.splice(0);

            let followUsers = data.followUsers;
            let followGroups = data.followGroups;
            let availableGroups = data.availableGroups;

            followUsers.forEach( rec => {
                let displayRec = this.makeUserDisplayRecord(rec);
                this.followUsers.push(displayRec)
            });

            this.followGroups.splice(0);
            followGroups.forEach(rec => {
                this.followGroups.push(rec.following_group_cd);
            });

            this.availableGroups.splice(0);
            availableGroups.forEach(rec => {
                this.availableGroups.push(rec);
            });
        },

        makeUserDisplayRecord(rec){

            let user = this.findUser(rec.following_staff_cd);
            if (user){
                return {
                    user_id:user.user_id,
                    staff_cd:user.staff_cd,
                    name:user.name,
                    group_cd:user.group_cd,
                    group_name:this.getGroupName(user.group_cd),
                }
            }

        },


        onAppendUserClick(){
            let self = this;
            if (this.append_staff_cd){
                let user = this.findUser(this.append_staff_cd);
                if (user){

                    let a = this.followUsers.find(a => a.staff_cd == this.append_staff_cd);
                    if (a){
                        this.showMessageToast("既にフォローしています。");
                        return;
                    }

                    let followStaff = this.append_staff_cd;
                    this.append_staff_cd = "";

                    let follower = this.follower;

                    Promise.resolve()
                        .then(a => { return this.checkAuthToken() })
                        .then(a => { return FollowAPI.putStaff(follower,followStaff ) })
                        .then(response => {

                            this.followUsers.push({
                                user_id:user.user_id,
                                staff_cd:user.staff_cd,
                                name:user.name,
                                group_cd:user.group_cd,
                                group_name:this.getGroupName(user.group_cd),
                            });

                            self.loading = false;
                            this.showMessageToast(user.name + "さんをフォローしました。");
                        })
                        .catch(function(err) {
                            console.log(err);
                            self.loading = false;
                            self.showErrorToast("データの更新に失敗しました。");
                        });


                }
            }
        },
        onDeleteUserClick(user,idx){
            let self = this;

            let follower = this.follower;

            Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return FollowAPI.deleteStaff (follower,user.staff_cd) })
                .then(response => {
                    this.followUsers.splice(idx,1);
                    self.loading = false;
                    this.showMessageToast(user.name + "さんのフォローを解除しました。");
                })
                .catch(function(err) {
                    console.log(err);
                    self.loading = false;
                    self.showErrorToast("データの更新に失敗しました。");
                });
        },

        onUpdateGroupClick(){
            let self = this;

            let groups = this.followGroups;

            let follower = this.follower;

            Promise.resolve()
                .then(a => { return this.checkAuthToken() })
                .then(a => { return FollowAPI.postGroups (follower,groups) })
                .then(response => {
                    self.loading = false;
                    this.showMessageToast("グループのフォローを更新しました。");
                })
                .catch(function(err) {
                    console.log(err);
                    self.loading = false;
                    self.showErrorToast("データの更新に失敗しました。");
                });

        },

        onSelectAllClick(){

            let groupCds = this.availableGroups.map(a => a.group_cd);

            this.followGroups.splice(0);
            this.followGroups.splice(0,0,...groupCds);

        },



    }
};
</script>
