import axios from 'axios';
import Util from '../Util'

class ReportCommentAPI {

    constructor() {
        this.apiHost = process.env.VUE_APP_API_HOST;
    }

    saveComment(cond,commentId){

        let path = "/api/comment";
        if (commentId){
            path += "/" + commentId
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        if (commentId){
            return axios.put(endpoint, cond).then(response =>  response.data)
        } else {
            return axios.post(endpoint, cond).then(response =>  response.data)
        }

    }

    deleteComment(commentId){

        let path = "/api/comment/" + commentId;

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.delete(endpoint).then(response =>  response.data)
    }



    saveReply(cond,commentId,replyId){

        let path = "/api/comment/" + commentId + "/reply";

        if (replyId) {
            path += "/" + replyId
        }

        let endpoint =  Util.combineUrl(this.apiHost,path);

        if (replyId){
            return axios.put(endpoint, cond).then(response =>  response.data)
        } else {
            return axios.post(endpoint, cond).then(response =>  response.data)
        }

    }

    deleteReply(commentId,replyId){

        let path = "/api/comment/" + commentId + "/reply/" + replyId;

        let endpoint =  Util.combineUrl(this.apiHost,path);

        return axios.delete(endpoint).then(response =>  response.data)
    }

}

let _api = new ReportCommentAPI();

export default _api;